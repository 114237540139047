import {
  Button,
  darken,
  Stack,
  styled,
  SxProps,
  Typography,
} from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

export type Option<TValue> = {
  value: TValue;
  label: string;
};

type Value = string | number;

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue extends Value
> {
  control: Control<TFieldValues & Record<TName, TValue[]>>;
  name: TName;
  label?: string;
  options: Option<TValue>[];
  min?: number;
  max?: number;
  sx?: SxProps;
  errorSx?: SxProps;
  disabled?: boolean;
  required?: boolean;
}

export function ControlledChips<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue extends Value
>(props: Props<TFieldValues, TName, TValue>) {
  const toggle = (arr: TValue[], item: TValue): TValue[] => {
    if (props.max && props.max === 1) return [item];

    const includesItem = arr.includes(item);
    const hasReachedMaxLength = props.max && props.max <= arr.length;

    if (includesItem) {
      return arr.filter((i) => i !== item);
    }

    if (!includesItem && hasReachedMaxLength) {
      return arr;
    }

    return [...arr, item];
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => {
        const currentValue = field.value as Option<TValue>["value"][];

        return (
          <Stack spacing={1}>
            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap={1}
              columnGap={1}
              sx={props.sx}
            >
              {props.options.map((option) => {
                const isSelected = currentValue.includes(option.value);

                return (
                  <SButton
                    key={option.value}
                    variant={isSelected ? "contained" : "outlined"}
                    color={isSelected ? "secondary" : "primary"}
                    size="medium"
                    selected={isSelected}
                    onClick={() =>
                      field.onChange(toggle(currentValue, option.value))
                    }
                  >
                    {option.label}
                  </SButton>
                );
              })}
            </Stack>

            {fieldState.error?.type === "too_small" && (
              <Typography
                variant="caption"
                color="error"
                fontWeight={700}
                sx={props.errorSx}
              >
                Please select {props.min ? `at least ${props.min}` : "enough"}{" "}
                item(s)
              </Typography>
            )}
          </Stack>
        );
      }}
    />
  );
}

const SButton = styled(Button)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    minWidth: 80,
    height: 48,
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey[300],
    borderRadius: 24,
    fontWeight: 700,
    ...(selected && {
      "@media(hover:hover)": {
        "&:hover": {
          backgroundColor: darken(theme.palette.secondary.main, 0.05),
        },
      },
    }),
  })
);
