import AddIcon from "@mui/icons-material/Add";
import { IconButton, ListItemButton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useBroadcasts } from "../hooks/useBroadcasts";
import { BroadcastStatusChip } from "./BroadcastStatusChip";

export const BroadcastsPage: FC = () => {
  const [broadcasts, , error] = useBroadcasts([], [["scheduledAt", "desc"]]);

  // Needed because new items always get pushed to first position
  broadcasts.sort(
    (a, b) => b.scheduledAt.toMillis() - a.scheduledAt.toMillis()
  );

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">Broadcasts</Typography>
          <Stack>
            <IconButton
              sx={{ mt: -1, "&.active": { color: "primary.main" } }}
              size="large"
              to="/broadcasts/create"
              component={NavLink}
            >
              <AddIcon color="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      {!!broadcasts.length && (
        <MainSecondarySidebarList>
          {broadcasts.map((broadcast) => (
            <ListItemButton
              key={broadcast.id}
              to={`/broadcasts/${broadcast.id}`}
              component={NavLink}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                spacing={1}
              >
                <Stack>{toLocaleDateTimeString(broadcast.scheduledAt)}</Stack>
                <BroadcastStatusChip size="small" broadcast={broadcast} />
              </Stack>
            </ListItemButton>
          ))}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
