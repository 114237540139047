import {
  Broadcast,
  FirebaseFilter,
  FirebaseOrderBy,
  isBroadcast,
} from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export const useBroadcasts = (
  filters: FirebaseFilter<Broadcast>[],
  orderBys: FirebaseOrderBy<Broadcast>[]
) => {
  return usePaginatedCollection(
    query(
      getCollectionRef("broadcasts"),
      ...filters.map((filter) => where(...filter)),
      ...orderBys.map((order) => orderBy(...order))
    ),
    isBroadcast
  );
};
