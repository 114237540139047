import { Chip, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { TalentChip } from "../../Talent/views/TalentChip";
import { UserChip } from "../../User/views/UserChip";
import { usePayment } from "../hooks/usePayment";
import { PaymentPriceChip } from "./PaymentPriceChip";

export const PaymentPage: FC = () => {
  const { paymentId } = useParams<"paymentId">();
  const [payment, isLoading, error] = usePayment(paymentId);
  const isLoaded = !isLoading && !error;
  const stripePaymentId =
    payment?.stripePaymentIntentId || payment?.stripeInvoiceId;

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="ah2">Payment</Typography>
      </Stack>
      <Stack spacing={2} flex={1}>
        {error && <ErrorView error={error} />}
        {isLoading && <LoadingView />}
        {isLoaded && payment && (
          <Stack spacing={2} alignItems="flex-start">
            <PaymentPriceChip payment={payment} hasLabel hasOriginalPrice />
            {stripePaymentId && (
              <Chip
                component={NavLink}
                to={`${STRIPE_URL_RECORD[ENVIRONMENT.name]}${stripePaymentId}`}
                target="_blank"
                sx={{ cursor: "pointer" }}
                color="info"
                label={`Stripe: ${stripePaymentId}`}
              />
            )}
            {payment.appleTransactionId && (
              <Chip
                color="info"
                label={`Apple Transaction ID: ${payment.appleTransactionId}`}
              />
            )}
            {payment.googleTransactionId && (
              <Chip
                color="warning"
                label={`Google Transaction ID: ${payment.googleTransactionId}`}
              />
            )}
            <UserChip userId={payment.userId} />
            {payment.talentId && <TalentChip talentId={payment.talentId} />}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const STRIPE_URL_RECORD: Record<typeof ENVIRONMENT.name, string> = {
  eu: "https://dashboard.stripe.com/payments/",
  stg: "https://dashboard.stripe.com/test/payments/",
};
