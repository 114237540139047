import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Collapse, IconButton } from "@mui/material";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const CreatorBackButton: FC = () => {
  const { pathname } = useLocation();
  const parts = pathname.split("/").filter(Boolean);

  return (
    <Collapse orientation="horizontal" in={!!parts.length}>
      <IconButton
        size="large"
        sx={{ mr: 0.5 }}
        component={NavLink}
        to={`/${parts.slice(0, -1).join("/")}`}
      >
        <ArrowBackIcon />
      </IconButton>
    </Collapse>
  );
};
