import { Skeleton, Stack, Typography } from "@mui/material";
import { TalentConfig } from "@super-real/types";
import { FC } from "react";
import { useTalentMetricFrame } from "../hooks/useTalentMetricFrame";
import { CreatorDashboardHeadlineView } from "./CreatorDashboardHeadlineView";

interface Props {
  talentConfig: TalentConfig;
}

export const CreatorDashboardEngagementsView: FC<Props> = (props) => {
  const { talentConfig } = props;
  const [totalMetricFrame, isLoading] = useTalentMetricFrame(
    talentConfig.id,
    "total"
  );

  return (
    <>
      <CreatorDashboardHeadlineView title="💬 Engagements">
        <Typography variant="ah5" color="text.secondary">
          All Time
        </Typography>
      </CreatorDashboardHeadlineView>
      <Stack px={2} py={3} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack>
            <Typography variant="ah5">Users</Typography>
            <Typography variant="ah3" alignSelf="flex-start">
              {isLoading && <Skeleton width={60} />}
              {!isLoading &&
                (
                  totalMetricFrame?.chatWithAuthenticationCount || 0
                ).toLocaleString()}
            </Typography>
          </Stack>
          <Stack textAlign="right">
            <Typography variant="ah5">Paying Users</Typography>
            <Typography variant="ah3" alignSelf="flex-end">
              {isLoading && <Skeleton width={60} />}
              {!isLoading &&
                (totalMetricFrame?.chatWithPaymentCount || 0).toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
