import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { LinkList } from "../../Common/views/LinkList";

export const CreatorSettingsPage: FC = () => {
  return (
    <Stack py={3} spacing={2}>
      <Stack px={2}>
        <Typography variant="ah1" color="text.secondary">
          ⚙️ Manage
        </Typography>
      </Stack>
      <LinkList>
        <ListItemButton to="/settings/profile" component={NavLink}>
          <ListItemIcon>😎</ListItemIcon>
          <ListItemText primary="profile" />
          <ArrowForwardIcon />
        </ListItemButton>
        <ListItemButton to="/settings/gallery" component={NavLink}>
          <ListItemIcon>📸</ListItemIcon>
          <ListItemText primary="Image Gallery" />
          <ArrowForwardIcon />
        </ListItemButton>
        <ListItemButton to="/settings/voice" component={NavLink}>
          <ListItemIcon>🎤</ListItemIcon>
          <ListItemText primary="Voice" />
          <ArrowForwardIcon />
        </ListItemButton>
        <ListItemButton to="/settings/welcome-messages" component={NavLink}>
          <ListItemIcon>👋</ListItemIcon>
          <ListItemText primary="Welcome Messages" />
          <ArrowForwardIcon />
        </ListItemButton>
        <ListItemButton
          to="/settings/conversation-starters"
          component={NavLink}
        >
          <ListItemIcon>💡</ListItemIcon>
          <ListItemText primary="Conversation Starters" />
          <ArrowForwardIcon />
        </ListItemButton>
        <ListItemButton to="/settings/monetisation" component={NavLink}>
          <ListItemIcon>💵</ListItemIcon>
          <ListItemText primary="Monetisation" />
          <ArrowForwardIcon />
        </ListItemButton>
      </LinkList>
    </Stack>
  );
};
