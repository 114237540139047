import { FC } from "react";
import { MainPage } from "../../Main/views/MainPage";
import { LoadingView } from "./LoadingView";

export const LoadingPage: FC = () => {
  return (
    <MainPage>
      <LoadingView />
    </MainPage>
  );
};
