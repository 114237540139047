import { GlobalStyles } from "@mui/material";
import { FC } from "react";

export const BodyGradientView: FC = () => {
  return (
    <GlobalStyles
      styles={{
        body: {
          "&:after": {
            position: "absolute",
            content: '""',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(180deg, white 0%, rgba(217, 217, 217, 0.00) 100%)",
            zIndex: -1,
          },

          "&:before": {
            position: "absolute",
            content: '""',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(75deg, rgba(255, 216, 57, 0.3) -26.89%, rgba(255, 129, 247, 0.3) 99.04%)",
            zIndex: -1,
          },
        },
      }}
    />
  );
};
