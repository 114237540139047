import { Stack } from "@mui/material";
import { FC } from "react";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { useMyTalentConfigs } from "../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";
import { CreatorCompleteView } from "./CreatorCompleteView";
import { CreatorDashboardEngagementsView } from "./CreatorDashboardEngagementsView";
import { CreatorDashboardInsightsView } from "./CreatorDashboardInsightsView";
import { CreatorDashboardQuickAccessView } from "./CreatorDashboardQuickAccessView";
import { ShareLinkField } from "./ShareLinkField";

export const CreatorDashboardPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);

  if (!talent) return null;
  if (!talentConfig) return null;

  return (
    <>
      <CreatorCompleteView />
      <Stack px={2} py={3}>
        <ShareLinkField
          label="Share your digital twin"
          url={`${ENVIRONMENT.messengerDomain}/${talent.tag}`}
        />
      </Stack>
      <CreatorDashboardInsightsView talentConfig={talentConfig} />
      <CreatorDashboardEngagementsView talentConfig={talentConfig} />
      <CreatorDashboardQuickAccessView talent={talent} />
    </>
  );
};
