import {
  Attachment,
  AttachmentType,
  Dimensions,
  Gender,
  TalentInterest,
  TalentPersonalityTrait,
  TalentPurpose,
  Voice,
} from "@super-real/types";
import { LlmType } from "@super-real/types/types/LlmType";
import { z } from "zod";

export const FormValues = z.object({
  name: z.string().nonempty(),
  tag: z.string().nonempty(),
  profilePictureUrl: z.string(),
  gender: Gender,
  age: z.number().int().positive(),
  voice: Voice,
  isAdult: z.boolean(),
  hasCustomChatPrompt: z.boolean(),
  chatPrompt: z.string(),
  facts: z.string(),
  creditsToSendMessage: z.number(),
  creditsToUnlockImage: z.number(),
  creditsToUnlockAudio: z.number(),
  creditsToUnlockFirstImage: z.number(),
  isCreditsToUnlockFirstImageEnabled: z.boolean(),
  revenueShare: z.number().min(0).max(1),
  purpose: TalentPurpose,
  personalityTraits: z.array(TalentPersonalityTrait),
  interests: z.array(TalentInterest),
  chatMessageForAuth: z.string(),
  chatMessageForCheckout: z.string(),
  chatMessageForSubscription: z.string(),
  chatExampleCount: z.number(),
  chatExamples: z.array(
    z.object({
      requestText: z.string().nonempty(),
      responseText: z.string().nonempty(),
    })
  ),
  chatMessageSuggestions: z.array(
    z.object({
      id: z.string(),
      text: z.string(),
      answer: z.string(),
    })
  ),
  isChatMessageSuggestionsAutoAnswerEnabled: z.boolean(),
  initialChatMessages: z.array(
    z.object({
      id: z.string(),
      text: z.string().nonempty(),
      attachments: z.array(Attachment).optional(),
    })
  ),
  staticAttachments: z.array(
    z
      .object({
        id: z.string(),
        originalFileName: z.string(),
        filePath: z.string().nonempty(),
        context: z.string().nonempty(),
      })
      .and(
        z.union([
          z.object({
            type: z.literal("IMAGE" satisfies AttachmentType),
            lockedFilePath: z.string(),
            dimensions: Dimensions,
          }),
          z.object({
            type: z.literal("AUDIO" satisfies AttachmentType),
          }),
        ])
      )
  ),
  llmType: LlmType,
  llmTemperature: z.number().min(0),
  llmModelId: z.string(),
  llmFineTunedModelId: z.string(),
  faqId: z.string(),
  subscriptionProductId: z.string(),
  referralRevenueShareForInvitees: z.number().min(0).max(1),
});

export type FormValues = z.infer<typeof FormValues>;
