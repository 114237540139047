import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ChatMessage, FirebaseFilter } from "@super-real/types";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useChatMessages } from "../hooks/useChatMessages";

const FILTER_OPTIONS: {
  label: string;
  filters: FirebaseFilter<ChatMessage>[];
}[] = [
  {
    filters: [["reaction", "==", "THUMBS_DOWN"]],
    label: "With Thumbs Down 👎",
  },
  {
    filters: [["reaction", "==", "THUMBS_UP"]],
    label: "With Thumbs Up 👍",
  },
];

export const ChatReactionsPage: FC = () => {
  const [filterIndex, setFilterIndex] = useState(0);
  const filters = FILTER_OPTIONS[filterIndex]?.filters || [];
  const [chatMessages, , error, controller] = useChatMessages(filters);

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Typography variant="ah2">Reactions</Typography>

        <FormControl>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            value={filterIndex}
            onChange={(event) => setFilterIndex(event.target.value as number)}
            startAdornment={
              <InputAdornment position="start">
                <FilterAltIcon />
              </InputAdornment>
            }
          >
            {FILTER_OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {error && <ErrorView error={error} />}
      <MainSecondarySidebarList>
        {chatMessages
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((chatMessage) => {
            const { chatId } = chatMessage;
            if (!chatId) return;

            return (
              <ListItemButton
                key={chatMessage.id}
                to={`/reactions/${chatId}/${chatMessage.id}`}
                component={NavLink}
              >
                {[
                  toLocaleDateTimeString(chatMessage.createdAt),
                  chatMessage.reactionFeedback && "💬",
                ].join(" ")}
              </ListItemButton>
            );
          })}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>Load more</Button>
          </Stack>
        )}
      </MainSecondarySidebarList>
    </MainSecondarySidebarPage>
  );
};
