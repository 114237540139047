import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { BroadcastCreateForm } from "./BroadcastCreateForm";

export const BroadcastCreatePage: FC = () => {
  return (
    <Stack p={3} spacing={3}>
      <Typography variant="ah2">Schedule Broadcast</Typography>
      <BroadcastCreateForm />
    </Stack>
  );
};
