import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FirebaseAuthEmailLinkDialog } from "../../Firebase/views/FirebaseAuthEmailLinkDialog";
import { FirebaseAuthLandingPage } from "../../Firebase/views/FirebaseAuthLandingPage";
import { FirebaseAuthSignInPage } from "../../Firebase/views/FirebaseAuthSignInPage";
import { MainReferralPage } from "./MainReferralPage";

export const MainLoggedOutRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route index element={<FirebaseAuthLandingPage />} />
        <Route path="sign-in" element={<FirebaseAuthSignInPage />} />
        <Route path="r/:referralTalentTag" element={<MainReferralPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <FirebaseAuthEmailLinkDialog />
    </>
  );
};
