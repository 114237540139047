import { Skeleton } from "@mui/material";
import { EarningFrame } from "@super-real/types";
import { FC } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";

interface Props {
  earningFrames?: EarningFrame[];
}

export const CreatorEarningsLineChart: FC<Props> = (props) => {
  const { earningFrames } = props;

  if (!earningFrames?.length) {
    return <Skeleton height={HEIGHT} />;
  }

  return (
    <ResponsiveContainer height={HEIGHT}>
      <LineChart data={earningFrames}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="id" visibility="hidden" />
        <Tooltip
          formatter={(value) =>
            toLocaleMoney({ amount: typeof value === "number" ? value : 0 })
          }
        />
        <Line
          type="monotone"
          dataKey="totalNetAmount"
          name="Total Net"
          stroke={MAIN_PALETTE.primary.main}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const HEIGHT = 250;
