import { isInvitation } from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useTalentInvitations(talentId?: string) {
  return useValidCollectionData(
    talentId
      ? query(
          getCollectionRef("invitations"),
          where("talentId", "==", talentId)
        )
      : null,
    isInvitation
  );
}
