import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScienceIcon from "@mui/icons-material/Science";
import StarIcon from "@mui/icons-material/Star";
import StorageIcon from "@mui/icons-material/Storage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Model } from "@super-real/types";
import { FC, useState } from "react";
import { useAppConfig } from "../../AppConfig/hooks/useAppConfig";
import { handleError } from "../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { createModelVersionCallable } from "../callables/createModelVersionCallable";
import { updateModelCallable } from "../callables/updateModelCallable";
import { OPEN_AI_ORGANISATION_ID_RECORD } from "../consts/OPEN_AI_ORGANISATION_ID_RECORD";
import { toModelTrainingExamplesString } from "../helpers/toModelTrainingExamplesString";
import { useModelVersions } from "../hooks/useModelVersions";
import { ModelVersionStatusChip } from "./ModelVersionStatusChip";

interface Props {
  model: Model;
}

export const ModelVersionsView: FC<Props> = (props) => {
  const { model } = props;
  const [isUpdatingModel, setIsUpdatingModel] = useState(false);
  const [expandedVersionId, setExpandedVersionId] = useState<string>();
  const [versions] = useModelVersions(model.id);
  const [isCreatingVersion, setIsCreatingVersion] = useState(false);
  const [appConfig, isLoadingAppConfig] = useAppConfig("default");
  const isDisabled =
    isLoadingAppConfig || !!appConfig?.fineTuningModelId || isCreatingVersion;

  return (
    <Stack spacing={2}>
      <Typography variant="ah3">Versions</Typography>
      <Stack>
        {versions
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((version) => (
            <Accordion
              key={version.id}
              expanded={expandedVersionId === version.id}
              onChange={(_, isExpanded) =>
                setExpandedVersionId(isExpanded ? version.id : undefined)
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ModelVersionStatusChip modelVersion={version} />
                  <Typography variant="body2">
                    {[
                      version.base,
                      toLocaleDateTimeString(version.createdAt),
                      version.openAiOrganisationId
                        ? OPEN_AI_ORGANISATION_ID_RECORD[
                            version.openAiOrganisationId
                          ] || "Unknown"
                        : undefined,
                    ]
                      .filter(Boolean)
                      .join(" · ")}
                  </Typography>
                  {model.defaultVersionId === version.id && (
                    <StarIcon color="secondary" />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <TextField
                    value={toModelTrainingExamplesString(
                      version.trainingExamples
                    )}
                    label="Examples"
                    maxRows={10}
                    sx={{ textarea: { fontSize: 12, lineHeight: 1.5 } }}
                    fullWidth
                    multiline
                  />
                  {version.fineTunedModelId && (
                    <TextField
                      value={version.fineTunedModelId}
                      label="Fine-Tuned Model ID"
                      fullWidth
                    />
                  )}
                  {version.errorMessage && (
                    <Alert severity="error">{version.errorMessage}</Alert>
                  )}
                  <Stack direction="row" spacing={2}>
                    <Button
                      startIcon={<StarIcon />}
                      disabled={
                        isUpdatingModel || version.status !== "SUCCEEDED"
                      }
                      onClick={async () => {
                        try {
                          setIsUpdatingModel(true);
                          await updateModelCallable({
                            id: model.id,
                            defaultVersionId: version.id,
                            defaultFineTunedModelId: version.fineTunedModelId,
                          });
                        } catch (error) {
                          handleError(error);
                        } finally {
                          setIsUpdatingModel(false);
                        }
                      }}
                    >
                      Make Default
                    </Button>
                    <Button
                      component="a"
                      target="_blank"
                      href={getFirestoreUrl(
                        `models/${model.id}/versions/${version.id}`
                      )}
                      startIcon={<StorageIcon />}
                    >
                      Firestore
                    </Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
      </Stack>
      <Button
        size="large"
        color="success"
        variant="contained"
        startIcon={<ScienceIcon />}
        sx={{ alignSelf: "flex-start" }}
        onClick={async () => {
          try {
            setIsCreatingVersion(true);
            await createModelVersionCallable({
              modelId: model.id,
            });
          } catch (error) {
            handleError(error);
          } finally {
            setIsCreatingVersion(false);
          }
        }}
        disabled={isDisabled}
      >
        Generate new version
      </Button>
    </Stack>
  );
};
