import { zodResolver } from "@hookform/resolvers/zod";
import InstagramIcon from "@mui/icons-material/Instagram";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import SaveIcon from "@mui/icons-material/Save";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  TestGroupName,
  User,
  USER_ROLES,
  UserRole,
  WAITLIST_STATUSES,
  WaitlistStatus,
} from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { TalentChip } from "../../Talent/views/TalentChip";
import { adminUpdateUserCallable } from "../callables/adminUpdateUsersCallable";

const FormValues = z.object({
  name: z.string(),
  email: z.string(),
  role: UserRole,
  credits: z.number().min(0),
  waitlistStatus: WaitlistStatus.or(z.enum([""])),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  user: User;
}

export const UserForm: FC<Props> = (props) => {
  const { user } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: user.name || "",
      email: user.email || "",
      role: user.role || "USER",
      credits: user.credits,
      waitlistStatus: user.waitlistStatus
        ? user.waitlistStatus
        : user.managedTalentIds?.length
        ? "ACCEPTED"
        : "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { name, email, waitlistStatus } = formValues;

        await adminUpdateUserCallable({
          id: user.id,
          ...(name && { name }),
          ...(email && { email }),
          waitlistStatus: waitlistStatus || null,
          role: formValues.role,
          credits: formValues.credits,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [user]
  );

  const isLoading = formState.isSubmitting;
  const hasSocials = !!user.instagram || !!user.tiktok || !!user.x;

  const testGroupRecord = user.testGroupRecord || {};
  const testGroupNames = Object.keys(testGroupRecord) as TestGroupName[];

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Basic</Typography>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledTextField
          control={control}
          label="Email"
          name="email"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledSelect
          control={control}
          label=""
          name="role"
          items={USER_ROLES.map((role) => ({
            label: role,
            value: role,
          }))}
          renderItem={(item) => item.label}
          disabled={isLoading}
          fullWidth
        />
      </Stack>

      {!!testGroupNames.length && (
        <Stack component={Paper} p={2} spacing={3}>
          <Typography variant="ah3">Test Groups</Typography>
          <Stack direction="row" spacing={2}>
            {testGroupNames.map((name) => (
              <Chip
                key={name}
                label={`${name}: ${testGroupRecord[name] || "NOT_SET"}`}
              />
            ))}
          </Stack>
        </Stack>
      )}

      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Talents</Typography>
        {!!user.managedTalentIds?.length && (
          <Stack spacing={2} alignItems="flex-start">
            {user.managedTalentIds.map((talentId) => (
              <TalentChip key={talentId} talentId={talentId} />
            ))}
          </Stack>
        )}
        {hasSocials && (
          <Stack spacing={0.5}>
            {user.instagram && (
              <Button
                sx={{ alignSelf: "flex-start" }}
                startIcon={<InstagramIcon />}
                href={`https://www.instagram.com/${user.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram.com/{user.instagram}
              </Button>
            )}
            {user.tiktok && (
              <Button
                sx={{ alignSelf: "flex-start" }}
                startIcon={<MusicNoteIcon />}
                href={`https://www.tiktok.com/@${user.tiktok}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                tiktok.com/@{user.tiktok}
              </Button>
            )}
            {user.x && (
              <Button
                sx={{ alignSelf: "flex-start" }}
                startIcon={<TwitterIcon />}
                href={`https://x.com/${user.x}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                x.com/{user.x}
              </Button>
            )}
          </Stack>
        )}
        <ControlledSelect
          control={control}
          label="Waitlist Status"
          name="waitlistStatus"
          items={WAITLIST_STATUSES.map((status) => ({
            label: status,
            value: status,
          }))}
          emptyLabel="Not on Waitlist"
          renderItem={(item) => item.label}
          disabled={isLoading}
          fullWidth
        />
      </Stack>

      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Credits</Typography>
        <Stack direction="row" spacing={2}>
          <ControlledTextField
            control={control}
            type="number"
            label="free credits"
            name="credits"
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <TextField
            label="paid credits"
            value={user.paidCredits || 0}
            fullWidth
            disabled
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
