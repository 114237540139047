import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, InputAdornment, Stack } from "@mui/material";
import {
  MIN_CREDITS_TO_SEND_MESSAGE,
  MIN_CREDITS_TO_UNLOCK_AUDIO,
  MIN_CREDITS_TO_UNLOCK_IMAGE,
  Talent,
} from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { DEFAULT_APP_CONFIG } from "../../../AppConfig/consts/DEFAULT_APP_CONFIG";
import { handleError } from "../../../Common/helpers/handleError";
import { toLocaleMoney } from "../../../Common/helpers/toLocaleMoney";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";

const FormValues = z.object({
  creditsToSendMessage: z.number().int().min(MIN_CREDITS_TO_SEND_MESSAGE),
  creditsToUnlockImage: z.number().int().min(MIN_CREDITS_TO_UNLOCK_IMAGE),
  creditsToUnlockAudio: z.number().int().min(MIN_CREDITS_TO_UNLOCK_AUDIO),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent: Talent;
}

export const CreatorSettingsProfileForm: FC<Props> = (props) => {
  const { talent } = props;
  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      creditsToSendMessage: talent.creditsToSendMessage,
      creditsToUnlockImage: talent.creditsToUnlockImage,
      creditsToUnlockAudio: talent.creditsToUnlockAudio,
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateTalentCallable({
          id: talent.id,
          creditsToSendMessage: formValues.creditsToSendMessage,
          creditsToUnlockImage: formValues.creditsToUnlockImage,
          creditsToUnlockAudio: formValues.creditsToUnlockAudio,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  const creditsToSendMessage = watch("creditsToSendMessage");
  const creditsToUnlockImage = watch("creditsToUnlockImage");
  const creditsToUnlockAudio = watch("creditsToUnlockAudio");

  return (
    <Stack
      spacing={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      pt={3}
    >
      <ControlledTextField
        control={control}
        label="Cost per Message"
        name="creditsToSendMessage"
        type="number"
        autoComplete="off"
        startAdornment={<InputAdornment position="start">💎</InputAdornment>}
        endAdornment={
          <InputAdornment position="end">
            {toLocaleMoney({
              amount:
                creditsToSendMessage * DEFAULT_APP_CONFIG.pricePerCredit.amount,
            })}
          </InputAdornment>
        }
        inputProps={{ step: 1 }}
        disabled={isLoading}
        fullWidth
      />
      <ControlledTextField
        control={control}
        label="Cost per Image"
        name="creditsToUnlockImage"
        type="number"
        autoComplete="off"
        startAdornment={<InputAdornment position="start">💎</InputAdornment>}
        endAdornment={
          <InputAdornment position="end">
            {toLocaleMoney({
              amount:
                creditsToUnlockImage * DEFAULT_APP_CONFIG.pricePerCredit.amount,
            })}
          </InputAdornment>
        }
        inputProps={{ step: 1 }}
        disabled={isLoading}
        fullWidth
      />
      <ControlledTextField
        control={control}
        label="Cost per Audio"
        name="creditsToUnlockAudio"
        type="number"
        autoComplete="off"
        startAdornment={<InputAdornment position="start">💎</InputAdornment>}
        endAdornment={
          <InputAdornment position="end">
            {toLocaleMoney({
              amount:
                creditsToUnlockAudio * DEFAULT_APP_CONFIG.pricePerCredit.amount,
            })}
          </InputAdornment>
        }
        inputProps={{ step: 1 }}
        disabled={isLoading}
        fullWidth
      />
      <Stack py={2}>
        <Divider />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
