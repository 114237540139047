import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import {
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Chat, FirebaseFilter, FirebaseOrderBy } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { useChats } from "../../Chat/hooks/useChats";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";

const FILTER_OPTIONS: { label: string; filters: FirebaseFilter<Chat>[] }[] = [
  {
    filters: [["hasUserInteraction", "==", true]],
    label: "With User Interaction",
  },
  {
    filters: [["hasUserInteractionWithNonAnonymousUser", "==", true]],
    label: "With Logged In User",
  },
  {
    filters: [["hasUserInteractionWithPaidCredits", "==", true]],
    label: "With Paid Credits",
  },
  {
    filters: [["hasUserInteractionWithSubscription", "==", true]],
    label: "With Subscription",
  },
  {
    filters: [["hasUserInteractionWithManager", "==", true]],
    label: "With Talent Manager",
  },
];

const ORDER_BY_OPTIONS: {
  label: string;
  orderBy: FirebaseOrderBy<Chat>;
  sort: (a: Chat, b: Chat) => number;
  getValue?: (chat: Chat) => number | string;
}[] = [
  {
    label: "Last Created",
    orderBy: ["createdAt", "desc"],
    sort: (a: Chat, b: Chat) => b.createdAt.toMillis() - a.createdAt.toMillis(),
  },
  {
    label: "Last Updated",
    orderBy: ["updatedAt", "desc"],
    sort: (a: Chat, b: Chat) =>
      (b.updatedAt?.toMillis() || 0) - (a.updatedAt?.toMillis() || 0),
    getValue: (chat) =>
      chat.updatedAt
        ? DateTime.fromJSDate(chat.updatedAt.toDate()).toFormat("HH:mm")
        : "",
  },
  {
    label: "Messages",
    orderBy: ["chatMessageCount", "desc"],
    sort: (a: Chat, b: Chat) =>
      (b.chatMessageCount || 0) - (a.chatMessageCount || 0),
    getValue: (chat) => chat.chatMessageCount || 0,
  },
];

export const ChatsPage: FC = () => {
  const [filterIndex, setFilterIndex] = useState(2);
  const [orderByIndex, setOrderByIndex] = useState(0);
  const [talentId, setTalentId] = useState("");
  const [chats, , error, controller] = useChats(
    [
      ...(FILTER_OPTIONS[filterIndex]?.filters || []),
      ...(talentId
        ? [["talentId", "==", talentId] satisfies FirebaseFilter<Chat>]
        : []),
    ],
    ORDER_BY_OPTIONS[orderByIndex]?.orderBy
  );

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Typography variant="ah2">Chats</Typography>
        <TextField
          value={talentId}
          label="Talent ID"
          sx={{ textarea: { fontSize: 14 } }}
          onChange={(event) => setTalentId(event.target.value)}
          fullWidth
        />
        <FormControl>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            value={filterIndex}
            onChange={(event) => setFilterIndex(event.target.value as number)}
            startAdornment={
              <InputAdornment position="start">
                <FilterAltIcon />
              </InputAdornment>
            }
          >
            {FILTER_OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Sort By</InputLabel>
          <Select
            label="Sort By"
            value={orderByIndex}
            onChange={(event) => setOrderByIndex(event.target.value as number)}
            startAdornment={
              <InputAdornment position="start">
                <SortIcon />
              </InputAdornment>
            }
          >
            {ORDER_BY_OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {error && <ErrorView error={error} />}
      <MainSecondarySidebarList>
        {chats.sort(ORDER_BY_OPTIONS[orderByIndex]?.sort).map((chat) => (
          <ListItemButton
            key={chat.id}
            to={`/chats/${chat.id}`}
            component={NavLink}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              spacing={1}
            >
              <Stack
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                display="inline-block"
              >
                {toLocaleDateTimeString(chat.createdAt)}
              </Stack>
              {ORDER_BY_OPTIONS[orderByIndex].getValue && (
                <Chip
                  size="small"
                  label={ORDER_BY_OPTIONS[orderByIndex].getValue?.(chat)}
                />
              )}
            </Stack>
          </ListItemButton>
        ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>Load more</Button>
          </Stack>
        )}
      </MainSecondarySidebarList>
    </MainSecondarySidebarPage>
  );
};
