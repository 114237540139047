import {
  BaseTextFieldProps,
  InputBaseProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> {
  control: Control<TFieldValues>;
  name: TName;
  label?: ReactNode;
  inputRef?: BaseTextFieldProps["inputRef"];
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  helperText?: string;
  disabled?: boolean;
  autoComplete?: "off";
  startAdornment?: InputBaseProps["startAdornment"];
  endAdornment?: InputBaseProps["endAdornment"];
  size?: BaseTextFieldProps["size"];
  type?: BaseTextFieldProps["type"];
  inputProps?: BaseTextFieldProps["inputProps"];
  inputLabelProps?: BaseTextFieldProps["InputLabelProps"];
}

export function ControlledTextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: Props<TFieldValues, TName>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          size={props.size}
          placeholder={props.placeholder}
          fullWidth={props.fullWidth}
          required={props.required}
          label={props.label}
          inputRef={props.inputRef}
          sx={props.sx}
          disabled={props.disabled}
          error={!!fieldState.error}
          helperText={props.helperText || fieldState.error?.message}
          multiline={props.multiline}
          rows={props.rows}
          minRows={props.minRows}
          maxRows={props.maxRows}
          type={props.type}
          {...(props.type === "number" && {
            onChange: (e) =>
              field.onChange(
                e.target.value === "" ? "" : Number(e.target.value)
              ),
          })}
          inputProps={props.inputProps}
          InputLabelProps={props.inputLabelProps}
          InputProps={{
            autoComplete: props.autoComplete,
            startAdornment: props.startAdornment,
            endAdornment: props.endAdornment,
          }}
        />
      )}
    />
  );
}
