import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";
import { CreatorEarningFrameMonthsView } from "./CreatorEarningFrameMonthsView";
import { CreatorEarningsWithdrawView } from "./CreatorEarningsWithdrawView";
import { CreatorPayoutsView } from "./CreatorPayoutsView";

export const CreatorEarningsPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);

  if (!talent) return null;

  return (
    <Stack px={2} py={3} spacing={3}>
      <CreatorEarningFrameMonthsView talentId={talent.id} />
      <CreatorEarningsWithdrawView />
      <Typography variant="ah5" color="text.secondary">
        Your last Payouts
      </Typography>
      <CreatorPayoutsView talent={talent} />
    </Stack>
  );
};
