import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { PAYOUT_TRANSFER_TYPES, PayoutTransferType } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { showConfetti } from "../../Common/helpers/showConfetti";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { createPayoutCallable } from "../../Payout/callables/createPayoutCallable";
import { MIN_PAYOUT_AMOUNT } from "../../Payout/consts/MIN_PAYOUT_AMOUNT";
import { PAYOUT_TRANSFER_TYPE_RECORD } from "../../Payout/consts/PAYOUT_TRANSFER_TYPE_RECORD";
import { useMyTalentConfigs } from "../../Talent/hooks/useMyTalentConfigs";
import { useDisbursableAmount } from "../hooks/useDisbursableAmount";

const FormValues = z
  .object({
    talentId: z.string(),
    taxId: z.string(),
  })
  .and(
    z.union([
      z.object({
        transferType: z.literal("BANK_TRANSFER" satisfies PayoutTransferType),
        accountHolderName: z.string().nonempty(),
        iban: z.string().nonempty(),
        bic: z.string().nonempty(),
      }),
      z.object({
        transferType: z.literal("PAYPAL" satisfies PayoutTransferType),
        payPalEmail: z.string().nonempty(),
      }),
    ])
  );

type FormValues = z.infer<typeof FormValues>;

export const CreatorWithdrawPage: FC = () => {
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);
  const [disbursableAmount, isLoadingDisbursableAmount] =
    useDisbursableAmount();
  const navigate = useNavigate();

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      talentId: talentConfig.id,
      transferType: "BANK_TRANSFER",
      accountHolderName: talentConfig.accountHolderName || "",
      iban: talentConfig.iban || "",
      bic: talentConfig.bic || "",
      taxId: talentConfig.taxId || "",
      ...{ payPalEmail: talentConfig.payPalEmail || "" },
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await createPayoutCallable({
          ...formValues,
          value: {
            amount: disbursableAmount,
            currency: "USD",
          },
        });
        toast.success("Payout requested!");
        showConfetti();
        navigate("/earnings");
      } catch (error) {
        handleError(error);
      }
    },
    [disbursableAmount, navigate]
  );

  const transferType = watch("transferType");

  const isPayoutAllowed = disbursableAmount >= MIN_PAYOUT_AMOUNT;

  const isDisabled =
    formState.isSubmitting || isLoadingDisbursableAmount || !isPayoutAllowed;

  return (
    <Stack
      px={2}
      py={3}
      spacing={3}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={1}>
        <Typography variant="ah5" color="text.secondary">
          You’re Withdrawing
        </Typography>
        <Typography
          variant="ah2"
          color="background.gradient"
          alignSelf="flex-start"
        >
          {isLoadingDisbursableAmount && <Skeleton width={60} />}
          {!isLoadingDisbursableAmount &&
            toLocaleMoney({ amount: disbursableAmount })}
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="ah3">Payout Details</Typography>
        <Typography variant="ah6" color="text.secondary">
          Please enter the following information before proceeding
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <ControlledSelect
          control={control}
          name="transferType"
          label="Transfer Type"
          items={PAYOUT_TRANSFER_TYPES.map((type) => ({
            label: PAYOUT_TRANSFER_TYPE_RECORD[type].label,
            value: type,
          }))}
          renderItem={(item) => item.label}
          disabled={isDisabled}
        />
        {transferType === "BANK_TRANSFER" && (
          <>
            <ControlledTextField
              control={control}
              label="Name on Bank Account"
              name="accountHolderName"
              disabled={isDisabled}
            />
            <ControlledTextField
              control={control}
              label="IBAN"
              name="iban"
              disabled={isDisabled}
            />
            <ControlledTextField
              control={control}
              label="BIC"
              name="bic"
              disabled={isDisabled}
            />
          </>
        )}
        {transferType === "PAYPAL" && (
          <>
            <ControlledTextField
              control={control}
              label="PayPal Email"
              name="payPalEmail"
              disabled={isDisabled}
            />
          </>
        )}
        <ControlledTextField
          control={control}
          label="Tax ID (Optional)"
          name="taxId"
          autoComplete="off"
          disabled={isDisabled}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={isDisabled}
        >
          Request Payout
        </Button>
      </Stack>
    </Stack>
  );
};
