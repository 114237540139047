import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Avatar, Button, Stack, styled } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC } from "react";
import { handleError } from "../../../Common/helpers/handleError";
import { useUploadPublicImages } from "../../../Common/hooks/useUploadPublicImages";
import { FileInput } from "../../../Common/views/FileInput";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";

interface Props {
  talent: Talent;
}

export const CreatorSettingsProfilePictureInput: FC<Props> = (props) => {
  const { talent } = props;
  const { profilePictureUrl } = talent;

  const [uploadFiles, isLoading] = useUploadPublicImages({
    ownerId: talent.id,
    ownerType: "TALENT",
    onFileUploaded: async (nextUrl) => {
      await updateTalentCallable({
        id: talent.id,
        profilePictureUrl: nextUrl,
      }).catch(handleError);
    },
  });

  return (
    <FileInput
      onSelect={uploadFiles}
      accept="image/jpg,image/jpeg,image/png,image/webp"
      disabled={isLoading}
    >
      <Stack
        direction="row"
        py={2}
        spacing={3}
        alignItems="center"
        onClick={(e) => e.preventDefault()}
      >
        <SAvatar
          src={profilePictureUrl}
          sx={{ opacity: isLoading ? 0.3 : 1 }}
        />
        <SButton
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={isLoading ? undefined : <CameraAltIcon />}
        >
          {isLoading ? "Uploading..." : "Update image"}
        </SButton>
      </Stack>
    </FileInput>
  );
};

const SAvatar = styled(Avatar)(({ theme }) => ({
  height: 100,
  width: 100,
  backgroundColor: theme.palette.text.primary,
  fontSize: 80,
}));

const SButton = styled(Button)(({ theme }) => ({
  borderRadius: 56 / 2,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontWeight: 700,
}));
