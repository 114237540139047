import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  label: string;
  path: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  close: () => void;
}

export const MainSidebarListItemView: FC<Props> = (props) => {
  const { label, path, Icon, close } = props;

  return (
    <ListItemButton to={path} component={NavLink} onClick={close}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};
