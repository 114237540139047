import AddIcon from "@mui/icons-material/Add";
import {
  CircularProgress,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useAppConfig } from "../../AppConfig/hooks/useAppConfig";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useModels } from "../hooks/useModels";

export const ModelsPage: FC = () => {
  const [models, , error] = useModels([null]);
  const [appConfig] = useAppConfig("default");

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">Models</Typography>
          <Stack>
            <IconButton
              sx={{ mt: -1, "&.active": { color: "primary.main" } }}
              size="large"
              to="/models/create"
              component={NavLink}
            >
              <AddIcon color="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      {!!models.length && (
        <MainSecondarySidebarList>
          {models
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((model) => (
              <ListItemButton
                key={model.id}
                to={`/models/${model.id}`}
                component={NavLink}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {appConfig?.fineTuningModelId === model.id && (
                    <CircularProgress size={14} />
                  )}
                  <span>{model.name}</span>
                </Stack>
              </ListItemButton>
            ))}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
