import { isTalent } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useTalent(talentId?: string) {
  return useValidDocumentData(
    talentId ? getDocRef("talents", talentId) : null,
    isTalent
  );
}
