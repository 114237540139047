import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { DEFAULT_APP_CONFIG } from "../../AppConfig/consts/DEFAULT_APP_CONFIG";
import { toLocalePercent } from "../../Common/helpers/toLocalePercent";
import { MainPage } from "../../Main/views/MainPage";
import { FirebaseAuthCaptionView } from "./FirebaseAuthCaptionView";

export const FirebaseAuthLandingPage: FC = () => {
  return (
    <MainPage>
      <Stack flex={1} p={2} justifyContent="center">
        <Stack spacing={4}>
          <Stack spacing={2}>
            <FirebaseAuthCaptionView />
            <Typography variant="ah1">
              Create your{" "}
              <Typography
                component="span"
                variant="ah1"
                color="background.gradient"
              >
                digital twin
              </Typography>{" "}
              in minutes
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="ah3">👋</Typography>
            <Typography variant="h3" fontWeight={700}>
              Your AI can chat and talk in your real voice with your fans
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="ah3">🚀</Typography>
            <Typography variant="h3" fontWeight={700}>
              Get started in minutes, personalize anytime
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="ah3">💸</Typography>
            <Typography variant="h3" fontWeight={700}>
              Share your AI with your followers, and keep{" "}
              {toLocalePercent(DEFAULT_APP_CONFIG.revenueShare)} of what it
              earns
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack p={2}>
        <Button
          variant="contained"
          size="large"
          to="/sign-in"
          component={NavLink}
        >
          Let’s get started
        </Button>
      </Stack>
    </MainPage>
  );
};
