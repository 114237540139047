import { Stack } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQueryDown } from "../../Common/hooks/useMediaQueryDown";
import { MAIN_HEADER_HEIGHT } from "../consts/MAIN_HEADER_HEIGHT";
import { MAIN_SECONDARY_SIDEBAR_WIDTH } from "../consts/MAIN_SECONDARY_SIDEBAR_WIDTH";
import { MAIN_SIDEBAR_WIDTH } from "../consts/MAIN_SIDEBAR_WIDTH";

export const MainSecondarySidebarPage: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const isSmall = useMediaQueryDown("sm");

  return (
    <Stack direction="row" flex={1} pl={`${MAIN_SECONDARY_SIDEBAR_WIDTH}px`}>
      <Stack
        position="fixed"
        top={isSmall ? MAIN_HEADER_HEIGHT : 0}
        bottom={0}
        left={isSmall ? 0 : MAIN_SIDEBAR_WIDTH}
        width={MAIN_SECONDARY_SIDEBAR_WIDTH}
        overflow="scroll"
      >
        {children}
      </Stack>
      <Stack
        zIndex={0}
        bgcolor="common.white"
        flex={1}
        borderLeft={1}
        borderColor="grey.500"
      >
        <Outlet />
      </Stack>
    </Stack>
  );
};
