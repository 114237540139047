import { Button, ListItemButton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { usePayments } from "../hooks/usePayments";
import { PaymentPriceChip } from "./PaymentPriceChip";

export const PaymentsPage: FC = () => {
  const [payments, , error, controller] = usePayments();

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack>
          <Typography variant="ah2">Payments</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <MainSecondarySidebarList>
        {payments
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .filter((payment) => !payment.isSandbox)
          .map((payment) => (
            <ListItemButton
              key={payment.id}
              to={`/payments/${payment.id}`}
              component={NavLink}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                spacing={1}
              >
                <Stack>{toLocaleDateTimeString(payment.createdAt)}</Stack>
                <PaymentPriceChip size="small" payment={payment} />
              </Stack>
            </ListItemButton>
          ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>Load more</Button>
          </Stack>
        )}
      </MainSecondarySidebarList>
    </MainSecondarySidebarPage>
  );
};
