import { Chip, ChipOwnProps } from "@mui/material";
import { Payment } from "@super-real/types";
import { FC } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { toLocalePrice } from "../../Common/helpers/toLocalePrice";
import { PRODUCT_PRICE_TYPE_RECORD } from "../consts/PRODUCT_PRICE_TYPE_RECORD";

interface Props {
  payment: Payment;
  size?: ChipOwnProps["size"];
  hasLabel?: boolean;
  hasOriginalPrice?: boolean;
}

export const PaymentPriceChip: FC<Props> = (props) => {
  const { payment, size, hasLabel, hasOriginalPrice } = props;
  const { chipColor, label } =
    PRODUCT_PRICE_TYPE_RECORD[payment.productPriceType];

  const parts: string[] = [];

  if (hasLabel) {
    parts.push(`${label}: `);
  }

  parts.push(toLocaleMoney(payment.price));

  if (hasOriginalPrice && payment.originalPrice) {
    parts.push(` (${toLocalePrice(payment.originalPrice)})`);
  }

  return <Chip size={size} label={parts.join("")} color={chipColor} />;
};
