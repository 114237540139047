import AddIcon from "@mui/icons-material/Add";
import {
  Chip,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { PUBLIC_TALENT_PLACEMENTS } from "@super-real/types";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { PUBLIC_TALENT_PLACEMENT_RECORD } from "../consts/PUBLIC_TALENT_PLACEMENT_RECORD";
import { usePublicTalents } from "../hooks/usePublicTalents";

export const PublicTalentsPage: FC = () => {
  const [publicTalents, isLoading, error] = usePublicTalents();
  const isLoaded = !isLoading && !error;

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">Discover</Typography>
          <Stack>
            <IconButton
              sx={{ mt: -1, "&.active": { color: "primary.main" } }}
              size="large"
              to="/public-talents/create"
              component={NavLink}
            >
              <AddIcon color="inherit" />
            </IconButton>
          </Stack>
        </Stack>
        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
      </Stack>
      {isLoaded && (
        <MainSecondarySidebarList>
          {publicTalents
            .sort((a, b) => a.order - b.order)
            .map((publicTalent) => (
              <ListItemButton
                key={publicTalent.id}
                to={`/public-talents/${publicTalent.id}`}
                component={NavLink}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  spacing={1}
                >
                  <Stack
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    display="inline-block"
                  >
                    {PUBLIC_TALENT_PLACEMENTS.filter(
                      (placement) => publicTalent.isVisibleRecord?.[placement]
                    ).map(
                      (placement) =>
                        PUBLIC_TALENT_PLACEMENT_RECORD[placement].emoji
                    )}{" "}
                    {publicTalent.name}
                  </Stack>
                  <Chip size="small" label={publicTalent.order} />
                </Stack>
              </ListItemButton>
            ))}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
