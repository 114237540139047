import {
  FirebaseFilter,
  FirebaseOrderBy,
  isUser,
  User,
} from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export const useUsers = (
  filters: FirebaseFilter<User>[],
  orderBys: FirebaseOrderBy<User>[]
) => {
  return usePaginatedCollection(
    query(
      getCollectionRef("users"),
      ...filters.map((filter) => where(...filter)),
      ...orderBys.map((order) => orderBy(...order))
    ),
    isUser
  );
};
