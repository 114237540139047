import { Chip, ChipOwnProps } from "@mui/material";
import { Broadcast } from "@super-real/types";
import { FC } from "react";
import { BROADCAST_STATUS_RECORD } from "../consts/BROADCAST_STATUS_RECORD";

interface Props {
  broadcast: Broadcast;
  size?: ChipOwnProps["size"];
}

export const BroadcastStatusChip: FC<Props> = (props) => {
  const { broadcast, size } = props;
  const { chipColor, label } = BROADCAST_STATUS_RECORD[broadcast.status];

  return <Chip size={size} label={label} color={chipColor} />;
};
