import { EarningFrame, FirebaseFilter } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useIsLoadingNextTick } from "../../Common/hooks/useIsLoadingNextTick";
import { mergeEarningFramesToTotal } from "../../Talent/helpers/mergeEarningFramesToTotal";
import { useEarningFrame } from "./useEarningFrame";
import { useEarningFrames } from "./useEarningFrames";

export const useEarningFrameTotal = (talentId: string) => {
  const [earningFrameTotal, isLoadingTotal, errorTotal] = useEarningFrame(
    talentId,
    "total"
  );

  const endedAt = useMemo(() => {
    if (!earningFrameTotal) return undefined;
    return DateTime.fromJSDate(earningFrameTotal.endedAt.toDate());
  }, [earningFrameTotal]);

  const [earningFramesByDay, isLoadingByDay, errorByDay] = useEarningFrames(
    isLoadingTotal ? undefined : talentId,
    [
      ["range", "==", "DAY"],
      ...(endedAt
        ? [
            [
              "startedAt",
              ">=",
              Timestamp.fromDate(endedAt.toJSDate()),
            ] satisfies FirebaseFilter<EarningFrame>,
          ]
        : []),
    ]
  );

  const sortedEarningFramesByDay = earningFramesByDay.sort(
    (a, b) => b.startedAt.toMillis() - a.startedAt.toMillis()
  );

  const mergedEarningFrame = useMemo(() => {
    if (!earningFrameTotal && !sortedEarningFramesByDay.length) {
      return undefined;
    }

    return mergeEarningFramesToTotal({
      earningFrames: [
        ...sortedEarningFramesByDay,
        ...(earningFrameTotal ? [earningFrameTotal] : []),
      ],
    });
  }, [earningFrameTotal, sortedEarningFramesByDay]);

  const isLoadingNextTick = useIsLoadingNextTick(!isLoadingTotal);
  const isLoading = isLoadingTotal || isLoadingByDay || isLoadingNextTick;
  const error = errorTotal || errorByDay;

  return [mergedEarningFrame, isLoading, error] as const;
};
