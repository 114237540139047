import { ModelVersion } from "@super-real/types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { MODEL_VERSION_STATUS_RECORD } from "../consts/MODEL_VERSION_STATUS_RECORD";

interface Props {
  modelVersion: ModelVersion;
}

export const ModelVersionStatusChip: FC<Props> = (props: Props) => {
  const { modelVersion } = props;
  const { color, Icon, label, isLoading } =
    MODEL_VERSION_STATUS_RECORD[modelVersion.status];

  return (
    <StatusChip color={color} label={label} Icon={Icon} isLoading={isLoading} />
  );
};
