import { List, styled } from "@mui/material";

export const MainSecondarySidebarList = styled(List)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    padding: theme.spacing(1, 3),
    "&.active": {
      color: theme.palette.primary.main,
    },
  },
}));
