import { isChatMessage } from "@super-real/types";
import { orderBy, query } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export function useChatMessagesByChatId(chatId?: string) {
  return usePaginatedCollection(
    chatId
      ? query(
          getCollectionRef(`chats/${chatId}/messages`),
          orderBy("createdAt", "desc")
        )
      : null,
    isChatMessage
  );
}
