import { EarningFrame } from "@super-real/types";

export const toTotalEarnings = (earningFrames: EarningFrame[] = []) => {
  return earningFrames.reduce(
    (result, frame) => {
      return {
        totalGrossAmount: result.totalGrossAmount + frame.totalGrossAmount,
        totalNetAmount: result.totalNetAmount + frame.totalNetAmount,
        sendMessageGrossAmount:
          result.sendMessageGrossAmount + (frame.sendMessageGrossAmount || 0),
        sendMessageNetAmount:
          result.sendMessageNetAmount + (frame.sendMessageNetAmount || 0),
        unlockImageGrossAmount:
          result.unlockImageGrossAmount + (frame.unlockImageGrossAmount || 0),
        unlockImageNetAmount:
          result.unlockImageNetAmount + (frame.unlockImageNetAmount || 0),
        unlockAudioGrossAmount:
          result.unlockAudioGrossAmount + (frame.unlockAudioGrossAmount || 0),
        unlockAudioNetAmount:
          result.unlockAudioNetAmount + (frame.unlockAudioNetAmount || 0),
        referralGrossAmount:
          result.referralGrossAmount + (frame.referralGrossAmount || 0),
        referralNetAmount:
          result.referralNetAmount + (frame.referralNetAmount || 0),
        subscriptionGrossAmount:
          result.subscriptionGrossAmount + (frame.subscriptionGrossAmount || 0),
        subscriptionNetAmount:
          result.subscriptionNetAmount + (frame.subscriptionNetAmount || 0),
      };
    },
    {
      totalGrossAmount: 0,
      totalNetAmount: 0,
      sendMessageGrossAmount: 0,
      sendMessageNetAmount: 0,
      unlockImageGrossAmount: 0,
      unlockImageNetAmount: 0,
      unlockAudioGrossAmount: 0,
      unlockAudioNetAmount: 0,
      referralGrossAmount: 0,
      referralNetAmount: 0,
      subscriptionGrossAmount: 0,
      subscriptionNetAmount: 0,
    }
  );
};
