import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, InputAdornment, styled, TextField } from "@mui/material";
import { FC, useCallback, useRef, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";

interface Props {
  url: string;
  label?: string;
}

export const ShareLinkField: FC<Props> = (props) => {
  const { url, label } = props;
  const [hasCopied, setHasCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url);
      setHasCopied(true);
      setTimeout(() => setHasCopied(false), 2000);
    } catch (error) {
      handleError(error);
    }
  }, [url]);

  return (
    <TextField
      label={label}
      value={url.replace(/https?:\/\//g, "")}
      inputRef={inputRef}
      onClick={() => inputRef.current?.select()}
      InputProps={{
        sx: { fontWeight: 700 },
        endAdornment: (
          <InputAdornment position="end">
            <SButton
              startIcon={<ContentCopyIcon />}
              variant="outlined"
              size="small"
              color={hasCopied ? "success" : "secondary"}
              onClick={copyToClipboard}
            >
              {hasCopied ? "Copied" : "Copy"}
            </SButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const SButton = styled(Button)(() => ({
  padding: "6px 12px",
  borderRadius: 20,
  borderWidth: 1,
  fontSize: 12,
  fontWeight: 700,
  "&&": {
    textTransform: "uppercase",
  },
}));
