import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { CreatorSettingsInitialChatMessagesForm } from "./CreatorSettingsInitialChatMessagesForm";

export const CreatorSettingsInitialChatMessagesPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);

  return (
    <Stack px={2} py={3} spacing={3}>
      <Typography variant="ah1" color="text.secondary">
        👋 Welcome Messages
      </Typography>
      <Typography variant="ah5" color="text.primary">
        These are sent in one go to your fans when they start the chat.
      </Typography>
      {talent && <CreatorSettingsInitialChatMessagesForm talent={talent} />}
    </Stack>
  );
};
