import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useApp } from "../../../AppConfig/hooks/useApp";
import { useMyTalentConfigs } from "../../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { CreatorSettingsVoiceForm } from "./CreatorSettingsVoiceForm";

export const CreatorSettingsVoicePage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);
  const voices = useApp((state) => state.app?.voices);

  return (
    <Stack px={2} py={3} spacing={3}>
      <Typography variant="ah1" color="text.secondary">
        🎤 Voice
      </Typography>
      <Typography variant="ah5" color="text.primary">
        Use a synthetic voice
      </Typography>
      {talent && talentConfig && voices && (
        <CreatorSettingsVoiceForm
          talent={talent}
          talentConfig={talentConfig}
          voices={voices}
        />
      )}
    </Stack>
  );
};
