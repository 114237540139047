import {
  Alert,
  Button,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { MIN_PAYOUT_AMOUNT } from "../../Payout/consts/MIN_PAYOUT_AMOUNT";
import { useDisbursableAmount } from "../hooks/useDisbursableAmount";

export const CreatorEarningsWithdrawView: FC = () => {
  const [disbursableAmount, isLoading] = useDisbursableAmount();

  return (
    <Stack
      p={2}
      spacing={2}
      component={Paper}
      variant="outlined"
      bgcolor="common.white"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Stack>
          <Typography variant="ah6" color="text.secondary">
            Disbursable Amount
          </Typography>
          <Typography variant="ah3">
            {isLoading && <Skeleton width={60} />}
            {!isLoading && toLocaleMoney({ amount: disbursableAmount })}
          </Typography>
        </Stack>
        <Stack>
          <Button
            component={NavLink}
            to="/earnings/withdraw"
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading || disbursableAmount < MIN_PAYOUT_AMOUNT}
          >
            Withdraw
          </Button>
        </Stack>
      </Stack>
      <Alert severity="info" icon={false}>
        💸 Minimum Withdrawal amount:{" "}
        {toLocaleMoney({ amount: MIN_PAYOUT_AMOUNT, fractionDigits: 0 })}
        <br />⏳ Disbursable after 24 hours
      </Alert>
    </Stack>
  );
};
