import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { FC, useState } from "react";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { useChatPrompt } from "../hooks/useChatPrompt";
import { useChatPromptDialog } from "../hooks/useChatPromptDialog";
import { ChatPromptDialogTabInput } from "./ChatPromptDialogTabInput";
import { ChatPromptDialogTabOutput } from "./ChatPromptDialogTabOutput";

type Tab = "INPUT" | "OUTPUT";

export const ChatPromptDialog: FC = () => {
  const { isOpen, chatId, chatMessageId, close } = useChatPromptDialog();
  const [chatPrompt, isLoading, error] = useChatPrompt(chatId, chatMessageId);
  const [currentTab, setCurrentTab] = useState<Tab>("INPUT");

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      PaperProps={{
        sx: {
          width: 800,
          maxWidth: "100%",
          height: "95%",
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack>Message Details</Stack>
          {chatId && chatMessageId && (
            <Stack direction="row" spacing={2}>
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(
                  `chats/${chatId}/messages/${chatMessageId}`
                )}
                startIcon={<ChatBubbleIcon />}
              >
                Chat Message
              </Button>
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(
                  `chats/${chatId}/prompts/${chatMessageId}`
                )}
                startIcon={<ChatBubbleOutlineOutlinedIcon />}
              >
                Chat Prompt
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Tabs value={currentTab} onChange={(_, tab: Tab) => setCurrentTab(tab)}>
          <Tab value="INPUT" label="Input" />
          <Tab value="OUTPUT" label="Output" />
        </Tabs>

        <Stack mt={4}>
          {isLoading && <LoadingView />}
          {error && <ErrorView error={error} />}

          {chatPrompt && (
            <>
              {currentTab === "INPUT" && (
                <ChatPromptDialogTabInput chatPrompt={chatPrompt} />
              )}
              {currentTab === "OUTPUT" && (
                <ChatPromptDialogTabOutput chatPrompt={chatPrompt} />
              )}
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};
