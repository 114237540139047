import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { EarningFrame } from "@super-real/types";
import { DateTime } from "luxon";
import { FC } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { CreatorEarningsLineChart } from "./CreatorEarningsLineChart";
import { CreatorEarningTypeView } from "./CreatorEarningTypeView";

interface Props {
  earningFrame?: EarningFrame;
  isExpanded: boolean;
  expand: () => void;
  close: () => void;
}

export const CreatorEarningFrameAccordion: FC<Props> = (props) => {
  const { earningFrame, expand, close, isExpanded } = props;

  return (
    <Accordion
      variant="outlined"
      sx={{ bgcolor: "common.white" }}
      expanded={isExpanded}
      onChange={(_, isExpanded) => {
        if (isExpanded) {
          expand();
        } else {
          close();
        }
      }}
    >
      <SAccordionSummary expandIcon={<ChevronRightIcon />}>
        <Typography variant="ah6" color="text.secondary">
          {!earningFrame && <Skeleton width={80} />}
          {!!earningFrame &&
            DateTime.fromJSDate(earningFrame.startedAt.toDate()).toFormat(
              "MMMM"
            )}
        </Typography>
        <Typography variant="ah6" color="text.secondary">
          {!earningFrame && <Skeleton width={60} />}
          {!!earningFrame &&
            toLocaleMoney({
              amount: earningFrame.totalNetAmount,
              currency: earningFrame.currency,
            })}
        </Typography>
      </SAccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Typography
            variant="ah2"
            color="background.gradient"
            alignSelf="flex-start"
          >
            {!earningFrame && <Skeleton width={100} />}
            {!!earningFrame &&
              toLocaleMoney({
                amount: earningFrame.totalNetAmount,
                currency: earningFrame.currency,
              })}
          </Typography>
          <Typography variant="ah5">
            {!earningFrame && <Skeleton width={100} />}
            {!!earningFrame && (
              <>
                Gross:{" "}
                {toLocaleMoney({
                  amount: earningFrame.totalGrossAmount,
                  currency: earningFrame.currency,
                })}
              </>
            )}
          </Typography>
          <CreatorEarningsLineChart
            earningFrames={earningFrame?.childEarningFrames}
          />
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <CreatorEarningTypeView
                label="Messages"
                isLoading={!earningFrame}
                netAmount={earningFrame?.sendMessageNetAmount}
              />
              <CreatorEarningTypeView
                label="Subscriptions"
                isLoading={!earningFrame}
                netAmount={earningFrame?.subscriptionNetAmount}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <CreatorEarningTypeView
                label="Images"
                isLoading={!earningFrame}
                netAmount={earningFrame?.unlockImageNetAmount}
              />
              <CreatorEarningTypeView
                label="Voice"
                isLoading={!earningFrame}
                netAmount={earningFrame?.unlockAudioNetAmount}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <CreatorEarningTypeView
                label="Referrals"
                isLoading={!earningFrame}
                netAmount={earningFrame?.referralNetAmount}
              />
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const SAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
