import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { Talent, UploadFileResponse } from "@super-real/types";
import { FC } from "react";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { FileInput } from "../../../Common/views/FileInput";

interface Props {
  talent: Talent;
  onFileUploaded: (uploadedFile: UploadFileResponse) => void;
}

export const InitialChatMessageFileInput: FC<Props> = (props) => {
  const { talent, onFileUploaded } = props;

  const [uploadFile, isUploadingFile] = useUploadFiles({
    ownerId: talent.id,
    ownerType: "TALENT",
    onFileUploaded,
  });

  const isLoading = isUploadingFile;

  return (
    <FileInput
      onSelect={uploadFile}
      accept="image/jpg,image/jpeg,image/png,image/webp"
      disabled={isLoading}
    >
      {isLoading && (
        <Stack
          width={BUTTON_SIZE}
          height={BUTTON_SIZE}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={BUTTON_SIZE / 2} />
        </Stack>
      )}
      {!isLoading && (
        <IconButton size="large" disabled={isLoading} color="primary">
          <AddPhotoAlternateIcon />
        </IconButton>
      )}
    </FileInput>
  );
};

const BUTTON_SIZE = 48;
