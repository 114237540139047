import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Talent, TalentConfig } from "@super-real/types";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useMyTalentConfigs } from "../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";

interface Step {
  isComplete: (params: {
    talent?: Talent;
    talentConfig?: TalentConfig;
  }) => boolean;
  path?: string;
  buttonLabel?: string;
}

const STEPS: Step[] = [
  {
    // First step is always complete to simulate some progress.
    isComplete: () => true,
  },
  {
    buttonLabel: "Upload images",
    path: "/settings/gallery",
    isComplete: ({ talentConfig }) => !!talentConfig?.staticAttachments?.length,
  },
  {
    buttonLabel: "Set your voice",
    path: "/settings/voice",
    isComplete: ({ talentConfig }) => !!talentConfig?.voice,
  },
  {
    buttonLabel: "Define conversion starters",
    path: "/settings/conversation-starters",
    isComplete: ({ talent }) => !!talent?.chatMessageSuggestions?.length,
  },
];

export const CreatorCompleteView: FC = () => {
  const theme = useTheme();
  const talent = useMyTalents(({ talents }) =>
    talents.length ? talents[0] : undefined
  );
  const talentConfig = useMyTalentConfigs(({ talentConfigs }) =>
    talentConfigs.length ? talentConfigs[0] : undefined
  );

  const stepIndex = STEPS.findIndex(
    (step) => !step.isComplete({ talent, talentConfig })
  );
  const step = STEPS[stepIndex];

  if (!step) return null;

  return (
    <Stack
      p={2}
      sx={{ background: theme.palette.background.gradient }}
      spacing={2}
    >
      <Stack direction="row" color="common.white" spacing={2}>
        <Stack justifyContent="center">
          <Stack position="relative">
            <CircularProgress
              variant="determinate"
              sx={{ color: "#FF5252", opacity: 0.1 }}
              size={PIE_WIDTH}
              thickness={8}
              value={100}
            />
            <CircularProgress
              sx={{ position: "absolute", left: 0, top: 0 }}
              variant="determinate"
              color="inherit"
              size={PIE_WIDTH}
              thickness={8}
              value={(stepIndex / STEPS.length) * 100}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h3">Power up your AI twin</Typography>
          <Typography variant="h5">And make it yours</Typography>
        </Stack>
      </Stack>
      {step.path && (
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          component={NavLink}
          to={step.path}
          sx={{ "&&": { border: 0 } }}
          endIcon={<ArrowForwardIcon />}
        >
          {step.buttonLabel}
        </Button>
      )}
    </Stack>
  );
};

const PIE_WIDTH = 24;
