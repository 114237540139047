import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Talent, TalentConfig, Voice } from "@super-real/types";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { useAudio } from "../../../Common/hooks/useAudio";
import { ControlledRadioButtons } from "../../../Form/views/ControlledRadioButtons";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";

const FormValues = z.object({
  voiceId: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent: Talent;
  talentConfig: TalentConfig;
  voices: Voice[];
}

export const CreatorSettingsVoiceForm: FC<Props> = (props) => {
  const { talent, talentConfig } = props;
  const [playingFilePath, setPlayingFilePath] = useAudio();

  const voices: Voice[] = useMemo(() => {
    const voicesForGender = props.voices.filter(
      (voice) => talent.gender === voice.gender
    );
    const defaultVoices = [NO_VOICE, ...voicesForGender];
    const talentVoice = talentConfig.voice;
    if (!talentVoice) return defaultVoices;

    const isTalentVoiceInDefaultVoices = defaultVoices.some(
      (voice) => voice.id === talentVoice.id
    );

    if (isTalentVoiceInDefaultVoices) return defaultVoices;
    return [NO_VOICE, talentVoice, ...voicesForGender];
  }, [props.voices, talent, talentConfig]);

  const onClickPlayPauseButton = useCallback(
    (voice: Voice) => () => {
      return setPlayingFilePath(
        playingFilePath === voice.sampleFilePath
          ? undefined
          : voice.sampleFilePath
      );
    },
    [playingFilePath, setPlayingFilePath]
  );

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      voiceId: talentConfig.voice?.id || NO_VOICE.id,
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const voice = voices.find((voice) => voice.id === formValues.voiceId);
        if (!voice) return;

        await updateTalentCallable({
          id: talent.id,
          voice,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id, voices]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <ControlledRadioButtons
        control={control}
        name="voiceId"
        options={voices.map((voice) => ({
          value: voice.id,
          label: voice.name,
        }))}
        renderLabel={(option, isSelected) => {
          const voiceIndex = voices.findIndex(
            (voice) => voice.id === option.value
          );
          const voice: Voice | undefined = voices[voiceIndex];

          return (
            <Stack
              direction="row"
              alignItems="center"
              py={2}
              flex={1}
              justifyContent="space-between"
              spacing={1}
              borderBottom={voiceIndex === voices.length - 1 ? 0 : 1}
              borderColor="divider"
            >
              <Typography
                variant="ah5"
                color={isSelected ? "primary.main" : "text.secondary"}
              >
                {option.label}
              </Typography>
              {voice?.sampleFilePath && (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={onClickPlayPauseButton(voice)}
                >
                  {playingFilePath === voice.sampleFilePath ? (
                    <PauseCircleIcon
                      color="primary"
                      sx={{ width: PLAY_BUTTON_SIZE, height: PLAY_BUTTON_SIZE }}
                    />
                  ) : (
                    <PlayCircleIcon
                      color="primary"
                      sx={{ width: PLAY_BUTTON_SIZE, height: PLAY_BUTTON_SIZE }}
                    />
                  )}
                </IconButton>
              )}
            </Stack>
          );
        }}
      />
      <Stack py={2}>
        <Divider />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoading}
        startIcon={<CheckCircleRoundedIcon />}
      >
        Choose Voice
      </Button>
    </Stack>
  );
};

const PLAY_BUTTON_SIZE = 42;
const NO_VOICE: Voice = {
  id: "",
  name: "No voice",
  stability: 0,
  similarityBoost: 0,
  style: 0,
  model: "eleven_monolingual_v1",
  gender: "NON_BINARY",
};
