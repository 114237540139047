import { FirebaseFilter, isPayment, Payment } from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export function usePayments(filters?: FirebaseFilter<Payment>[]) {
  return usePaginatedCollection(
    query(
      getCollectionRef("payments"),
      ...(filters ? filters.map((filter) => where(...filter)) : []),
      orderBy("createdAt", "desc")
    ),
    isPayment
  );
}
