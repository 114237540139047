import { isPublicTalent } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function usePublicTalent(talentId?: string) {
  return useValidDocumentData(
    talentId ? getDocRef("publicTalents", talentId) : null,
    isPublicTalent
  );
}
