import { ChipOwnProps } from "@mui/material";
import { BroadcastStatus } from "@super-real/types";

interface RecordValue {
  label: string;
  chipColor: ChipOwnProps["color"];
}

export const BROADCAST_STATUS_RECORD: Record<BroadcastStatus, RecordValue> = {
  SCHEDULED: {
    label: "Scheduled",
    chipColor: "default",
  },
  PREPARING: {
    label: "Preparing",
    chipColor: "primary",
  },
  SENDING_MESSAGES: {
    label: "Sending",
    chipColor: "primary",
  },
  COMPLETED: {
    label: "Completed",
    chipColor: "success",
  },
  DELETED: {
    label: "Deleted",
    chipColor: "error",
  },
};
