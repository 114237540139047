import { Alert, Divider, Stack, Typography } from "@mui/material";
import { PayoutStatus, Talent } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, Fragment } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { PAYOUT_STATUS_RECORD } from "../../Payout/consts/PAYOUT_STATUS_RECORD";
import { usePayouts } from "../../Payout/hooks/usePayouts";

interface Props {
  talent: Talent;
}

export const CreatorPayoutsView: FC<Props> = (props) => {
  const { talent } = props;
  const [payouts, isLoading, error] = usePayouts([
    ["talentId", "==", talent.id],
  ]);
  const isLoaded = !isLoading && !error;

  return (
    <Stack spacing={1}>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {isLoaded && !payouts.length && <Alert severity="info">No payouts</Alert>}
      {payouts.map((payout, index) => {
        const { Icon, iconColor } = PAYOUT_STATUS_RECORD[payout.status];

        return (
          <Fragment key={payout.id}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack>
                <Icon color={iconColor} />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h4">
                  {DateTime.fromJSDate(payout.createdAt.toDate()).toFormat(
                    "dd MMMM yyyy"
                  )}
                </Typography>
                <Typography variant="caption">
                  {DateTime.fromJSDate(payout.createdAt.toDate()).toFormat(
                    "h:mm a"
                  )}
                </Typography>
                {(["REJECTED", "FAILED"] as PayoutStatus[]).includes(
                  payout.status
                ) && (
                  <Alert severity="error">
                    {payout.rejectionReason || "Unknown reason"}
                  </Alert>
                )}
              </Stack>
              <Stack justifyContent="center">
                <Typography variant="ah5">
                  {toLocaleMoney(payout.value)}
                </Typography>
              </Stack>
            </Stack>
            {index !== payouts.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </Stack>
  );
};
