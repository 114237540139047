import { Chip, ChipOwnProps } from "@mui/material";
import { PayoutStatus } from "@super-real/types";
import { FC } from "react";
import { PAYOUT_STATUS_RECORD } from "../consts/PAYOUT_STATUS_RECORD";

interface Props {
  payoutStatus: PayoutStatus;
  size?: ChipOwnProps["size"];
}

export const PayoutStatusChip: FC<Props> = (props) => {
  const { payoutStatus, size } = props;
  const { chipColor, label } = PAYOUT_STATUS_RECORD[payoutStatus];

  return <Chip size={size} label={label} color={chipColor} />;
};
