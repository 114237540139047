import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import { Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { LoadingView } from "../../Common/views/LoadingView";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { createInvitationCallable } from "../callables/createInvitationCallable";
import { useTalentInvitations } from "../hooks/useTalentInvitations";
import { useTalentUsers } from "../hooks/useTalentUsers";

interface Props {
  talent: Talent;
}

/**
 * Credits: https://github.com/colinhacks/zod/issues/653#issuecomment-930293063
 */
const emailSchema = z.preprocess(
  (u: unknown) => (typeof u === "string" ? u.trim().toLowerCase() : u),
  z.string().email()
);

const FormValues = z.object({
  email: emailSchema,
});

type FormValues = z.infer<typeof FormValues>;

export const TalentManagersView: FC<Props> = (props) => {
  const { talent } = props;
  const [invitations, isLoadingInvitations] = useTalentInvitations(talent.id);
  const [users, isLoadingUsers] = useTalentUsers(talent.id);
  const talentId = talent.id;

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { email: "" },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { email } = formValues;

        await createInvitationCallable({
          talentId,
          email,
        });

        reset();
      } catch (error) {
        handleError(error);
      }
    },
    [reset, talentId]
  );

  const isLoading = formState.isSubmitting;
  const isInitializing = isLoadingInvitations || isLoadingUsers;

  return (
    <Stack spacing={3}>
      {isInitializing && <LoadingView />}
      {!isInitializing && (
        <Stack component={Paper} p={2} spacing={3}>
          <Typography variant="ah3">Managers</Typography>
          <Stack spacing={1} alignItems="flex-start">
            {!users.length && (
              <Typography>
                <em>No Managers</em>
              </Typography>
            )}
            {users.map((user) => (
              <Chip
                key={user.id}
                label={user.email || user.name || user.id}
                onDelete={() => toast.error("Sorry, not implemented yet.")}
              />
            ))}
          </Stack>
          <Typography variant="ah3">Invitations</Typography>
          <Stack spacing={1} alignItems="flex-start">
            {!invitations.length && (
              <Typography>
                <em>No invitation</em>
              </Typography>
            )}
            {invitations.map((invitation) => (
              <Chip
                key={invitation.id}
                label={invitation.email}
                onDelete={() => toast.error("Sorry, not implemented yet.")}
              />
            ))}
          </Stack>
          <Stack
            spacing={2}
            component="form"
            direction="row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledTextField
              name="email"
              control={control}
              disabled={isLoading}
              placeholder="Email"
              fullWidth
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={<SendIcon />}
            >
              Invite
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
