import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { auth } from "../../Firebase/consts/FIREBASE";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { createTalentDraftCallable } from "../../Talent/callables/createTalentDraftCallable";
import { updateTalentDraftCallable } from "../../Talent/callables/updateTalentDraftCallable";
import { useMyTalentDraft } from "../../Talent/hooks/useMyTalentDraft";
import { getOnboardingStepPath } from "../helpers/getOnboardingStepPath";

const FormValues = z.object({
  name: z.string().min(2).max(50),
});

type FormValues = z.infer<typeof FormValues>;

export const OnboardingTalentNamePage: FC = () => {
  const [authState] = useAuthState(auth);
  const talentDraft = useMyTalentDraft();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talentDraft?.name || authState?.displayName || "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (talentDraft) {
          await updateTalentDraftCallable({
            id: talentDraft.id,
            name: formValues.name,
          });
        } else {
          const { data, setData } = useLocalStorage.getState();
          await createTalentDraftCallable({
            name: formValues.name,
            ...(data.referralTalentTag && {
              referralTalentTag: data.referralTalentTag,
            }),
          });
          setData({ referralTalentTag: undefined });
        }
        navigate(getOnboardingStepPath("TALENT_NAME", "NEXT"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, talentDraft]
  );

  const isDisabled = formState.isSubmitting;

  return (
    <Stack
      mt={2}
      p={2}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <Typography variant="ah1" textAlign="center">
          🫶
        </Typography>
        <Typography variant="ah5" textAlign="center">
          Welcome to Super Real
        </Typography>
        <Typography variant="ah2" textAlign="center">
          You got invited to Super Real
        </Typography>
        <Typography variant="h4" textAlign="center">
          Let`s build your profile
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <ControlledTextField
          name="name"
          control={control}
          disabled={isDisabled}
          label="What should we call your AI twin?"
          fullWidth
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isDisabled}
          size="large"
          fullWidth
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};
