import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider, Stack, Typography } from "@mui/material";
import {
  TALENT_PERSONALITY_TRAITS,
  TalentPersonalityTrait,
} from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledChips } from "../../Form/views/ControlledChips";
import { updateTalentDraftCallable } from "../../Talent/callables/updateTalentDraftCallable";
import { TALENT_PERSONALITY_TRAIT_RECORD } from "../../Talent/consts/TALENT_PERSONALITY_TRAIT_RECORD";
import { useMyTalentDraft } from "../../Talent/hooks/useMyTalentDraft";
import { getOnboardingStepPath } from "../helpers/getOnboardingStepPath";

const FormValues = z.object({
  personalityTraits: z.array(TalentPersonalityTrait).min(1).max(3),
});

type FormValues = z.infer<typeof FormValues>;

const OPTIONS = TALENT_PERSONALITY_TRAITS.map((trait) => ({
  ...TALENT_PERSONALITY_TRAIT_RECORD[trait],
  value: trait,
}));

export const OnboardingTalentPersonalityTraitsPage: FC = () => {
  const navigate = useNavigate();
  const talentDraft = useMyTalentDraft();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      personalityTraits: talentDraft?.personalityTraits?.length
        ? talentDraft.personalityTraits
        : [],
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!talentDraft?.id) {
          throw new Error("Talent ID is not defined");
        }

        await updateTalentDraftCallable({
          id: talentDraft.id,
          personalityTraits: formValues.personalityTraits,
        });
        navigate(getOnboardingStepPath("TALENT_PERSONALITY_TRAITS", "NEXT"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, talentDraft?.id]
  );

  const isDisabled = formState.isSubmitting;

  return (
    <Stack
      mt={2}
      p={2}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <Typography variant="ah2" color="text.primary" textAlign="center">
          Describe the{" "}
          <Typography
            component="span"
            variant="ah2"
            color="background.gradient"
          >
            Personality
          </Typography>{" "}
          of your AI Twin
        </Typography>
        <Typography variant="ah5" color="text.secondary" textAlign="center">
          Pick Up to 3
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={4}>
        <ControlledChips
          name="personalityTraits"
          control={control}
          options={OPTIONS}
          disabled={isDisabled}
          min={1}
          max={3}
          sx={{ justifyContent: "center" }}
          errorSx={{ textAlign: "center" }}
        />
        <Stack spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isDisabled}
            size="large"
            fullWidth
          >
            Continue
          </Button>
          <Button
            disabled={isDisabled}
            size="large"
            startIcon={<ArrowBackIcon />}
            to={getOnboardingStepPath("TALENT_PERSONALITY_TRAITS", "PREVIOUS")}
            component={NavLink}
            fullWidth
          >
            Previous
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
