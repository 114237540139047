import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Paper, Stack } from "@mui/material";
import {
  PUBLIC_TALENT_PLACEMENTS,
  PublicTalent,
  PublicTalentPlacement,
} from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { adminUpdatePublicTalentCallable } from "../callables/adminUpdatePublicTalentCallable";
import { PUBLIC_TALENT_PLACEMENT_RECORD } from "../consts/PUBLIC_TALENT_PLACEMENT_RECORD";

const FormValues = z.object({
  name: z.string().nonempty(),
  order: z.number(),
  isVisibleRecord: z.record(PublicTalentPlacement, z.boolean()),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  publicTalent: PublicTalent;
}

export const PublicTalentForm: FC<Props> = (props) => {
  const { publicTalent } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: publicTalent.name,
      order: publicTalent.order,
      isVisibleRecord: PUBLIC_TALENT_PLACEMENTS.reduce((result, placement) => {
        result[placement] = !!publicTalent.isVisibleRecord?.[placement];
        return result;
      }, {} as Record<PublicTalentPlacement, boolean>),
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await adminUpdatePublicTalentCallable({
          id: publicTalent.id,
          name: formValues.name,
          order: formValues.order,
          isVisibleRecord: formValues.isVisibleRecord,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [publicTalent]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
          disabled={isLoading}
        />
        <ControlledTextField
          control={control}
          label="Sort Order"
          name="order"
          type="number"
          autoComplete="off"
          disabled={isLoading}
        />
        <Stack spacing={0}>
          {PUBLIC_TALENT_PLACEMENTS.map((placement) => (
            <ControlledSwitch
              key={placement}
              control={control}
              name={`isVisibleRecord.${placement}`}
              label={`${PUBLIC_TALENT_PLACEMENT_RECORD[placement].emoji} ${PUBLIC_TALENT_PLACEMENT_RECORD[placement].label}`}
            />
          ))}
        </Stack>
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
