import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, IconButton, Stack } from "@mui/material";
import { Attachment, Talent } from "@super-real/types";
import { FC, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { logEvent } from "../../../Common/helpers/logEvent";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";
import { FilePreview } from "../../../Talent/views/TalentForm/FilePreview";
import { InitialChatMessageFileInput } from "../CreatorSettingsProfilePage/InitialChatMessageFileInput";

const FormValues = z.object({
  initialChatMessages: z.array(
    z.object({
      id: z.string(),
      text: z.string().nonempty(),
      attachments: z.array(Attachment).optional(),
    })
  ),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent: Talent;
}

export const CreatorSettingsInitialChatMessagesForm: FC<Props> = (props) => {
  const { talent } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      initialChatMessages: talent.initialChatMessages,
    },
  });

  const initialChatMessages = useFieldArray({
    control,
    name: "initialChatMessages",
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateTalentCallable({
          id: talent.id,
          initialChatMessages: formValues.initialChatMessages,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      {initialChatMessages.fields.map((field, index) => {
        const imageAttachment = field.attachments?.filter(
          (a) => a.type === "IMAGE"
        )[0];

        return (
          <Stack
            key={field.id}
            spacing={1.5}
            direction="row"
            alignItems="center"
          >
            {imageAttachment && (
              <Stack width={IMAGE_SIZE} height={IMAGE_SIZE} position="relative">
                {imageAttachment.filePath && (
                  <FilePreview
                    type="IMAGE"
                    filePath={imageAttachment.filePath}
                  />
                )}
                <Stack position="absolute">
                  <IconButton
                    size="large"
                    disabled={isLoading}
                    onClick={() => {
                      const initialChatMessage = {
                        ...initialChatMessages.fields[index],
                      };

                      delete initialChatMessage.attachments;
                      initialChatMessages.update(index, initialChatMessage);
                      void handleSubmit(onSubmit)();
                    }}
                  >
                    <ClearIcon sx={{ color: "common.white" }} />
                  </IconButton>
                </Stack>
              </Stack>
            )}
            {!imageAttachment && (
              <InitialChatMessageFileInput
                talent={talent}
                onFileUploaded={async (uploadedFile) => {
                  if (uploadedFile.type !== "IMAGE") return;

                  initialChatMessages.update(index, {
                    ...initialChatMessages.fields[index],
                    attachments: [
                      {
                        type: "IMAGE",
                        filePath: uploadedFile.filePath,
                        dimensions: uploadedFile.dimensions,
                        isUnlocked: true,
                      },
                    ],
                  });

                  await handleSubmit(onSubmit)();
                  logEvent("uploaded_welcome_message_image");
                }}
              />
            )}
            <ControlledTextField
              control={control}
              label={getMessageLabel(index)}
              name={`initialChatMessages.${index}.text`}
              autoComplete="off"
              disabled={isLoading}
              endAdornment={
                index > 0 && (
                  <IconButton
                    onClick={() => initialChatMessages.remove(index)}
                    disabled={isLoading}
                    color="error"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                )
              }
              fullWidth
            />
          </Stack>
        );
      })}
      {initialChatMessages.fields.length < 3 && (
        <Button
          size="large"
          startIcon={<AddCircleIcon />}
          onClick={() =>
            initialChatMessages.append({
              id: v4(),
              text: "",
            })
          }
          disabled={isLoading}
        >
          Add message
        </Button>
      )}
      <Stack py={2}>
        <Divider />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};

function getMessageLabel(index: number) {
  switch (index) {
    case 0:
      return "First Message";
    case 1:
      return "Second Message";
    case 2:
      return "Third Message";
    default:
      return `Message ${index + 1}`;
  }
}

const IMAGE_SIZE = 48;
