import { isModel } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useModel(modelId?: string) {
  return useValidDocumentData(
    modelId ? getDocRef("models", modelId) : null,
    isModel
  );
}
