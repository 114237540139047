import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export function getDataFromDocumentSnapshot<T>(
  isT: (obj: unknown) => obj is T,
  doc?: DocumentSnapshot<DocumentData>
) {
  if (doc?.exists()) {
    const data = doc.data();

    if (isT(data)) {
      return data;
    }
  }
}
