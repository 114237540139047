import { useMyTalentConfigs } from "../../Talent/hooks/useMyTalentConfigs";
import { useEarningFrame } from "./useEarningFrame";

export const useDisbursableAmount = () => {
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);
  const [earningFrame, isLoading] = useEarningFrame(talentConfig.id, "total");

  const totalEarningsNetAmount = earningFrame?.totalNetAmount || 0;
  const totalPayoutAmount = talentConfig.totalPayoutAmount || 0;
  const disbursableAmount = totalEarningsNetAmount - totalPayoutAmount;

  const roundedDisbursableAmount =
    Math.floor(Math.max(disbursableAmount, 0) * 100) / 100;

  return [roundedDisbursableAmount, isLoading] as const;
};
