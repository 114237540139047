"use client";
import { NoSsr } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { MAIN_THEME } from "../consts/MAIN_THEME";
import { MainContentView } from "./MainContentView";
import { MainEmotionCacheProvider } from "./MainEmotionCache";
import { MainInitView } from "./MainInitView";
import { MainMinVersionView } from "./MainMinVersionView";
import { MainRouter } from "./MainRouter";

export const MainProvider: FC = () => {
  return (
    <NoSsr>
      <MainEmotionCacheProvider options={{ key: "mui" }}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ThemeProvider theme={MAIN_THEME}>
            <CssBaseline />
            <MainRouter>
              <MainInitView />
              <MainContentView />
              <MainMinVersionView />
            </MainRouter>
            <Toaster />
          </ThemeProvider>
        </LocalizationProvider>
      </MainEmotionCacheProvider>
    </NoSsr>
  );
};
