import { Skeleton, styled, SxProps } from "@mui/material";
import { Dimensions } from "@super-real/types";
import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react";
import { calculateAspectRatio } from "../helpers/calculateAspectRatio";
import { useStorageFile } from "../hooks/useStorageFile";

interface Props
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  filePath: string;
  dimensions?: Dimensions;
  sx?: SxProps;
}

export const StorageImage: FC<Props> = (props) => {
  const { filePath, dimensions, ...rest } = props;
  const { url, isLoading } = useStorageFile(filePath);
  const aspectRatio = calculateAspectRatio(dimensions);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height="auto"
        sx={{ aspectRatio, ...props.sx }}
      />
    );
  }

  return <Image src={url} alt="" sx={{ aspectRatio }} {...rest} />;
};

const Image = styled("img")(() => ({
  width: "100%",
  height: "auto",
}));
