import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { LoadingPage } from "../../Common/views/LoadingPage";

export const MainReferralPage: FC = () => {
  const { referralTalentTag } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!referralTalentTag) return;
    useLocalStorage.getState().setData({ referralTalentTag });
    navigate("/", { replace: true });
  }, [navigate, referralTalentTag]);

  return <LoadingPage />;
};
