import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppConfigPage } from "../../AppConfig/views/AppConfigPage";
import { BroadcastCreatePage } from "../../Broadcast/views/BroadcastCreatePage";
import { BroadcastPage } from "../../Broadcast/views/BroadcastPage";
import { BroadcastsPage } from "../../Broadcast/views/BroadcastsPage";
import { ChatPage } from "../../Chat/views/ChatPage";
import { ChatPromptDialog } from "../../Chat/views/ChatPromptDialog";
import { ChatReactionsPage } from "../../Chat/views/ChatReactionsPage";
import { ChatsPage } from "../../Chat/views/ChatsPage";
import { useIsAdminInterface } from "../../Common/hooks/useIsAdminInterface";
import { CreatorDashboardPage } from "../../Creator/views/CreatorDashboardPage";
import { CreatorEarningsPage } from "../../Creator/views/CreatorEarningsPage";
import { CreatorFeedbackPage } from "../../Creator/views/CreatorFeedbackPage";
import { CreatorLayout } from "../../Creator/views/CreatorLayout";
import { CreatorReferralPage } from "../../Creator/views/CreatorReferralPage";
import { CreatorSettingsChatMessageSuggestionsPage } from "../../Creator/views/CreatorSettingsChatMessageSuggestionsPage";
import { CreatorSettingsGalleryPage } from "../../Creator/views/CreatorSettingsGalleryPage";
import { CreatorSettingsInitialChatMessagesPage } from "../../Creator/views/CreatorSettingsInitialChatMessagesPage";
import { CreatorSettingsMonetizationPage } from "../../Creator/views/CreatorSettingsMonetizationPage";
import { CreatorSettingsPage } from "../../Creator/views/CreatorSettingsPage";
import { CreatorSettingsProfilePage } from "../../Creator/views/CreatorSettingsProfilePage";
import { CreatorSettingsVoicePage } from "../../Creator/views/CreatorSettingsVoicePage";
import { CreatorWithdrawPage } from "../../Creator/views/CreatorWithdrawPage";
import { FaqCreatePage } from "../../Faq/views/FaqCreatePage";
import { FaqPage } from "../../Faq/views/FaqPage";
import { FaqsPage } from "../../Faq/views/FaqsPage";
import { ModelCreatePage } from "../../Model/views/ModelCreatePage";
import { ModelPage } from "../../Model/views/ModelPage";
import { ModelsPage } from "../../Model/views/ModelsPage";
import { PaymentPage } from "../../Payment/views/PaymentPage";
import { PaymentsPage } from "../../Payment/views/PaymentsPage";
import { PayoutPage } from "../../Payout/views/PayoutPage";
import { PayoutsPage } from "../../Payout/views/PayoutsPage";
import { StatusPage } from "../../Status/views/StatusPage";
import { PublicTalentCreatePage } from "../../Talent/views/PublicTalentCreatePage";
import { PublicTalentPage } from "../../Talent/views/PublicTalentPage";
import { PublicTalentsPage } from "../../Talent/views/PublicTalentsPage";
import { TalentCreatePage } from "../../Talent/views/TalentCreatePage";
import { TalentPage } from "../../Talent/views/TalentPage";
import { TalentsPage } from "../../Talent/views/TalentsPage";
import { UserPage } from "../../User/views/UserPage";
import { UsersPage } from "../../User/views/UsersPage";
import { MainLayout } from "./MainLayout";

export const MainRoutes: FC = () => {
  const isAdmin = useIsAdminInterface();

  return (
    <>
      <Routes>
        {isAdmin && (
          <Route element={<MainLayout />}>
            <Route index element={<Navigate replace to="/status" />} />
            <Route path="status" element={<StatusPage />} />
            <Route path="app" element={<AppConfigPage />} />
            <Route path="talents" element={<TalentsPage />}>
              <Route path="create" element={<TalentCreatePage />} />
              <Route path=":talentId" element={<TalentPage />} />
            </Route>
            <Route path="chats" element={<ChatsPage />}>
              <Route path=":chatId" element={<ChatPage />} />
            </Route>
            <Route path="public-talents" element={<PublicTalentsPage />}>
              <Route path="create" element={<PublicTalentCreatePage />} />
              <Route path=":talentId" element={<PublicTalentPage />} />
            </Route>
            <Route path="reactions" element={<ChatReactionsPage />}>
              <Route path=":chatId/:chatMessageId" element={<ChatPage />} />
            </Route>
            <Route path="payments" element={<PaymentsPage />}>
              <Route path=":paymentId" element={<PaymentPage />} />
            </Route>
            <Route path="payouts" element={<PayoutsPage />}>
              <Route path=":payoutId" element={<PayoutPage />} />
            </Route>
            <Route path="models" element={<ModelsPage />}>
              <Route path="create" element={<ModelCreatePage />} />
              <Route path=":modelId" element={<ModelPage />} />
            </Route>
            <Route path="faqs" element={<FaqsPage />}>
              <Route path="create" element={<FaqCreatePage />} />
              <Route path=":faqId" element={<FaqPage />} />
            </Route>
            <Route path="users" element={<UsersPage />}>
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="broadcasts" element={<BroadcastsPage />}>
              <Route path="create" element={<BroadcastCreatePage />} />
              <Route path=":broadcastId" element={<BroadcastPage />} />
            </Route>
          </Route>
        )}
        {!isAdmin && (
          <Route element={<CreatorLayout />}>
            <Route index element={<CreatorDashboardPage />} />
            <Route path="referrals" element={<CreatorReferralPage />} />
            <Route path="earnings">
              <Route index element={<CreatorEarningsPage />} />
              <Route path="withdraw" element={<CreatorWithdrawPage />} />
            </Route>
            <Route path="settings">
              <Route index element={<CreatorSettingsPage />} />
              <Route path="profile" element={<CreatorSettingsProfilePage />} />
              <Route path="gallery" element={<CreatorSettingsGalleryPage />} />
              <Route path="voice" element={<CreatorSettingsVoicePage />} />
              <Route
                path="welcome-messages"
                element={<CreatorSettingsInitialChatMessagesPage />}
              />
              <Route
                path="conversation-starters"
                element={<CreatorSettingsChatMessageSuggestionsPage />}
              />
              <Route
                path="monetisation"
                element={<CreatorSettingsMonetizationPage />}
              />
            </Route>
            <Route path="feedback" element={<CreatorFeedbackPage />} />
          </Route>
        )}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ChatPromptDialog />
    </>
  );
};
