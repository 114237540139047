import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FirebaseFilter, FirebaseOrderBy, User } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useUsers } from "../hooks/useUsers";

const FILTER_OPTIONS: {
  label: string;
  orderBys: FirebaseOrderBy<User>[];
  filters: FirebaseFilter<User>[];
  sort: (a: User, b: User) => number;
  getValue: (user: User) => number | string;
}[] = [
  {
    label: "All",
    filters: [],
    orderBys: [["createdAt", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) =>
      user.createdAt
        ? DateTime.fromJSDate(user.createdAt.toDate()).toFormat("d MMM")
        : "",
  },
  {
    label: "Talent Manager",
    filters: [["managedTalentIds", "!=", []]],
    orderBys: [["managedTalentIds", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) =>
      user.createdAt
        ? DateTime.fromJSDate(user.createdAt.toDate()).toFormat("d MMM")
        : "",
  },
  {
    label: "Admin",
    filters: [["role", "==", "ADMIN"]],
    orderBys: [["role", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) =>
      user.createdAt
        ? DateTime.fromJSDate(user.createdAt.toDate()).toFormat("d MMM")
        : "",
  },
  {
    label: "Waitlist - Pending",
    filters: [["waitlistStatus", "==", "PENDING"]],
    orderBys: [["createdAt", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) =>
      user.createdAt
        ? DateTime.fromJSDate(user.createdAt.toDate()).toFormat("d MMM")
        : "",
  },
  {
    label: "Waitlist - Accepted",
    filters: [["waitlistStatus", "==", "ACCEPTED"]],
    orderBys: [["createdAt", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) => (user.managedTalentIds?.length ? "Live" : "Onboarding"),
  },
  {
    label: "Waitlist - Rejected",
    filters: [["waitlistStatus", "==", "REJECTED"]],
    orderBys: [["createdAt", "desc"]],
    sort: (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
    getValue: (user) =>
      user.createdAt
        ? DateTime.fromJSDate(user.createdAt.toDate()).toFormat("d MMM")
        : "",
  },
];

export const UsersPage: FC = () => {
  const [filterIndex, setFilterIndex] = useState(3);
  const filters = FILTER_OPTIONS[filterIndex]?.filters || [];
  const orderBys = FILTER_OPTIONS[filterIndex]?.orderBys || [];
  const [users, , error, controller] = useUsers(filters, orderBys);

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Typography variant="ah2">Users</Typography>
        <FormControl>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            value={filterIndex}
            onChange={(event) => setFilterIndex(event.target.value as number)}
            startAdornment={
              <InputAdornment position="start">
                <FilterAltIcon />
              </InputAdornment>
            }
          >
            {FILTER_OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && <ErrorView error={error} />}
      </Stack>
      {!!users.length && (
        <MainSecondarySidebarList>
          {users.sort(FILTER_OPTIONS[filterIndex]?.sort).map((user) => (
            <ListItemButton
              key={user.id}
              to={`/users/${user.id}`}
              component={NavLink}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                spacing={1}
              >
                <Stack
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  display="inline-block"
                >
                  {user.name || user.email || user.id}
                </Stack>
                {
                  <Chip
                    size="small"
                    label={FILTER_OPTIONS[filterIndex]?.getValue(user) || ""}
                  />
                }
              </Stack>
            </ListItemButton>
          ))}
          {controller.hasMore && (
            <Stack mx={2} my={1}>
              <Button onClick={controller.loadMore}>Load more</Button>
            </Stack>
          )}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
