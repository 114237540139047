export const ONBOARDING_STEPS = [
  "TALENT_NAME",
  "TALENT_PROFILE_PICTURE",
  "TALENT_TAG",
  "TALENT_GENDER_AGE",
  "TALENT_PURPOSE",
  "TALENT_PERSONALITY_TRAITS",
  "TALENT_INTERESTS",
  "TALENT_INITIAL_CHAT_MESSAGE",
] as const;

export type OnboardingStep = (typeof ONBOARDING_STEPS)[number];
