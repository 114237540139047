import { EarningFrame } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { DateTime, Interval } from "luxon";

interface Params {
  earningFrames: EarningFrame[];
  fromDate: DateTime;
  toDate: DateTime;
}

export function normalizeEarningFramesByDay(params: Params): EarningFrame[] {
  const { earningFrames, fromDate, toDate } = params;

  return Interval.fromDateTimes(fromDate, toDate.endOf("day"))
    .splitBy({ days: 1 })
    .map<EarningFrame | undefined>((interval) => {
      const dayStartedAt = interval.start || DateTime.local();

      // filter out future days
      if (dayStartedAt > DateTime.local()) return undefined;

      const dayId = dayStartedAt.toFormat("yyyy-MM-dd");
      const frame = earningFrames.find(({ id }) => id === dayId);

      if (frame) return frame;

      return {
        id: dayId,
        range: "DAY",
        currency: "USD",
        startedAt: Timestamp.fromMillis(dayStartedAt.toMillis()),
        endedAt: Timestamp.fromMillis(
          dayStartedAt.plus({ days: 1 }).toMillis()
        ),
        talentId: "INVALID_TALENT_ID",
        totalGrossAmount: 0,
        totalNetAmount: 0,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      } satisfies EarningFrame;
    })
    .filter(Boolean) as EarningFrame[];
}
