import { useEffect, useRef, useState } from "react";
import { handleError } from "../helpers/handleError";
import { useStorageFile } from "./useStorageFile";

export const useAudio = () => {
  const [filePath, setFilePath] = useState<string>();
  const { url } = useStorageFile(filePath);
  const audioRef = useRef(new Audio(url));

  useEffect(() => {
    audioRef.current.pause();
  }, [filePath]);

  useEffect(() => {
    if (!url) return;
    audioRef.current.setAttribute("src", url);
    void audioRef.current.play().catch(handleError);
  }, [url]);

  useEffect(() => {
    const audio = audioRef.current;
    const resetFilePath = () => setFilePath(undefined);
    audio.addEventListener("ended", resetFilePath);

    return () => {
      audio.pause();
      audio.removeEventListener("ended", resetFilePath);
    };
  }, []);

  return [filePath, setFilePath] as const;
};
