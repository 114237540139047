import { logEvent as logFirebaseAnalyticsEvent } from "firebase/analytics";
import { analytics } from "../../Firebase/consts/FIREBASE";

type EventName =
  | "signed_in"
  | "completed_talent_onboarding"
  | "uploaded_welcome_message_image"
  | "uploaded_gallery_image";

export function logEvent(eventName: EventName, itemId?: string) {
  if (!analytics) return;

  try {
    logFirebaseAnalyticsEvent(analytics, eventName, {
      ...(itemId && { item_id: itemId }),
    });
  } catch {
    // Do not throw an error!
  }
}
