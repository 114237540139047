import { isPayment } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function usePayment(paymentId?: string) {
  return useValidDocumentData(
    paymentId ? getDocRef("payments", paymentId) : null,
    isPayment
  );
}
