import { PublicTalentPlacement } from "@super-real/types";

interface RecordValue {
  label: string;
  emoji: string;
}

export const PUBLIC_TALENT_PLACEMENT_RECORD: Record<
  PublicTalentPlacement,
  RecordValue
> = {
  VENUS_DISCOVER: {
    label: "Venus: Discover",
    emoji: "📱",
  },
  VENUS_DISCOVER_FOR_REVIEW: {
    label: "Venus: Discover in Review",
    emoji: "🕵️‍♂️",
  },
  HOME_PAGE_FOR_SUPER_REAL: {
    label: "Homepage: super-real.co",
    emoji: "🏠",
  },
  HOME_PAGE_FOR_SUPER_SEXY: {
    label: "Homepage: supersexy.ai",
    emoji: "🔞",
  },
};
