import { create } from "zustand";
import { persist } from "zustand/middleware";

const LOCAL_STORAGE_KEY = "SUPER_REAL";

export const DEFAULT_LOCAL_STORAGE: LocalStorage = {
  referralTalentTag: undefined,
  isAdmin: false,
  areChatImagesVisible: false,
};

export interface LocalStorage {
  referralTalentTag?: string;
  isAdmin?: boolean;
  areChatImagesVisible?: boolean;
}

interface LocalStorageState {
  data: LocalStorage;
  setData: (next: Partial<LocalStorage>) => void;
}

export const useLocalStorage = create<LocalStorageState>()(
  persist(
    (set, get) => ({
      data: { ...DEFAULT_LOCAL_STORAGE },

      setData: (next) => {
        set({
          data: {
            ...DEFAULT_LOCAL_STORAGE,
            ...get().data,
            ...next,
          },
        });
      },
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({
        data: state.data,
      }),
    }
  )
);
