import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { createTalentCallable } from "../callables/createTalentCallable";

const FormValues = z.object({
  name: z.string().nonempty(),
});

export type FormValues = z.infer<typeof FormValues>;

export const TalentCreatePage: FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { talentId } = await createTalentCallable({
          name: formValues.name,
        });
        navigate(`/talents/${talentId}`);
        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [navigate]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Typography variant="ah2">Create Talent</Typography>
      <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
        />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ alignSelf: "flex-start" }}
          disabled={isLoading}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
};
