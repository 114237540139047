import { isTalentMetricFrame } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useTalentMetricFrame(
  talentId?: string,
  talentMetricFrameId?: string
) {
  return useValidDocumentData(
    talentId && talentMetricFrameId
      ? getDocRef(`talentConfigs/${talentId}/metricFrames`, talentMetricFrameId)
      : null,
    isTalentMetricFrame
  );
}
