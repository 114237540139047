import { isTalentDraft, TalentDraft } from "@super-real/types";
import { onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";
import { useOnboardingStep } from "../../Onboarding/hooks/useOnboardingStep";

interface State {
  talentDrafts: TalentDraft[];
  isInitializing: boolean;
  subscribe: (userId: string) => void;
  unsubscribe?: () => void;
}

export const useMyTalentDrafts = create<State>((set) => ({
  talentDrafts: [],
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      query(getCollectionRef("talentDrafts"), where("userId", "==", userId)),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const talentDrafts = getDataFromCollectionSnapshot(
          isTalentDraft,
          snapshot
        );

        set({ talentDrafts, isInitializing: false });
        useOnboardingStep.getState().refresh();
      },
      () => {
        set({ talentDrafts: [], isInitializing: false });
        useOnboardingStep.getState().refresh();
      }
    );

    set({
      unsubscribe: () => {
        set({ talentDrafts: [], isInitializing: true });
        useOnboardingStep.getState().refresh();
        unsubscribeFirestore();
      },
    });
  },
}));
