import { create } from "zustand";

interface State {
  isOpen: boolean;
  chatId?: string;
  chatMessageId?: string;
  open: (chatId: string, chatMessageId: string) => void;
  close: () => void;
}

export const useChatPromptDialog = create<State>((set) => ({
  isOpen: false,
  open: (chatId, chatMessageId) => {
    set({ isOpen: true, chatId, chatMessageId });
  },
  close: () => {
    set({ isOpen: false, chatId: undefined, chatMessageId: undefined });
  },
}));
