import { isBroadcast } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useBroadcast(broadcastId?: string) {
  return useValidDocumentData(
    broadcastId ? getDocRef("broadcasts", broadcastId) : null,
    isBroadcast
  );
}
