import { lighten, Stack, styled, Typography, useTheme } from "@mui/material";
import { ChatMessage } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, Fragment, useCallback } from "react";
import { useApp } from "../../AppConfig/hooks/useApp";
import { StorageFile } from "../../Common/types/StorageFile";
import { toReactionEmoji } from "../helpers/toReactionEmoji";
import { useChatPromptDialog } from "../hooks/useChatPromptDialog";
import { ChatAttachmentImageView } from "./ChatAttachmentImageView";

interface Props {
  chatId?: string;
  chatMessage: ChatMessage;
  talentProfilePicture?: StorageFile;
  isSelected: boolean;
  areChatImagesVisible?: boolean;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatId, chatMessage, isSelected, areChatImagesVisible } = props;
  const { palette } = useTheme();

  const emptyChatMessageText = useApp(
    (state) => state.app?.emptyChatMessageText
  );

  const onClick = useCallback(() => {
    if (!chatId) return;

    useChatPromptDialog.getState().open(chatId, chatMessage.id);
  }, [chatId, chatMessage.id]);

  return (
    <Stack
      px={1}
      direction={chatMessage.senderType === "USER" ? "row-reverse" : "row"}
      justifyContent="flex-start"
      spacing={1}
    >
      <Stack
        maxWidth="80%"
        width={chatMessage.attachments?.length ? "100%" : undefined}
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      >
        <Stack
          p={1.5}
          borderRadius="18px"
          border={1}
          bgcolor={
            isSelected
              ? lighten(palette.success.main, 0.8)
              : "background.default"
          }
          borderColor={isSelected ? "success.main" : "grey.400"}
          position="relative"
          spacing={1}
        >
          {chatMessage.attachments?.map((attachment, index) => (
            <Fragment key={index}>
              {attachment.type === "IMAGE" && !areChatImagesVisible && (
                <Stack>
                  🏞️ {attachment.isUnlocked ? "Unlocked" : "Locked"} Image
                </Stack>
              )}
              {attachment.type === "IMAGE" && areChatImagesVisible && (
                <ChatAttachmentImageView attachment={attachment} />
              )}
              {attachment.type === "AUDIO" && (
                <Stack>
                  🔈 {attachment.isUnlocked ? "Unlocked" : "Locked"} Audio
                </Stack>
              )}
            </Fragment>
          ))}
          <Typography variant="body2">
            {chatMessage.text || emptyChatMessageText}
          </Typography>

          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <Typography
              fontSize={10}
              color="text.secondary"
              textAlign="right"
              whiteSpace="nowrap"
            >
              {DateTime.fromJSDate(chatMessage.createdAt.toDate()).toFormat(
                "dd. LLL HH:mm"
              )}
            </Typography>

            {chatMessage.reactionFeedback && (
              <Typography fontSize={10} color="text.secondary">
                Feedback: {chatMessage.reactionFeedback}
              </Typography>
            )}
          </Stack>

          {!!chatMessage.reaction && (
            <SReactionView>
              {toReactionEmoji(chatMessage.reaction)}
            </SReactionView>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const REACTION_SIZE = 24;

const SReactionView = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: REACTION_SIZE / -3,
  right: theme.spacing(1),
  width: REACTION_SIZE,
  height: REACTION_SIZE,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[500],
  fontSize: 12,
  borderRadius: REACTION_SIZE / 2,
  alignItems: "center",
  justifyContent: "center",
}));
