import {
  FirebaseFilter,
  isTalentConfig,
  TalentConfig,
} from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useTalentConfigs(filters?: FirebaseFilter<TalentConfig>[]) {
  return useValidCollectionData(
    query(
      getCollectionRef("talentConfigs"),
      ...(filters ? filters.map((filter) => where(...filter)) : [])
    ),
    isTalentConfig
  );
}
