import { GetStaticPaths, GetStaticProps } from "next";
import { MainProvider } from "../Main/views/MainProvider";

export default MainProvider;

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
  };
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [
      {
        params: {
          slug: [] as string[],
        },
      },
    ],
    fallback: "blocking",
  };
};
