import { Analytics, getAnalytics, isSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";

const { isEmulator } = ENVIRONMENT.firebase;
const region = isEmulator ? "us-central1" : ENVIRONMENT.firebase.region;

export const app = initializeApp({
  ...ENVIRONMENT.firebase,
  ...(isEmulator && { projectId: "local" }),
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, region);
export const storage = getStorage(app);
export let analytics: Analytics | undefined = undefined;

void (async () => {
  if (ENVIRONMENT.nodeEnv !== "production") return;
  const isAnalyticsSupported = await isSupported();
  if (!isAnalyticsSupported) return;
  analytics = getAnalytics(app);
})();

/**
 * To use this you have to set NEXT_PUBLIC_FIREBASE_IS_EMULATOR=true in .env
 */
if (isEmulator) {
  const host = "localhost";

  connectFunctionsEmulator(functions, host, 5001);
  connectFirestoreEmulator(db, host, 8080);
  connectAuthEmulator(auth, `http://${host}:9099`, { disableWarnings: true });
  connectStorageEmulator(storage, host, 9199);
}
