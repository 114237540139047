import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { usePayout } from "../hooks/usePayout";
import { PayoutForm } from "./PayoutForm";

export const PayoutPage: FC = () => {
  const { payoutId } = useParams<"payoutId">();
  const [payout, isLoading, error] = usePayout(payoutId);
  const isLoaded = !isLoading && !error;

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="ah2">Payout</Typography>
      </Stack>
      <Stack spacing={2} flex={1}>
        {error && <ErrorView error={error} />}
        {isLoading && <LoadingView />}
        {isLoaded && payout && <PayoutForm payout={payout} />}
      </Stack>
    </Stack>
  );
};
