import { Chip, Skeleton, Stack } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTalent } from "../hooks/useTalent";

interface Props {
  talentId: string;
}

export const TalentChip: FC<Props> = (props) => {
  const { talentId } = props;
  const [talent, isLoading] = useTalent(talentId);

  return (
    <Chip
      to={`/talents/${talentId}`}
      component={NavLink}
      sx={{ cursor: "pointer" }}
      label={
        <Stack direction="row" spacing={0.5}>
          <Stack>Talent:</Stack>
          {isLoading && <Skeleton width={75} />}
          {!isLoading && <Stack>{talent?.name || talentId.slice(0, 8)}</Stack>}
        </Stack>
      }
    />
  );
};
