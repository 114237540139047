import { TalentPurpose } from "@super-real/types";

interface RecordValue {
  label: string;
}

export const TALENT_PURPOSE_RECORD: Record<TalentPurpose, RecordValue> = {
  BANTER: {
    label: "🤣 Banter",
  },
  FLIRT: {
    label: "🫦 Flirt",
  },
  EDUCATE: {
    label: "📚‍ Educate",
  },
  BULLY: {
    label: "😭 Be a bully",
  },
};
