interface Params {
  amount: number;
  currency: string;
  fractionDigits?: number;
}

/**
 * @deprecated use `toLocaleMoney` instead if possible
 */
export function toLocalePrice(params: Params) {
  return params.amount.toLocaleString(undefined, {
    minimumFractionDigits: params.fractionDigits,
    maximumFractionDigits: params.fractionDigits,
    style: "currency",
    currency: params.currency.toUpperCase(),
  });
}
