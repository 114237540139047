import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloudIcon from "@mui/icons-material/Cloud";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StarIcon from "@mui/icons-material/Star";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useMediaQueryDown } from "../../Common/hooks/useMediaQueryDown";
import { auth } from "../../Firebase/consts/FIREBASE";
import { MAIN_HEADER_HEIGHT } from "../consts/MAIN_HEADER_HEIGHT";
import { MAIN_SIDEBAR_WIDTH } from "../consts/MAIN_SIDEBAR_WIDTH";
import { MainSidebarListItemView } from "./MainSidebarListItemView";

export const MainSidebarView: FC = () => {
  const navigate = useNavigate();
  const isSmall = useMediaQueryDown("sm");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isSmall && (
        <>
          <Stack
            top={0}
            left={0}
            right={0}
            height={MAIN_HEADER_HEIGHT}
            bgcolor="background.paper"
            position="fixed"
            direction="row"
            zIndex={10}
            px={1}
            borderBottom={1}
            borderColor="grey.500"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              <MenuIcon />
            </IconButton>
          </Stack>
          <Stack height={MAIN_HEADER_HEIGHT} />
        </>
      )}
      <SDrawer
        anchor="left"
        variant={isSmall ? "temporary" : "persistent"}
        open={isOpen || !isSmall}
        onClose={() => setIsOpen(false)}
      >
        <SList>
          <MainSidebarListItemView
            Icon={OfflineBoltIcon}
            label="Status"
            path="/status"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={CloudIcon}
            label="App"
            path="/app"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={StarIcon}
            label="Talents"
            path="/talents"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={QuestionAnswerIcon}
            label="Chats"
            path="/chats"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={HomeIcon}
            label="Discover"
            path="/public-talents"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={ThumbUpIcon}
            label="Reactions"
            path="/reactions"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={ShoppingCartIcon}
            label="Payments"
            path="/payments"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={AccountBalanceIcon}
            label="Payouts"
            path="/payouts"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={LightbulbIcon}
            label="Models"
            path="/models"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={ContactSupportIcon}
            label="FAQ"
            path="/faqs"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={GroupIcon}
            label="Users"
            path="/users"
            close={() => setIsOpen(false)}
          />
          <MainSidebarListItemView
            Icon={CampaignIcon}
            label="Broadcast"
            path="/broadcasts"
            close={() => setIsOpen(false)}
          />
        </SList>
        <Stack flex={1} />
        <SList>
          <ListItemButton
            onClick={() => {
              useLocalStorage.getState().setData({ isAdmin: false });
              navigate("/");
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Talent UI" />
          </ListItemButton>
          {ENVIRONMENT.nodeEnv === "development" && (
            <ListItemButton
              component="a"
              target="_blank"
              href="http://localhost:4000"
            >
              <ListItemIcon>
                <WhatshotIcon />
              </ListItemIcon>
              <ListItemText primary="Emulator" />
            </ListItemButton>
          )}
          <ListItemButton onClick={() => void auth.signOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </SList>
      </SDrawer>
    </>
  );
};

const SDrawer = styled(Drawer)(() => ({
  width: `${MAIN_SIDEBAR_WIDTH}px`,
  ".MuiDrawer-paper": {
    border: "none",
    width: `${MAIN_SIDEBAR_WIDTH}px`,
    boxSizing: "border-box",
  },
}));

const SList = styled(List)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    "&.active": {
      color: theme.palette.primary.main,
      ".MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: 38,
  },
}));
