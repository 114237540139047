import { Paper, Stack, Typography } from "@mui/material";
import { TalentConfig } from "@super-real/types";
import { FC } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useEarningFrameTotal } from "../../Creator/hooks/useEarningFrameTotal";
import { useTalentMetricFrame } from "../../Creator/hooks/useTalentMetricFrame";
import { CreatorEarningFrameMonthsView } from "../../Creator/views/CreatorEarningFrameMonthsView";

interface Props {
  talentConfig: TalentConfig;
}

export const TalentAnalyticsView: FC<Props> = (props) => {
  const { talentConfig } = props;
  const [earningFrameTotal, isLoadingEarningFrame] = useEarningFrameTotal(
    talentConfig.id
  );
  const [talentMetricTotal, isLoadingMetricFrame] = useTalentMetricFrame(
    talentConfig.id,
    "total"
  );

  const isLoading = isLoadingEarningFrame || isLoadingMetricFrame;

  return (
    <Stack spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Analytics</Typography>
        <Stack direction="row" spacing={2}>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Chats
            </Typography>
            <Typography variant="ah3">{talentConfig.chatCount}</Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Registered users
            </Typography>
            <Typography variant="ah3">
              {!isLoading &&
                (talentMetricTotal?.chatWithAuthenticationCount || 0)}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Paying users
            </Typography>
            <Typography variant="ah3">
              {!isLoading && (talentMetricTotal?.chatWithPaymentCount || 0)}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Total Gross
            </Typography>
            <Typography variant="ah3">
              {!isLoading &&
                toLocaleMoney({
                  amount: earningFrameTotal?.totalGrossAmount || 0,
                })}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Total Net
            </Typography>
            <Typography variant="ah3">
              {!isLoading &&
                toLocaleMoney({
                  amount: earningFrameTotal?.totalNetAmount || 0,
                })}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography variant="ah6" color="text.secondary">
              Total Payouts
            </Typography>
            <Typography variant="ah3">
              {toLocaleMoney({
                amount: talentConfig.totalPayoutAmount || 0,
              })}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Earnings</Typography>
        <CreatorEarningFrameMonthsView talentId={talentConfig.id} />
      </Stack>
    </Stack>
  );
};
