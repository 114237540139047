import { EarningFrame } from "@super-real/types";

export const sumEarningFrameAmounts = (earningFrames: EarningFrame[]) => {
  return earningFrames.reduce(
    (result, item) => {
      result.totalGrossAmount += item.totalGrossAmount || 0;
      result.totalNetAmount += item.totalNetAmount || 0;
      result.sendMessageGrossAmount += item.sendMessageGrossAmount || 0;
      result.sendMessageNetAmount += item.sendMessageNetAmount || 0;
      result.unlockImageGrossAmount += item.unlockImageGrossAmount || 0;
      result.unlockImageNetAmount += item.unlockImageNetAmount || 0;
      result.unlockAudioGrossAmount += item.unlockAudioGrossAmount || 0;
      result.unlockAudioNetAmount += item.unlockAudioNetAmount || 0;
      result.referralGrossAmount += item.referralGrossAmount || 0;
      result.referralNetAmount += item.referralNetAmount || 0;
      result.subscriptionGrossAmount += item.subscriptionGrossAmount || 0;
      result.subscriptionNetAmount += item.subscriptionNetAmount || 0;
      return result;
    },
    {
      totalGrossAmount: 0,
      totalNetAmount: 0,
      sendMessageGrossAmount: 0,
      sendMessageNetAmount: 0,
      unlockImageGrossAmount: 0,
      unlockImageNetAmount: 0,
      unlockAudioGrossAmount: 0,
      unlockAudioNetAmount: 0,
      referralGrossAmount: 0,
      referralNetAmount: 0,
      subscriptionGrossAmount: 0,
      subscriptionNetAmount: 0,
    }
  );
};
