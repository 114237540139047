import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Avatar, Chip, Stack, styled, Typography } from "@mui/material";
import { FileOwnerType } from "@super-real/types";
import { FC } from "react";
import { useUploadPublicImages } from "../../Common/hooks/useUploadPublicImages";
import { FileInput } from "../../Common/views/FileInput";

interface Props {
  ownerId: string;
  ownerType: FileOwnerType;
  url?: string;
  onChange(url: string): void;
}

export const OnboardingTalentProfilePictureInput: FC<Props> = (props) => {
  const { url } = props;

  const [uploadFiles, isLoading] = useUploadPublicImages({
    ownerId: props.ownerId,
    ownerType: props.ownerType,
    onFileUploaded: (url) => props.onChange(url),
  });

  return (
    <FileInput
      onSelect={uploadFiles}
      accept="image/jpg,image/jpeg,image/png,image/webp"
      disabled={isLoading}
    >
      <Stack alignItems="center" onClick={(e) => e.preventDefault()}>
        <SAvatar src={url} sx={{ opacity: isLoading ? 0.3 : 1 }} />
        <SChip
          label={isLoading ? "Uploading..." : "Add photo"}
          avatar={isLoading ? undefined : <CameraAltIcon />}
        />
        <Typography variant="caption" color="grey.300" fontWeight={700}>
          (Optional)
        </Typography>
      </Stack>
    </FileInput>
  );
};

const SAvatar = styled(Avatar)(({ theme }) => ({
  height: 200,
  width: 200,
  backgroundColor: theme.palette.text.primary,
  fontSize: 80,
}));

const SChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(-3),
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  borderRadius: 20,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontWeight: 700,
  boxShadow: `0px 2px 2px 0px ${theme.palette.grey[300]}`,
  zIndex: 1,

  "& .MuiChip-avatar": {
    width: 16,
    height: 16,
    color: theme.palette.text.primary,
  },
}));
