import { FC } from "react";
import { MainPage } from "../../Main/views/MainPage";
import { ErrorView } from "./ErrorView";

interface Props {
  error: Error;
}

export const ErrorPage: FC<Props> = (props) => {
  const { error } = props;

  return (
    <MainPage>
      <ErrorView error={error} />
    </MainPage>
  );
};
