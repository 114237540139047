import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

type Option = {
  value: string | number | boolean;
  label: string;
};

interface Props<TFields extends FieldValues, TData extends Option> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  options: TData[];
  disabled?: boolean;
  renderLabel?: (option: TData, isSelected: boolean) => ReactNode;
  required?: boolean;
}

export function ControlledRadioButtons<
  TFields extends FieldValues,
  TData extends Option
>(props: Props<TFields, TData>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          error={!!fieldState.error}
          disabled={props.disabled}
        >
          <RadioGroup {...field}>
            {props.options?.map((option: TData) => (
              <SFormControlLabel
                key={String(option.value)}
                value={option.value}
                label={
                  props.renderLabel?.(option, option.value === field.value) ||
                  option.label
                }
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}

const SFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
  "& .MuiRadio-root": {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      width: 38,
      height: 38,
    },
  },
}));
