import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { z } from "zod";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { auth } from "../consts/FIREBASE";

const SearchParams = z.object({
  email: z.string(),
  mode: z.enum(["signIn"]),
});

interface Args {
  pathname: string;
  search: string;
  onStart: () => void;
  onCompletion: () => void;
}

export async function processEmailLink(args: Args) {
  const { pathname, search, onStart, onCompletion } = args;
  const emailLink = `${ENVIRONMENT.domain}${pathname}${search}`;

  // Get the search params
  const unsafeSearchParams = Object.fromEntries(new URLSearchParams(search));
  const result = SearchParams.safeParse(unsafeSearchParams);
  if (!result.success) return;
  const { mode, email } = result.data;

  // Do not throw for the following conditions
  if (mode !== "signIn") return;
  if (!isSignInWithEmailLink(auth, emailLink)) return;

  try {
    onStart();
    await signInWithEmailLink(auth, email, emailLink);
    logEvent("signed_in");
  } catch (error) {
    handleError(error);
  } finally {
    // Please note: We also complete, when an error is thrown
    onCompletion();
  }
}
