import { Chip, Stack, TextField, Typography } from "@mui/material";
import { ChatPrompt, ChatPromptMessageType } from "@super-real/types";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabInput: FC<Props> = (props) => {
  const { chatPrompt } = props;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          value={
            chatPrompt.llmFineTunedModelId ||
            chatPrompt.llmType ||
            "openai/gpt-3.5-turbo"
          }
          label="LLM Model"
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
        <TextField
          value={chatPrompt.llmTemperature || "1"}
          label="LLM Temperature"
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
      </Stack>

      {chatPrompt.messages?.length && (
        <>
          <Typography variant="ah5">Messages</Typography>
          {chatPrompt.messages.map((message, index) => (
            <TextField
              key={index}
              value={message.text}
              label={toOpenAiLabel(message.type)}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
              multiline
            />
          ))}
        </>
      )}

      {!!chatPrompt.faqItems?.length && !!chatPrompt.faqMinScore && (
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="ah5">FAQ</Typography>
            <Chip
              label={`Min Score: ${(chatPrompt.faqMinScore * 100).toFixed(2)}%`}
              size="small"
            />
          </Stack>
          {chatPrompt.faqItems?.map((faqItem, index) => (
            <Stack direction="row" key={index} spacing={2}>
              <TextField
                value={`${(faqItem.score * 100).toFixed(2)}%`}
                label="Score"
                sx={{ width: 100, input: { fontSize: 14 } }}
              />
              <TextField
                value={faqItem.question}
                label="Question"
                sx={{ input: { fontSize: 14 } }}
                fullWidth
              />
            </Stack>
          ))}
        </>
      )}

      {!!chatPrompt.functions?.length && (
        <>
          <Typography variant="ah5">Fuctions</Typography>
          {chatPrompt.functions?.map((functionSchema, index) => (
            <TextField
              key={index}
              value={JSON.stringify(functionSchema, null, 4)}
              label={`Function: ${functionSchema.name}`}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
              multiline
            />
          ))}
        </>
      )}
    </Stack>
  );
};

function toOpenAiLabel(type: ChatPromptMessageType) {
  switch (type) {
    case "SYSTEM":
      return "System";
    case "USER":
      return "User";
    case "TALENT":
      return "Assistant";
  }
}
