import { isTalentConfig } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useTalentConfig(talentConfigId?: string) {
  return useValidDocumentData(
    talentConfigId ? getDocRef("talentConfigs", talentConfigId) : null,
    isTalentConfig
  );
}
