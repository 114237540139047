import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Paper, Stack, Typography } from "@mui/material";
import { isLlmType } from "@super-real/types/types/LlmType";
import { FC } from "react";
import { useAppConfig } from "../../AppConfig/hooks/useAppConfig";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";

export const StatusPage: FC = () => {
  const [appConfig, isLoading, error] = useAppConfig("default");
  const isModelHealthyRecord = appConfig?.isModelHealthyRecord || {};

  return (
    <Stack p={3} spacing={3}>
      <Typography variant="ah2">Status</Typography>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {appConfig && (
        <Stack component={Paper} p={2} spacing={3}>
          <Typography variant="ah5">Model Health</Typography>
          <Stack spacing={0.5}>
            {Object.keys(isModelHealthyRecord)
              .sort((a, b) => a.localeCompare(b))
              .map((llmType) => {
                if (!isLlmType(llmType)) return;

                return (
                  <Stack key={llmType} direction="row" spacing={1}>
                    {isModelHealthyRecord[llmType] ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                    <Typography>{llmType}</Typography>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
