import {
  Chat,
  FirebaseFilter,
  FirebaseOrderBy,
  isChat,
} from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";

export function useChats(
  filters: FirebaseFilter<Chat>[] = [],
  firebaseOrderBy: FirebaseOrderBy<Chat> = ["createdAt", "desc"]
) {
  return usePaginatedCollection(
    query(
      getCollectionRef("chats"),
      ...filters.map((filter) => where(...filter)),
      orderBy(...firebaseOrderBy)
    ),
    isChat
  );
}
