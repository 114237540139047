import { Chip, Skeleton, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useUser } from "../hooks/useUser";

interface Props {
  userId: string;
  isAnonymous?: boolean;
}

export const UserChip: FC<Props> = (props) => {
  const { userId, isAnonymous } = props;
  const [user, isLoading] = useUser(userId);

  const email = useMemo(() => {
    if (!user?.email) return;
    if (!isAnonymous) return user.email;

    const parts = user.email.split("@");
    if (parts.length !== 2) return;

    const first = `${parts[0].slice(0, 2)}…${parts[0].slice(-1)}`;
    const second = `${parts[1].slice(0, 1)}…${parts[1].slice(-1)}`;

    return `${first}@${second}`;
  }, [isAnonymous, user?.email]);

  return (
    <Chip
      to={`/users/${userId}`}
      component={NavLink}
      sx={{ cursor: "pointer" }}
      label={
        <Stack direction="row" spacing={0.5}>
          <Stack>User:</Stack>
          {isLoading && <Skeleton width={75} />}
          {!isLoading && <Stack>{email || userId.slice(0, 8)}</Stack>}
        </Stack>
      }
    />
  );
};
