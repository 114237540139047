import { FC } from "react";
import { useIsAdminInterface } from "../../Common/hooks/useIsAdminInterface";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useOnboardingStep } from "../../Onboarding/hooks/useOnboardingStep";
import { OnboardingWaitlistPage } from "../../Onboarding/views/OnboardingWaitlistPage";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useMainInit } from "../hooks/useMainInit";
import { MainLoggedOutRoutes } from "./MainLoggedOutRoutes";
import { MainOnboardingRoutes } from "./MainOnboardingRoutes";
import { MainRoutes } from "./MainRoutes";

export const MainContentView: FC = () => {
  const { isInitializing, error } = useMainInit();
  const isLoggedIn = useMyUser((state) => !!state.user);
  const isAdmin = useIsAdminInterface();
  const isWaitlist = useOnboardingStep((state) => state.isWaitlist);
  const hasOnboardingStep = useOnboardingStep((state) => !!state.step);

  if (isInitializing) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;

  if (!isLoggedIn) {
    return <MainLoggedOutRoutes />;
  }

  if (isWaitlist && !isAdmin) {
    return <OnboardingWaitlistPage />;
  }

  if (hasOnboardingStep && !isAdmin) {
    return <MainOnboardingRoutes />;
  }

  return <MainRoutes />;
};
