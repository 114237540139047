import StorageIcon from "@mui/icons-material/Storage";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { useFaq } from "../hooks/useFaq";
import { FaqForm } from "./FaqForm";

export const FaqPage: FC = () => {
  const { faqId = "" } = useParams<"faqId">();
  const [faq, isLoading, error] = useFaq(faqId);

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="ah2">
          {faq ? faq.name : <Skeleton variant="text" width={150} />}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack>
            <Button
              component="a"
              target="_blank"
              href={getFirestoreUrl(`faqs/${faqId}`)}
              startIcon={<StorageIcon />}
            >
              Firestore
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
        {faq && <FaqForm faq={faq} />}
      </Stack>
    </Stack>
  );
};
