import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import {
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Talent, TalentConfig } from "@super-real/types";
import { DateTime } from "luxon";
import { FC, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useTalentConfigs } from "../hooks/useTalentConfigs";
import { useTalents } from "../hooks/useTalents";

interface TalentGroup {
  talent: Talent;
  talentConfig: TalentConfig;
}

const SORT_OPTIONS: {
  label: string;
  sort: (a: TalentGroup, b: TalentGroup) => number;
  getValue?: (g: TalentGroup) => number | string;
}[] = [
  {
    label: "Chats",
    sort: (a, b) => b.talentConfig.chatCount - a.talentConfig.chatCount,
    getValue: (g) => g.talentConfig.chatCount,
  },
  {
    label: "Created At",
    sort: (a, b) =>
      b.talent.createdAt.toMillis() - a.talent.createdAt.toMillis(),
    getValue: (g) =>
      DateTime.fromJSDate(g.talent.createdAt.toDate()).toFormat("d MMM"),
  },
];

export const TalentsPage: FC = () => {
  const [talents, isLoadingTalents, talentsError] = useTalents();
  const [talentConfigs, isLoadingTalentConfigs, talentConfigsError] =
    useTalentConfigs();
  const [sortOptionIndex, setSortOptionIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const isLoading = isLoadingTalents || isLoadingTalentConfigs;
  const error = talentsError || talentConfigsError;
  const isLoaded = !isLoading && !error;

  const talentGroups = useMemo(() => {
    return talents.reduce<TalentGroup[]>((result, talent) => {
      const talentConfig = talentConfigs.find((tc) => tc.id === talent.id);

      if (talentConfig) {
        result.push({ talent, talentConfig });
      }

      return result;
    }, []);
  }, [talentConfigs, talents]);

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">Talents</Typography>
          <Stack>
            <IconButton
              sx={{ mt: -1, "&.active": { color: "primary.main" } }}
              size="large"
              to="/talents/create"
              component={NavLink}
            >
              <AddIcon color="inherit" />
            </IconButton>
          </Stack>
        </Stack>
        <TextField
          value={searchQuery}
          label="Search"
          onChange={(event) => setSearchQuery(event.target.value)}
          autoComplete="off"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl>
          <InputLabel>Sort By</InputLabel>
          <Select
            label="Sort By"
            value={sortOptionIndex}
            startAdornment={
              <InputAdornment position="start">
                <SortIcon />
              </InputAdornment>
            }
            onChange={(event) =>
              setSortOptionIndex(event.target.value as number)
            }
          >
            {SORT_OPTIONS.map((option, index) => (
              <MenuItem key={option.label} value={index}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
      </Stack>
      {isLoaded && (
        <MainSecondarySidebarList>
          {talentGroups
            .filter((g) =>
              searchQuery
                ? g.talent.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                : true
            )
            .sort(SORT_OPTIONS[sortOptionIndex].sort)
            .map((talentGroup) => (
              <ListItemButton
                key={talentGroup.talent.id}
                to={`/talents/${talentGroup.talent.id}`}
                component={NavLink}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  spacing={1}
                >
                  <Stack
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    display="inline-block"
                  >
                    {[
                      talentGroup.talent.name,
                      talentGroup.talentConfig.staticAttachments.length && "📸",
                      talentGroup.talentConfig.voice && "🎤",
                    ]
                      .filter(Boolean)
                      .join(" ")}
                  </Stack>
                  {SORT_OPTIONS[sortOptionIndex].getValue && (
                    <Chip
                      size="small"
                      label={SORT_OPTIONS[sortOptionIndex].getValue?.(
                        talentGroup
                      )}
                    />
                  )}
                </Stack>
              </ListItemButton>
            ))}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
