import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import {
  App,
  AppConfig,
  ELEVEN_LABS_MODELS,
  ElevenLabsModel,
  Gender,
  GENDERS,
  SYSTEM_MESSAGE_PLACEHOLDERS,
} from "@super-real/types";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { useUploadFiles } from "../../Common/hooks/useUploadFiles";
import { AccordionView } from "../../Common/views/AccordionView";
import { FileInput } from "../../Common/views/FileInput";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { adminUpdateAppCallable } from "../callables/adminUpdateAppCallable";

const FormValues = z.object({
  chatPromptTemplate: z.string().nonempty(),
  chatPromptTemplateForFaq: z.string().nonempty(),
  talentChatPromptTemplate: z.string().nonempty(),
  referralRevenueShare: z.number().min(0).max(1),
  llmFunctionTemperature: z.number().min(0).max(1),
  functionSchemaConfig: z.object({
    generateImageAttachment: z.object({
      name: z
        .string()
        .nonempty()
        .refine(
          (name) => /^[a-zA-Z_]+$/.test(name),
          "FunctionName contains invalid characters"
        ),
      description: z.string().nonempty(),
      contextDescription: z.string().nonempty(),
      successTextDescription: z.string().nonempty(),
      failureTextDescription: z.string().nonempty(),
    }),
    generateAudioAttachment: z.object({
      name: z
        .string()
        .nonempty()
        .refine(
          (name) => /^[a-zA-Z_]+$/.test(name),
          "FunctionName contains invalid characters"
        ),
      description: z.string().nonempty(),
      successTextDescription: z.string().nonempty(),
      failureTextDescription: z.string().nonempty(),
    }),
  }),
  voices: z.array(
    z.object({
      id: z.string().nonempty(),
      name: z.string().nonempty(),
      stability: z.number(),
      similarityBoost: z.number(),
      style: z.number(),
      model: ElevenLabsModel,
      sampleFilePath: z.string().nonempty(),
      gender: Gender,
    })
  ),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  app: App;
  appConfig: AppConfig;
}

export const AppConfigForm: FC<Props> = (props) => {
  const { app, appConfig } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      chatPromptTemplate: appConfig.chatPromptTemplate,
      chatPromptTemplateForFaq: appConfig.chatPromptTemplateForFaq,
      talentChatPromptTemplate: appConfig.talentChatPromptTemplate,
      referralRevenueShare: appConfig.referralRevenueShare,
      llmFunctionTemperature: appConfig.llmFunctionTemperature,
      functionSchemaConfig: {
        generateImageAttachment: {
          ...appConfig.functionSchemaConfig.generateImageAttachment,
        },
        generateAudioAttachment: {
          ...appConfig.functionSchemaConfig.generateAudioAttachment,
        },
      },
      voices: app.voices || [],
    },
  });

  const voices = useFieldArray({
    control,
    name: "voices",
  });

  const [uploadFiles, isUploadingFiles] = useUploadFiles({
    ownerId: "public",
    ownerType: "PUBLIC",
    onFileUploaded: (uploadedFile) => {
      voices.append({
        id: "",
        name: "",
        stability: 0.5,
        similarityBoost: 0.7,
        style: 0,
        model: "eleven_monolingual_v1",
        sampleFilePath: uploadedFile.filePath,
        gender: "FEMALE",
      });
    },
  });

  const onSubmit = useCallback(async (formValues: FormValues) => {
    try {
      await adminUpdateAppCallable({
        chatPromptTemplate: formValues.chatPromptTemplate,
        chatPromptTemplateForFaq: formValues.chatPromptTemplateForFaq,
        talentChatPromptTemplate: formValues.talentChatPromptTemplate,
        referralRevenueShare: formValues.referralRevenueShare,
        llmFunctionTemperature: formValues.llmFunctionTemperature,
        functionSchemaConfig: formValues.functionSchemaConfig,
        voices: formValues.voices,
      });
      toast.success("Saved!");
    } catch (error) {
      handleError(error);
    }
  }, []);

  const isLoading = formState.isSubmitting;

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <AccordionView title="Prompt Templates">
        <Stack spacing={2}>
          <ControlledTextField
            control={control}
            label="Default"
            name="chatPromptTemplate"
            autoComplete="off"
            multiline
          />
          <ControlledTextField
            control={control}
            label="FAQ"
            name="chatPromptTemplateForFaq"
            autoComplete="off"
            multiline
          />
          <ControlledTextField
            control={control}
            label="Talent (talent_chat_prompt)"
            name="talentChatPromptTemplate"
            autoComplete="off"
            multiline
          />
          <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={1}>
            {SYSTEM_MESSAGE_PLACEHOLDERS.map((placeholder) => (
              <Chip key={placeholder} label={`{${placeholder}}`} size="small" />
            ))}
          </Stack>
        </Stack>
      </AccordionView>

      <AccordionView title="Referrals">
        <Stack spacing={2}>
          <ControlledTextField
            control={control}
            label="Revenue Share for Referrals"
            name="referralRevenueShare"
            inputProps={{ min: 0, max: 1, step: 0.001 }}
            type="number"
            autoComplete="off"
          />
        </Stack>
      </AccordionView>

      <AccordionView title="Functions">
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography variant="ah6">General</Typography>
            <ControlledTextField
              control={control}
              type="number"
              inputProps={{ min: 0, max: 1, step: 0.001 }}
              label="Temperature for functions calls"
              name="llmFunctionTemperature"
              autoComplete="off"
            />
          </Stack>
          <Typography variant="ah6">Images</Typography>
          <ControlledTextField
            control={control}
            label="Function Name"
            name="functionSchemaConfig.generateImageAttachment.name"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Description"
            name="functionSchemaConfig.generateImageAttachment.description"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Context Description"
            name="functionSchemaConfig.generateImageAttachment.contextDescription"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Success Text Description"
            name="functionSchemaConfig.generateImageAttachment.successTextDescription"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Failure Text Description"
            name="functionSchemaConfig.generateImageAttachment.failureTextDescription"
            autoComplete="off"
          />
          <Typography variant="ah6">Audios</Typography>
          <ControlledTextField
            control={control}
            label="Function Name"
            name="functionSchemaConfig.generateAudioAttachment.name"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Description"
            name="functionSchemaConfig.generateAudioAttachment.description"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Success Text Description"
            name="functionSchemaConfig.generateAudioAttachment.successTextDescription"
            autoComplete="off"
          />
          <ControlledTextField
            control={control}
            label="Failure Text Description"
            name="functionSchemaConfig.generateAudioAttachment.failureTextDescription"
            autoComplete="off"
          />
        </Stack>
      </AccordionView>

      <AccordionView title="Voices">
        <Stack spacing={2}>
          {voices.fields.map((field, index) => (
            <Fragment key={field.id}>
              <Stack direction="row" spacing={2}>
                <ControlledTextField
                  control={control}
                  label="Elevenlabs Voice Id"
                  name={`voices.${index}.id`}
                  autoComplete="off"
                  fullWidth
                />
                <ControlledSelect
                  control={control}
                  label="Model"
                  name={`voices.${index}.model`}
                  items={ELEVEN_LABS_MODELS.map((model) => ({
                    label: model,
                    value: model,
                  }))}
                  renderItem={(item) => item.value}
                  disabled={isLoading}
                  fullWidth
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <ControlledTextField
                  control={control}
                  label="Voice Name"
                  name={`voices.${index}.name`}
                  autoComplete="off"
                  fullWidth
                />
                <ControlledSelect
                  control={control}
                  label="Gender"
                  name={`voices.${index}.gender`}
                  items={GENDERS.map((gender) => ({
                    label: gender,
                    value: gender,
                  }))}
                  renderItem={(item) => item.label}
                  disabled={isLoading}
                  fullWidth
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <ControlledTextField
                  control={control}
                  label="Stability"
                  name={`voices.${index}.stability`}
                  autoComplete="off"
                  fullWidth
                />
                <ControlledTextField
                  control={control}
                  label="Similiarity Boost"
                  name={`voices.${index}.similarityBoost`}
                  autoComplete="off"
                  fullWidth
                />
                <ControlledTextField
                  control={control}
                  label="Stability"
                  name={`voices.${index}.style`}
                  autoComplete="off"
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon fontSize="inherit" />}
                  onClick={() => voices.remove(index)}
                  fullWidth
                >
                  Delete Voice
                </Button>
              </Stack>
              {index !== voices.fields.length - 1 && <Divider />}
            </Fragment>
          ))}
          <Stack>
            <FileInput
              disabled={isUploadingFiles}
              onSelect={uploadFiles}
              accept="audio/mp3"
              multiple
            >
              <Button variant="text" disabled={isUploadingFiles}>
                {isUploadingFiles && "Uploading... "}
                {!isUploadingFiles && "Add Voice"}
              </Button>
            </FileInput>
          </Stack>
        </Stack>
      </AccordionView>

      <Stack direction="row" alignItems="self-start" mt={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
