import GavelIcon from "@mui/icons-material/Gavel";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SupportIcon from "@mui/icons-material/Support";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { handleError } from "../../Common/helpers/handleError";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useMyUser } from "../../User/hooks/useMyUser";
import { MainSupportDialog } from "./MainSupportDialog";

export const MainMenuButton: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAdmin = useMyUser((state) => state.user?.role === "ADMIN");
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setAnchorEl(null);
    } catch (error) {
      handleError(error);
    }
  }, []);

  return (
    <>
      <IconButton
        size="large"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isAdmin && (
          <MenuItem
            onClick={() => {
              useLocalStorage.getState().setData({
                isAdmin: true,
              });
              navigate("/");
            }}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText>Admin UI</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setIsSupportDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SupportIcon />
          </ListItemIcon>
          <ListItemText>Support</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          onClick={() => setAnchorEl(null)}
          href={TERMS_AND_CONDITIONS_URL}
        >
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText>Terms & Conditions</ListItemText>
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText>
            <Typography color="error">Logout</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      <MainSupportDialog
        isOpen={isSupportDialogOpen}
        close={() => setIsSupportDialogOpen(false)}
      />
    </>
  );
};
