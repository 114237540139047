import { isAppConfig } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useAppConfig(appConfigId: string) {
  return useValidDocumentData(
    getDocRef("appConfigs", appConfigId),
    isAppConfig
  );
}
