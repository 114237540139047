import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { MainMenuButton } from "../../Main/views/MainMenuButton";
import { MainPage } from "../../Main/views/MainPage";
import { CreatorBackButton } from "./CreatorBackButton";

export const CreatorLayout: FC = () => {
  return (
    <MainPage>
      <Stack
        p={2}
        top={0}
        position="sticky"
        direction="row"
        bgcolor="common.white"
        borderBottom={1}
        borderColor="divider"
        zIndex={10}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <CreatorBackButton />
          <Typography
            variant="ah3"
            to="/"
            component={NavLink}
            sx={{
              textDecoration: "none",
              "&:focus, &:hover, &:visited, &:link, &:active": {
                color: "inherit",
              },
            }}
          >
            <Typography
              component="span"
              variant="ah3"
              color="background.gradient"
            >
              Creator
            </Typography>{" "}
            Dashboard
          </Typography>
        </Stack>
        <MainMenuButton />
      </Stack>
      <Outlet />
    </MainPage>
  );
};
