import { CircularProgress, Dialog, DialogContent, Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { processEmailLink } from "../helpers/processEmailLink";

export const FirebaseAuthEmailLinkDialog: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const startEffect = async () => {
      try {
        await processEmailLink({
          pathname,
          search,
          onStart: () => setIsOpen(true),
          onCompletion: () => {
            setIsOpen(false);
            navigate(pathname, { replace: true });
          },
        });
      } catch (error) {
        handleError(error);
      }
    };

    void startEffect();
  }, [pathname, search, navigate]);

  if (!isOpen) return null;

  return (
    <Dialog open>
      <DialogContent>
        <Stack alignItems="center" p={4}>
          <CircularProgress />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
