import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import FolderIcon from "@mui/icons-material/Folder";
import StorageIcon from "@mui/icons-material/Storage";
import { Button, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppConfig } from "../../AppConfig/hooks/useAppConfig";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { useFaqs } from "../../Faq/hooks/useFaqs";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { getStorageUrl } from "../../Firebase/helpers/getStorageUrl";
import { useModels } from "../../Model/hooks/useModels";
import { useProducts } from "../../Product/hooks/useProducts";
import { useTalent } from "../../Talent/hooks/useTalent";
import { useTalentConfig } from "../../Talent/hooks/useTalentConfig";
import { TalentAnalyticsView } from "./TalentAnalyticsView";
import { TalentFaqView } from "./TalentFaqView";
import { TalentForm } from "./TalentForm";
import { TalentManagersView } from "./TalentManagersView";
import { TalentModelView } from "./TalentModelView";
import { TalentPayoutsView } from "./TalentPayoutsView";

type Tab =
  | "SETTINGS"
  | "MODEL"
  | "FAQ"
  | "MANAGERS"
  | "PAYOUTS"
  | "BROADCAST"
  | "ANALYTICS";

export const TalentPage: FC = () => {
  const { talentId } = useParams<"talentId">();
  const [talent, isLoadingTalent, talentError] = useTalent(talentId);
  const [models, isLoadingModels, modelsError] = useModels([talentId, null]);
  const [faqs, isLoadingFaqs, faqsError] = useFaqs([talentId, null]);
  const [appConfig, isLoadingAppConfig, appConfigError] =
    useAppConfig("default");
  const [talentConfig, isLoadingTalentConfig, talentConfigError] =
    useTalentConfig(talentId);
  const [tab, setTab] = useState<Tab>("SETTINGS");
  const [products, isLoadingProducts, productsError] = useProducts();

  const isLoading =
    isLoadingTalent ||
    isLoadingTalentConfig ||
    isLoadingModels ||
    isLoadingFaqs ||
    isLoadingAppConfig ||
    isLoadingProducts;

  const error =
    talentError ||
    talentConfigError ||
    modelsError ||
    faqsError ||
    appConfigError ||
    productsError;

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="ah2">
          {talent ? talent.name : <Skeleton variant="text" width={150} />}
        </Typography>
        {talent && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack>
              <Button
                component="a"
                target="_blank"
                href={`${ENVIRONMENT.messengerDomain}/${talent.tag}`}
                startIcon={<ChatBubbleIcon />}
              >
                Chat
              </Button>
            </Stack>
            <Stack>
              <Button
                component="a"
                target="_blank"
                href={getStorageUrl(`talents/${talent?.id}`)}
                startIcon={<FolderIcon />}
              >
                Storage
              </Button>
            </Stack>
            <Stack>
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(`talentConfigs/${talent?.id}`)}
                startIcon={<StorageIcon />}
              >
                Firestore
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack spacing={2} flex={1}>
        <Tabs
          value={tab}
          onChange={(_, tab: Tab) => setTab(tab)}
          aria-label="basic tabs example"
        >
          <Tab value="SETTINGS" label="Settings" />
          <Tab value="MODEL" label="Model" />
          <Tab value="FAQ" label="Faq" />
          <Tab value="MANAGERS" label="Managers" />
          <Tab value="PAYOUTS" label="Payouts" />
          <Tab value="BROADCAST" label="Broadcast" />
          <Tab value="ANALYTICS" label="Analytics" />
        </Tabs>
        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
        {talent && talentConfig && !!models.length && appConfig && (
          <>
            {tab === "SETTINGS" && (
              <TalentForm
                talent={talent}
                talentConfig={talentConfig}
                models={models}
                faqs={faqs}
                appConfig={appConfig}
                products={products}
              />
            )}
            {tab === "MODEL" && <TalentModelView talent={talent} />}
            {tab === "FAQ" && <TalentFaqView talent={talent} />}
            {tab === "MANAGERS" && <TalentManagersView talent={talent} />}
            {tab === "PAYOUTS" && <TalentPayoutsView talent={talent} />}
            {tab === "ANALYTICS" && (
              <TalentAnalyticsView talentConfig={talentConfig} />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
