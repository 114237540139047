import { Currency } from "@super-real/types";

interface Params {
  amount: number;
  currency?: Currency;
  fractionDigits?: number;
}

export function toLocaleMoney(params: Params) {
  return params.amount.toLocaleString(undefined, {
    minimumFractionDigits: params.fractionDigits,
    maximumFractionDigits: params.fractionDigits,
    style: "currency",
    currency: params.currency || "USD",
  });
}
