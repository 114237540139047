import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { CreatorSettingsProfileForm } from "./CreatorSettingsMonetizationForm";

export const CreatorSettingsMonetizationPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);

  return (
    <Stack px={2} py={3} spacing={2}>
      <Typography variant="ah1" color="text.secondary">
        💵 Monetisation
      </Typography>
      {talent && <CreatorSettingsProfileForm talent={talent} />}
    </Stack>
  );
};
