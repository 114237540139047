import { File as FileFromTypes, UploadFileResponse } from "@super-real/types";
import { useCallback, useState } from "react";
import { uploadFileCallable } from "../callables/uploadFileCallable";
import { ENVIRONMENT } from "../consts/ENVIRONMENT";

interface Params {
  ownerId: string;
  ownerType: FileFromTypes["ownerType"];
  onFileUploadStarted?: () => void;
  onFileUploaded?: (
    uploadedFile: UploadFileResponse,
    originalFile: File
  ) => void;
}

export const useUploadFiles = (params: Params) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  const uploadSingleFile = useCallback(
    async (file: File) => {
      const arrayBuffer = await file.arrayBuffer();
      const base64String = Buffer.from(arrayBuffer).toString("base64");

      // simulate slower image upload in development
      if (ENVIRONMENT.nodeEnv === "development") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      const response = await uploadFileCallable({
        ownerId: params.ownerId,
        ownerType: params.ownerType,
        file: {
          name: file.name,
          type: file.type,
          size: file.size,
          content: base64String,
        },
      });

      return response;
    },
    [params.ownerId, params.ownerType]
  );

  const upload = useCallback(
    async (files: File[]) => {
      if (!files.length) return;
      setIsUploading(true);

      const results = await Promise.allSettled(
        files.map((file) => {
          params.onFileUploadStarted?.();
          return uploadSingleFile(file).then((uploadedFile) => {
            params.onFileUploaded?.(uploadedFile, file);
          });
        })
      );

      setIsUploading(false);

      const errors = results.reduce<Error[]>((acc, result) => {
        if (result.status === "fulfilled") return acc;
        if (!result.reason) return acc;

        if (result.reason instanceof Error) {
          acc.push(result.reason);
        } else {
          acc.push(new Error(result.reason as string));
        }

        return acc;
      }, []);

      if (!errors.length) return;
      setErrors(errors);
    },
    [uploadSingleFile, params]
  );

  return [upload, isUploading, errors] as const;
};
