import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { TalentConfig } from "@super-real/types";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useDisbursableAmount } from "../hooks/useDisbursableAmount";
import { useEarningFrameTotal } from "../hooks/useEarningFrameTotal";
import { CreatorDashboardHeadlineView } from "./CreatorDashboardHeadlineView";

interface Props {
  talentConfig: TalentConfig;
}

export const CreatorDashboardInsightsView: FC<Props> = (props) => {
  const { talentConfig } = props;
  const [earningFrameTotal, isLoadingTotal] = useEarningFrameTotal(
    talentConfig.id
  );
  const [disbursableAmount, isLoadingDisbursable] = useDisbursableAmount();

  return (
    <>
      <CreatorDashboardHeadlineView title="💵 Earnings">
        <Typography variant="ah5" color="text.secondary">
          All Time
        </Typography>
      </CreatorDashboardHeadlineView>
      <Stack px={2} py={3} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack>
            <Typography variant="ah5">You’ve earned</Typography>
            <Typography
              variant="ah2"
              color="background.gradient"
              alignSelf="flex-start"
            >
              {isLoadingTotal && <Skeleton width={60} />}
              {!isLoadingTotal &&
                toLocaleMoney({
                  amount: earningFrameTotal?.totalNetAmount || 0,
                })}
            </Typography>
          </Stack>
          <Stack textAlign="right">
            <Typography variant="ah6">Ready for Payout</Typography>
            <Typography variant="ah3" alignSelf="flex-end">
              {isLoadingDisbursable && <Skeleton width={60} />}
              {!isLoadingDisbursable &&
                toLocaleMoney({
                  amount: disbursableAmount || 0,
                })}
            </Typography>
          </Stack>
        </Stack>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          endIcon={<ArrowForwardIcon />}
          to="/earnings"
          component={NavLink}
        >
          Balance details
        </Button>
      </Stack>
    </>
  );
};
