import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { Faq, Talent } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { updateFaqCallable } from "../callables/updateFaqCallable";
import { toFaqItems } from "../helpers/toFaqItems";
import { toFaqItemsString } from "../helpers/toFaqItemsString";

const FormValues = z.object({
  name: z.string().nonempty(),
  items: z.string().superRefine((value, ctx) => {
    try {
      toFaqItems(value);
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: error instanceof Error ? error.message : "Invalid",
      });
    }
  }),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  faq: Faq;
  talent?: Talent;
}

export const FaqForm: FC<Props> = (props) => {
  const { faq, talent } = props;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talent?.name || faq.name,
      items: toFaqItemsString(faq.items),
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateFaqCallable({
          id: faq.id,
          name: formValues.name,
          items: toFaqItems(formValues.items),
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [faq]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label="Name"
          name="name"
          autoComplete="off"
          disabled={!!talent || isLoading}
        />
      </Stack>
      <Typography variant="ah3">Data</Typography>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label="Data"
          name="items"
          autoComplete="off"
          minRows={5}
          maxRows={20}
          multiline
          disabled={isLoading}
        />
        <Typography variant="caption">
          Every line has to start with {`"Q: "`} or {`"A: "`}.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
