import { Link, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { DEFAULT_APP_CONFIG } from "../../AppConfig/consts/DEFAULT_APP_CONFIG";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { toLocalePercent } from "../../Common/helpers/toLocalePercent";
import { BodyGradientView } from "../../Common/views/BodyGradientView";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";
import { useTalentConfig } from "../../Talent/hooks/useTalentConfig";
import { ShareLinkField } from "./ShareLinkField";

export const CreatorReferralPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);
  const [talentConfig] = useTalentConfig(talent.id);
  const url = `${ENVIRONMENT.domain}/r/${talent?.tag}`;

  if (!talent) return null;

  return (
    <Stack flex={1} px={2} py={3} spacing={2}>
      <BodyGradientView />
      <Typography variant="ah1">💸</Typography>
      <Stack>
        <Typography variant="ah5" color="text.secondary">
          Invite and Earn
        </Typography>
        <Typography variant="ah2">
          INVITE ANOTHER INFLUENCER,
          <br />
          Get{" "}
          <Typography
            variant="inherit"
            component="span"
            color="background.gradient"
          >
            {toLocalePercent(
              talentConfig?.referralRevenueShareForInvitees ||
                DEFAULT_APP_CONFIG.referralRevenueShare
            )}{" "}
            of their earnings
          </Typography>
        </Typography>
      </Stack>

      <Stack pt={4} spacing={2}>
        <ShareLinkField label="" url={url} />
      </Stack>

      <Typography variant="h5" textAlign="center">
        By sharing the link, you agree to the{" "}
        <Link
          href={TERMS_AND_CONDITIONS_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </Link>{" "}
        of the referral program.
      </Typography>
    </Stack>
  );
};
