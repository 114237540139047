import { Box, Skeleton, Stack } from "@mui/material";
import { Attachment } from "@super-real/types";
import { FC } from "react";
import { useStorageFile } from "../../Common/hooks/useStorageFile";

interface Props {
  attachment: Attachment & { type: "IMAGE" };
}

export const ChatAttachmentImageView: FC<Props> = (props) => {
  const { attachment } = props;
  const { url } = useStorageFile(attachment.filePath);

  const aspectRatio = attachment.dimensions
    ? attachment.dimensions.width / attachment.dimensions.height
    : undefined;

  return (
    <Stack overflow="hidden" borderRadius={2}>
      {!url && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="auto"
          sx={{ aspectRatio }}
        />
      )}
      {url && (
        <Box
          component="img"
          src={url}
          alt="Unknown"
          width="100%"
          height="auto"
          sx={{
            aspectRatio,
            filter: attachment.isUnlocked ? "none" : "blur(35px)",
          }}
        />
      )}
    </Stack>
  );
};
