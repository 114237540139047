import { PayoutTransferType } from "@super-real/types";

interface RecordValue {
  label: string;
}

export const PAYOUT_TRANSFER_TYPE_RECORD: Record<
  PayoutTransferType,
  RecordValue
> = {
  BANK_TRANSFER: {
    label: "Bank Transfer",
  },
  PAYPAL: {
    label: "PayPal",
  },
};
