import { FirebaseFilter, isTalent, Talent } from "@super-real/types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useTalents(filters?: FirebaseFilter<Talent>[]) {
  return useValidCollectionData(
    query(
      getCollectionRef("talents"),
      ...(filters ? filters.map((filter) => where(...filter)) : [])
    ),
    isTalent
  );
}
