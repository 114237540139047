import {
  EarningFrame,
  FirebaseFilter,
  isEarningFrame,
} from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useEarningFrames(
  talentId?: string,
  filters?: FirebaseFilter<EarningFrame>[]
) {
  return useValidCollectionData(
    talentId
      ? query(
          getCollectionRef(`talentConfigs/${talentId}/earningFrames`),
          ...(filters ? filters.map((filter) => where(...filter)) : []),
          orderBy("startedAt", "desc")
        )
      : null,
    isEarningFrame
  );
}
