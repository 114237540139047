import { zodResolver } from "@hookform/resolvers/zod";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Button, InputAdornment, Link, Stack, Typography } from "@mui/material";
import { SOCIAL_PLATFORMS, SocialPlatform } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { TERMS_AND_CONDITIONS_URL } from "../../Common/consts/TERMS_AND_CONDITIONS_URL";
import { handleError } from "../../Common/helpers/handleError";
import { showConfetti } from "../../Common/helpers/showConfetti";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { MainBackgroundGradientView } from "../../Main/views/MainBackgroundGradientView";
import { MainMenuButton } from "../../Main/views/MainMenuButton";
import { MainPage } from "../../Main/views/MainPage";
import { useMyUser } from "../../User/hooks/useMyUser";
import { addUserToWaitlistCallable } from "../callables/addUserToWaitlistCallable";
import { ONBOARDING_WAITLIST_RECORD } from "../consts/ONBOARDING_WAITLIST_RECORD";

const FormValues = z.object({
  platform: SocialPlatform,
  handle: z.string().nonempty(),
  hasAgreedToTermsOfService: z.literal<boolean>(true, {
    errorMap: () => ({
      message: "Please review and accept the Terms of Service to proceed.",
    }),
  }),
});

export type FormValues = z.infer<typeof FormValues>;

export const OnboardingWaitlistPage: FC = () => {
  const isOnWaitlist = useMyUser(
    (state) =>
      state.user?.waitlistStatus === "PENDING" ||
      state.user?.waitlistStatus === "REJECTED"
  );

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      platform: "INSTAGRAM",
      handle: "",
      hasAgreedToTermsOfService: false,
    },
  });

  const onSubmit = useCallback(async (formValues: FormValues) => {
    try {
      await addUserToWaitlistCallable({
        platform: formValues.platform,
        handle: formValues.handle,
      });
      toast.success("Added to waitlist");
      showConfetti();
    } catch (error) {
      handleError(error);
    }
  }, []);

  const isLoading = formState.isSubmitting;

  return (
    <MainPage>
      <Stack
        p={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <MainMenuButton />
      </Stack>

      {!isOnWaitlist && (
        <>
          <Stack p={2} flex={1} spacing={4}>
            <Stack spacing={2}>
              <Typography variant="ah5" color="text.secondary">
                Thank you for your interest
              </Typography>
              <Typography variant="ah2" color="text.primary">
                Join the waiting list for super real
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="ah3">⭐️</Typography>
              <Typography variant="h3" fontWeight={700}>
                We are currently accepting selected creators to our community.
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="ah3">⚡️</Typography>
              <Typography variant="h3" fontWeight={700}>
                Please share your main social media handle so we can evaluate
                your application.
              </Typography>
            </Stack>
          </Stack>

          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            spacing={1}
            p={2}
          >
            <Typography variant="caption">Your social media handle</Typography>
            <Stack direction="row" spacing={1} pb={1}>
              <ControlledSelect
                control={control}
                label=""
                name="platform"
                items={SOCIAL_PLATFORMS.map((platform) => ({
                  label: ONBOARDING_WAITLIST_RECORD[platform].label,
                  value: platform,
                }))}
                renderItem={(item) => item.label}
                formControlSx={{ maxWidth: 140 }}
                disabled={isLoading}
                fullWidth
              />
              <ControlledTextField
                control={control}
                label=""
                name="handle"
                placeholder="handle"
                autoComplete="off"
                disabled={isLoading}
                fullWidth
                startAdornment={
                  <InputAdornment position="start">@</InputAdornment>
                }
              />
            </Stack>
            <ControlledSwitch
              control={control}
              name="hasAgreedToTermsOfService"
              label={
                <Typography variant="caption">
                  Agree to the{" "}
                  <Link
                    href={TERMS_AND_CONDITIONS_URL}
                    target="_blank"
                    color="inherit"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    href={TERMS_AND_CONDITIONS_URL}
                    target="_blank"
                    color="inherit"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
            <Button
              variant="contained"
              size="large"
              type="submit"
              disabled={isLoading}
            >
              Join Waitlist
            </Button>
          </Stack>
        </>
      )}

      {isOnWaitlist && (
        <>
          <Stack p={2} flex={1} spacing={4}>
            <MainBackgroundGradientView />
            <Stack spacing={2} alignItems="center">
              <Typography variant="ah1" color="text.secondary">
                🤩
              </Typography>
              <Typography
                variant="ah5"
                color="text.secondary"
                textAlign="center"
              >
                Thank you for your interest
              </Typography>
              <Typography variant="ah2" color="text.primary" textAlign="center">
                You are on the waitlist
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                textAlign="center"
              >
                We will review your application and will be in touch with an
                update soon.
              </Typography>
            </Stack>
          </Stack>
          <Stack p={4} spacing={1}>
            <Typography variant="h5" color="text.secondary" textAlign="center">
              Follow us for product updates
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Link
                href="https://www.instagram.com/superrealai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  fontSize="large"
                  sx={{ color: "text.primary" }}
                />
              </Link>

              <Link
                href="https://x.com/superrealaico/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  width={35}
                  height={35}
                  fontSize={35}
                  lineHeight={0.8}
                  fontWeight={700}
                  color="text.primary"
                >
                  𝕏
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </>
      )}
    </MainPage>
  );
};
