import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { showConfetti } from "../../Common/helpers/showConfetti";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { publishTalentDraftCallable } from "../../Talent/callables/publishTalentDraftCallable";
import { updateTalentDraftCallable } from "../../Talent/callables/updateTalentDraftCallable";
import { useMyTalentDraft } from "../../Talent/hooks/useMyTalentDraft";
import { getOnboardingStepPath } from "../helpers/getOnboardingStepPath";

const FormValues = z.object({
  initialChatMessageText: z.string().min(1),
});

type FormValues = z.infer<typeof FormValues>;

export const OnboardingTalentInitialChatMessagePage: FC = () => {
  const talentDraft = useMyTalentDraft();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      initialChatMessageText: talentDraft?.initialChatMessageText || "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!talentDraft?.id) {
          throw new Error("Talent ID is not defined");
        }

        await updateTalentDraftCallable({
          id: talentDraft.id,
          initialChatMessageText: formValues.initialChatMessageText,
        });

        await publishTalentDraftCallable({
          id: talentDraft.id,
        });

        showConfetti();
        logEvent("completed_talent_onboarding");

        navigate(getOnboardingStepPath("TALENT_INITIAL_CHAT_MESSAGE", "NEXT"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, talentDraft]
  );

  const isDisabled = formState.isSubmitting;

  return (
    <Stack
      mt={2}
      p={2}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <Typography variant="ah5" color="text.secondary" textAlign="center">
          That’s everything
        </Typography>
        <Typography variant="ah2" color="text.primary" textAlign="center">
          Your greeting message
        </Typography>
        <Typography textAlign="center">
          Say ‘hello’ to your fans in your very own words
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={2}>
        <ControlledTextField
          name="initialChatMessageText"
          control={control}
          disabled={isDisabled}
          label="Your first message"
          placeholder="Yoooo, what's up baddie?"
          inputLabelProps={{ shrink: true }}
          autoComplete="off"
          fullWidth
        />
        <Stack spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isDisabled}
            size="large"
            fullWidth
          >
            Finish setup
          </Button>
          <Button
            disabled={isDisabled}
            size="large"
            startIcon={<ArrowBackIcon />}
            to={getOnboardingStepPath(
              "TALENT_INITIAL_CHAT_MESSAGE",
              "PREVIOUS"
            )}
            component={NavLink}
            fullWidth
          >
            Previous
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
