import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useMyTalents } from "../../Talent/hooks/useMyTalents";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const MainSupportDialog: FC<Props> = (props) => {
  const { isOpen, close } = props;
  const [talent] = useMyTalents((state) => state.talents);

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>Support</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Do you’ve a question about our app? You can reach our support team via{" "}
          <Link
            href="https://discord.gg/2vyYvvu3fM"
            target="_blank"
            rel="noreferrer noopener"
          >
            Discord
          </Link>{" "}
          or{" "}
          <Link
            href={`mailto:support@super-real.co?subject=${encodeURIComponent(
              `Talent: ${talent?.id || "Unknown"}`
            )}`}
          >
            Email
          </Link>
          . We look forward to hearing from you & aim to get back to you within
          24 hours!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};
