import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Dialog,
  Drawer,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { StaticAttachment } from "@super-real/types/types/StaticAttachment";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { StorageImage } from "../../../Common/views/StorageImage";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { MainContainer } from "../../../Main/views/MainContainer";
import { useMyTalentConfigs } from "../../../Talent/hooks/useMyTalentConfigs";
import { updateTalentConfigRemoveStaticAttachmentCallable } from "../../callables/updateTalentConfigRemoveStaticAttachmentCallable";
import { updateTalentConfigUpdateStaticAttachmentCallable } from "../../callables/updateTalentConfigUpdateStaticAttachmentCallable";

const FormValues = z.object({
  context: z
    .string()
    .min(3, "Context must be at least 3 characters long")
    .max(40, "Context must be less than 40 characters"),
});

export type FormValues = z.infer<typeof FormValues>;

export const StaticImageAttachmentDialog: FC = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialzedDeletion, setInitializedDeletion] = useState(false);

  const attachmentId = useMemo(() => {
    return new URLSearchParams(search).get("attachmentId");
  }, [search]);

  const [prevAttachment, attachment, nextAttachment] = useMemo(() => {
    if (!attachmentId || !talentConfig?.staticAttachments) return [];
    const imageAttachments = talentConfig.staticAttachments.filter(
      ({ type }) => type === "IMAGE"
    ) as (StaticAttachment & { type: "IMAGE" })[];

    const attachmentIndex = imageAttachments.findIndex(
      ({ id }) => id === attachmentId
    );

    return [
      imageAttachments[attachmentIndex - 1],
      imageAttachments[attachmentIndex],
      imageAttachments[attachmentIndex + 1],
    ] as const;
  }, [attachmentId, talentConfig]);

  const { control, handleSubmit, setValue, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { context: attachment?.context || "" },
  });

  const onClickBack = useCallback(() => navigate(-1), [navigate]);

  const onClickPrevNext = useCallback(
    (id: string) =>
      navigate(`${pathname}?attachmentId=${id}`, { replace: true }),
    [navigate, pathname]
  );

  const onClickDelete = useCallback(async () => {
    if (!talentConfig?.id) return;
    if (!attachmentId) return;
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);

      await updateTalentConfigRemoveStaticAttachmentCallable({
        id: talentConfig.id,
        staticAttachmentId: attachmentId,
      });

      navigate(pathname, { replace: true });
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
      setInitializedDeletion(false);
    }
  }, [talentConfig?.id, attachmentId, isSubmitting, pathname, navigate]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!talentConfig?.id) return;
      if (!attachment) return;
      if (isSubmitting) return;

      try {
        setIsSubmitting(true);

        await updateTalentConfigUpdateStaticAttachmentCallable({
          id: talentConfig.id,
          staticAttachment: {
            ...attachment,
            context: formValues.context,
          },
        });

        toast.success("Updated Context");
      } catch (error) {
        handleError(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [talentConfig.id, attachment, isSubmitting]
  );

  useEffect(() => {
    if (!attachment) return;
    setValue("context", attachment.context);
  }, [attachment, setValue]);

  if (!attachment) return null;

  return (
    <Dialog open fullScreen>
      <MainContainer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={1.5}
        >
          <Button
            size="medium"
            color="inherit"
            onClick={onClickBack}
            startIcon={<ArrowBackIcon sx={{ width: 24, height: 24 }} />}
          >
            <Typography variant="ah3">Back</Typography>
          </Button>

          <IconButton
            color="error"
            onClick={() => setInitializedDeletion(true)}
          >
            <DeleteForeverOutlinedIcon fontSize="large" />
          </IconButton>
        </Stack>

        <Stack position="relative">
          <StorageImage
            filePath={attachment.filePath}
            dimensions={attachment.dimensions}
          />
          {prevAttachment?.id && (
            <SIconButton
              sx={{ left: 0 }}
              onClick={() => onClickPrevNext(prevAttachment.id)}
            >
              <NavigateBeforeIcon />
            </SIconButton>
          )}
          {nextAttachment?.id && (
            <SIconButton
              sx={{ right: 0 }}
              onClick={() => onClickPrevNext(nextAttachment.id)}
            >
              <NavigateNextIcon />
            </SIconButton>
          )}
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            px={{ xs: 2, sm: 0 }}
            py={2}
            spacing={2}
            alignItems="flex-start"
          >
            <ControlledTextField
              control={control}
              name="context"
              label="Image Context"
              placeholder="Context"
              helperText={
                formState.errors.context?.message ||
                "Please provide a description of the content within this image."
              }
              autoComplete="off"
              inputLabelProps={{ shrink: true }}
              disabled={!talentConfig}
              fullWidth
            />

            <Button
              type="submit"
              variant="contained"
              size="large"
              startIcon={<SaveIcon />}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Stack>
        </form>

        <Drawer
          open={initialzedDeletion}
          anchor="bottom"
          PaperProps={{ elevation: 0 }}
          sx={{ zIndex: 1400 }}
          onClose={() => setInitializedDeletion(false)}
        >
          <Stack p={2} spacing={2} sx={{ color: "common.black" }}>
            <Button
              variant="contained"
              color="error"
              size="large"
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={onClickDelete}
              disabled={isSubmitting}
            >
              Delete Image
            </Button>
            <Button
              variant="contained"
              color="inherit"
              size="large"
              onClick={() => setInitializedDeletion(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Drawer>
      </MainContainer>
    </Dialog>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  margin: 8,
  transform: "translateY(-50%)",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[900],
  "@media(hover: hover)": {
    "&:hover": {
      backgroundColor: theme.palette.grey[900],
    },
  },
}));
