import { ONBOARDING_STEP_RECORD } from "../consts/ONBOARDING_STEP_RECORD";
import { ONBOARDING_STEPS, OnboardingStep } from "../types/OnboardingStep";

type Direction = "PREVIOUS" | "NEXT";

export function getOnboardingStepPath(
  onboardingStep: OnboardingStep,
  direction: Direction
) {
  const index = ONBOARDING_STEPS.findIndex((s) => s === onboardingStep);

  if (index === -1) return "/";

  const nextIndex = direction === "NEXT" ? index + 1 : index - 1;
  const nextOnboardingStep = ONBOARDING_STEPS[nextIndex];

  if (!nextOnboardingStep) return "/";

  return `/${ONBOARDING_STEP_RECORD[nextOnboardingStep].path}`;
}
