import { Button, ListItemButton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { usePayouts } from "../hooks/usePayouts";
import { PayoutStatusChip } from "./PayoutStatusChip";

export const PayoutsPage: FC = () => {
  const [payouts, , error, controller] = usePayouts();

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack>
          <Typography variant="ah2">Payouts</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <MainSecondarySidebarList>
        {payouts
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((payout) => (
            <ListItemButton
              key={payout.id}
              to={`/payouts/${payout.id}`}
              component={NavLink}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                spacing={1}
              >
                <Stack>{toLocaleMoney(payout.value)}</Stack>
                <PayoutStatusChip payoutStatus={payout.status} size="small" />
              </Stack>
            </ListItemButton>
          ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>Load more</Button>
          </Stack>
        )}
      </MainSecondarySidebarList>
    </MainSecondarySidebarPage>
  );
};
