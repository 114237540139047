import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { updateTalentDraftCallable } from "../../Talent/callables/updateTalentDraftCallable";
import { useMyTalentDraft } from "../../Talent/hooks/useMyTalentDraft";
import { getOnboardingStepPath } from "../helpers/getOnboardingStepPath";
import { OnboardingTalentProfilePictureInput } from "./OnboardingTalentProfilePictureInput";

const FormValues = z.object({
  profilePictureUrl: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

export const OnboardingTalentProfilePicturePage: FC = () => {
  const talentDraft = useMyTalentDraft();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      profilePictureUrl: talentDraft?.profilePictureUrl || "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!talentDraft?.id) {
          throw new Error("Talent ID is not defined");
        }

        await updateTalentDraftCallable({
          id: talentDraft.id,
          profilePictureUrl: formValues.profilePictureUrl,
        });

        navigate(getOnboardingStepPath("TALENT_PROFILE_PICTURE", "NEXT"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, talentDraft]
  );

  const isDisabled = formState.isSubmitting;

  return (
    <Stack
      mt={2}
      p={2}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <Typography variant="ah5" color="text.secondary" textAlign="center">
          The Basics
        </Typography>
        <Typography variant="ah2" color="text.primary" textAlign="center">
          Upload your Profile Picture
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={4}>
        <Controller
          name="profilePictureUrl"
          control={control}
          render={({ field }) => (
            <OnboardingTalentProfilePictureInput
              ownerId={talentDraft?.id || ""}
              ownerType="TALENT"
              url={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Stack spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isDisabled}
            size="large"
            fullWidth
          >
            Continue
          </Button>
          <Button
            disabled={isDisabled}
            size="large"
            startIcon={<ArrowBackIcon />}
            to={getOnboardingStepPath("TALENT_PROFILE_PICTURE", "PREVIOUS")}
            component={NavLink}
            fullWidth
          >
            Previous
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
