import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Stack } from "@mui/material";
import { FC } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { FileInput } from "../../../Common/views/FileInput";
import { ControlledSwitch } from "../../../Form/views/ControlledSwitch";
import { FilePreview } from "./FilePreview";
import { FormValues } from "./FormValues";

interface Props {
  index: number;
  control: Control<FormValues>;
  isLoading: boolean;
  talentId: string;
}

export const AttachmentFormFields: FC<Props> = (props) => {
  const { control, index, isLoading, talentId } = props;

  const attachments = useFieldArray({
    control,
    name: `initialChatMessages.${index}.attachments`,
  });

  const [uploadFiles, isUploadingFiles] = useUploadFiles({
    ownerId: talentId,
    ownerType: "TALENT",
    onFileUploaded: (uploadedFile) => {
      switch (uploadedFile.type) {
        case "IMAGE":
          attachments.append({
            type: "IMAGE",
            filePath: uploadedFile.filePath,
            dimensions: uploadedFile.dimensions,
            isUnlocked: true,
          });
          break;
        case "AUDIO":
          attachments.append({
            type: "AUDIO",
            filePath: uploadedFile.filePath,
            isUnlocked: true,
          });
          break;
      }
    },
  });

  return (
    <Stack pl={8} justifyContent="center" spacing={2}>
      {attachments.fields.map((field, i) => (
        <Stack key={field.id} spacing={2} direction="row" alignItems="center">
          {field.filePath && (
            <Stack width={56} height={56}>
              <FilePreview filePath={field.filePath} type={field.type} />
            </Stack>
          )}
          <Stack flex={1}>
            <ControlledSwitch
              control={control}
              label="Unlocked"
              name={`initialChatMessages.${index}.attachments.${i}.isUnlocked`}
              // initial chat messages do not have `staticAttachmentId` which we use as file ID to unblur. We don't allow locked attachments until we can guarantee this ID is set.
              disabled
            />
          </Stack>
          <Stack justifyContent="center">
            <IconButton
              color="error"
              onClick={() => attachments.remove(i)}
              disabled={isLoading}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      ))}

      <FileInput
        disabled={isUploadingFiles}
        onSelect={uploadFiles}
        accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3"
        multiple
      >
        <Button variant="text" disabled={isUploadingFiles}>
          {isUploadingFiles && "Uploading... "}
          {!isUploadingFiles && "Add Attachments"}
        </Button>
      </FileInput>
    </Stack>
  );
};
