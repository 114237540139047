import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, Stack, Typography } from "@mui/material";
import {
  CURRENT_TALENT_INTERESTS,
  Gender,
  GENDERS,
  MAX_CHAT_PROMPT_LENGTH,
  Talent,
  TALENT_PERSONALITY_TRAITS,
  TALENT_PURPOSES,
  TalentConfig,
  TalentInterest,
  TalentPersonalityTrait,
  TalentPurpose,
} from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledSelect } from "../../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";
import { TALENT_GENDER_RECORD } from "../../../Talent/consts/TALENT_GENDER_RECORD";
import { TALENT_INTEREST_RECORD } from "../../../Talent/consts/TALENT_INTEREST_RECORD";
import { TALENT_PERSONALITY_TRAIT_RECORD } from "../../../Talent/consts/TALENT_PERSONALITY_TRAIT_RECORD";
import { TALENT_PURPOSE_RECORD } from "../../../Talent/consts/TALENT_PURPOSE_RECORD";

const FormValues = z.object({
  name: z.string().nonempty(),
  gender: Gender,
  age: z.number().int().min(18).max(1000),
  hasCustomChatPrompt: z.boolean(),
  chatPrompt: z.string().max(MAX_CHAT_PROMPT_LENGTH),
  purpose: TalentPurpose,
  personalityTraits: z.array(TalentPersonalityTrait).min(1).max(3),
  interests: z.array(TalentInterest).min(1).max(5),
  facts: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent: Talent;
  talentConfig: TalentConfig;
}

export const CreatorSettingsProfileForm: FC<Props> = (props) => {
  const { talent, talentConfig } = props;
  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talent.name,
      gender: talent.gender,
      age: talent.age,
      hasCustomChatPrompt: !!talentConfig.hasCustomChatPrompt,
      chatPrompt: talentConfig.chatPrompt,
      purpose: talentConfig.purpose || "FLIRT",
      interests: talentConfig.interests || [],
      personalityTraits: talentConfig.personalityTraits || [],
      facts: talentConfig.facts,
    },
  });

  const hasCustomChatPrompt = watch("hasCustomChatPrompt");
  const chatPrompt = watch("chatPrompt");

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateTalentCallable({
          id: talent.id,
          name: formValues.name,
          gender: formValues.gender,
          age: formValues.age,
          hasCustomChatPrompt: formValues.hasCustomChatPrompt,
          chatPrompt: formValues.chatPrompt,
          purpose: formValues.purpose,
          personalityTraits: formValues.personalityTraits,
          interests: formValues.interests,
          facts: formValues.facts,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <ControlledTextField
        control={control}
        label="Name"
        name="name"
        autoComplete="off"
        disabled={isLoading}
      />
      <ControlledSelect
        control={control}
        label="Gender"
        name="gender"
        items={GENDERS.map((gender) => ({
          label: TALENT_GENDER_RECORD[gender].label,
          value: gender,
        }))}
        renderItem={(item) => item.label}
        disabled={isLoading}
        fullWidth
      />
      <ControlledTextField
        control={control}
        label="Age"
        name="age"
        type="number"
        autoComplete="off"
        disabled={isLoading}
        fullWidth
      />
      <Typography variant="ah5">Your Personality</Typography>
      {hasCustomChatPrompt && (
        <Stack>
          <ControlledTextField
            control={control}
            label="Custom Chat Prompt"
            name="chatPrompt"
            autoComplete="off"
            disabled={isLoading}
            inputProps={{ maxLength: MAX_CHAT_PROMPT_LENGTH }}
            minRows={3}
            multiline
          />
          <Typography variant="caption" fontSize={10} textAlign="right">
            {chatPrompt.length} / {MAX_CHAT_PROMPT_LENGTH}
          </Typography>
        </Stack>
      )}
      <ControlledSelect
        control={control}
        name="purpose"
        label="Purpose"
        items={TALENT_PURPOSES.map((purpose) => ({
          label: TALENT_PURPOSE_RECORD[purpose].label,
          value: purpose,
        }))}
        renderItem={(item) => item.label}
        disabled={isLoading}
        fullWidth
      />
      <ControlledSelect
        control={control}
        name="personalityTraits"
        label="Personality Traits"
        items={TALENT_PERSONALITY_TRAITS.map((trait) => ({
          label: TALENT_PERSONALITY_TRAIT_RECORD[trait].label,
          value: trait,
        }))}
        renderItem={(item) => item.label}
        disabled={isLoading}
        multiple
        fullWidth
      />
      <ControlledSelect
        control={control}
        name="interests"
        label="Interests"
        items={CURRENT_TALENT_INTERESTS.map((interest) => ({
          label: `${TALENT_INTEREST_RECORD[interest].label}`,
          value: interest,
        }))}
        renderItem={(item) => item.label}
        disabled={isLoading}
        multiple
        fullWidth
      />
      <Stack>
        <ControlledTextField
          control={control}
          label="Facts & Personality"
          name="facts"
          placeholder={[
            "is from Los Angeles",
            "is 6 ft 3 tall",
            "is proud of his 43 tattoos",
            "streams regularly on Twitch",
            "is famous for his 24 hour lifestreams",
            "cares a lot about encouraging his fans to be strong",
            "loves kpop",
            "goes to cosplay conventions in his free time",
          ].join("\n")}
          inputLabelProps={{ shrink: true }}
          autoComplete="off"
          disabled={isLoading}
          minRows={3}
          maxRows={8}
          multiline
        />
        <Typography variant="caption" fontSize={10}>
          Follow this format (1 per line) & add as many as you&apos;d like so we
          can give your bot some personality
        </Typography>
      </Stack>
      <Stack py={2}>
        <Divider />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
