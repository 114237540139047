import { Divider, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MainMenuButton } from "../../Main/views/MainMenuButton";
import { MainPage } from "../../Main/views/MainPage";
import { ONBOARDING_STEP_RECORD } from "../consts/ONBOARDING_STEP_RECORD";
import { ONBOARDING_STEPS } from "../types/OnboardingStep";

export const OnboardingLayout: FC = () => {
  const { pathname } = useLocation();
  const step = Object.entries(ONBOARDING_STEP_RECORD).find(
    ([, value]) => pathname === `/${value.path}`
  )?.[0];

  const stepIndex = ONBOARDING_STEPS.findIndex((s) => s === step);
  const progress = stepIndex / ONBOARDING_STEPS.length;

  return (
    <MainPage>
      <Stack
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="ah3" color="success.main">
          {Math.round(progress * 100)}%
        </Typography>
        <MainMenuButton />
      </Stack>
      <Stack p={2} spacing={1} direction="row">
        {ONBOARDING_STEPS.map((_, index) => (
          <SProgressStack
            key={index}
            sx={{ bgcolor: index < stepIndex ? "success.main" : "divider" }}
          />
        ))}
      </Stack>
      <Divider />
      <Outlet />
    </MainPage>
  );
};

const PROGRESS_HEIGHT = 4;

const SProgressStack = styled(Stack)(({ theme }) => ({
  height: PROGRESS_HEIGHT,
  borderRadius: PROGRESS_HEIGHT / 2,
  flex: 1,
  backgroundColor: theme.palette.divider,
}));
