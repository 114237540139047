import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { CreatorSettingsChatMessageSuggestionsForm } from "./CreatorSettingsChatMessageSuggestionsForm";

export const CreatorSettingsChatMessageSuggestionsPage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);

  return (
    <Stack px={2} py={3} spacing={4}>
      <Typography variant="ah1" color="text.secondary">
        💡 Conversation Starters
      </Typography>
      <Typography variant="ah5" color="text.primary">
        Write down some questions that your fans could ask your avatar to get
        the chat going.
      </Typography>
      {talent && <CreatorSettingsChatMessageSuggestionsForm talent={talent} />}
    </Stack>
  );
};
