import { Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
}

export const CreatorDashboardHeadlineView: FC<Props> = (props) => {
  const { title, children } = props;

  return (
    <Stack
      p={2}
      borderTop={1}
      borderBottom={1}
      bgcolor="grey.100"
      borderColor="divider"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Typography variant="ah3">{title}</Typography>
      {children}
    </Stack>
  );
};
