import { isEarningFrame } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useEarningFrame(talentId?: string, earingFrameId?: string) {
  return useValidDocumentData(
    talentId && earingFrameId
      ? getDocRef(`talentConfigs/${talentId}/earningFrames`, earingFrameId)
      : null,
    isEarningFrame
  );
}
