import { Reaction } from "@super-real/types";

export function toReactionEmoji(reaction: Reaction): string {
  switch (reaction) {
    case "THUMBS_UP":
      return "👍";
    case "THUMBS_DOWN":
      return "👎";
  }
}
