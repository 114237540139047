import { Paper, Stack, Typography } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC } from "react";
import { CreatorPayoutsView } from "../../Creator/views/CreatorPayoutsView";

interface Props {
  talent: Talent;
}

export const TalentPayoutsView: FC<Props> = (props) => {
  const { talent } = props;

  return (
    <Stack spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Payouts</Typography>
        <CreatorPayoutsView talent={talent} />
      </Stack>
    </Stack>
  );
};
