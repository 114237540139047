import {
  Avatar,
  Button,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { MainContainer } from "../../Main/views/MainContainer";
import { useTalent } from "../../Talent/hooks/useTalent";
import { TalentChip } from "../../Talent/views/TalentChip";
import { UserChip } from "../../User/views/UserChip";
import { useChat } from "../hooks/useChat";
import { useChatMessagesByChatId } from "../hooks/useChatMessagesByChatId";
import { ChatMessageView } from "./ChatMessageView";

const AVATAR_SIZE = 32;

export const ChatPage: FC = () => {
  const { chatId, chatMessageId } = useParams<"chatId" | "chatMessageId">();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [chat] = useChat(chatId);
  const [talent] = useTalent(chat?.talentId);
  const [chatMessages, , , controller] = useChatMessagesByChatId(chatId);
  const currentLength = chatMessages.length;
  const previousLength = usePrevious(currentLength);
  const areChatImagesVisible = useLocalStorage(
    (state) => !!state.data.areChatImagesVisible
  );

  useEffect(() => {
    if (!scrollRef.current) return;
    if (currentLength === previousLength) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    scrollRef.current.scrollIntoView({
      behavior: previousLength ? "smooth" : "instant",
    });
  }, [currentLength, previousLength]);

  if (!talent) return null;
  if (!chat) return null;

  return (
    <>
      <Stack
        p={2}
        bgcolor="background.paper"
        direction="row"
        spacing={1}
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Avatar
          src={talent.profilePictureUrl}
          sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        />
        <TalentChip talentId={talent?.id} />
        <UserChip userId={chat.userId} isAnonymous />
        <Chip label={`Messages: ${chat.chatMessageCount || 0}`} />
        <Stack flex={1} />
        <FormControlLabel
          control={<Switch checked={areChatImagesVisible} />}
          label="🏞️"
          onChange={(e) => {
            const checked = (e as ChangeEvent<HTMLInputElement>).target.checked;
            useLocalStorage
              .getState()
              .setData({ areChatImagesVisible: checked });
          }}
        />
      </Stack>
      <MainContainer>
        <Stack p={3} spacing={3} flex={1}>
          {controller.hasMore && (
            <Stack mx={2} my={1}>
              <Button onClick={controller.loadMore}>Load more</Button>
            </Stack>
          )}
          {chatMessages
            .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
            .map((chatMessage) => (
              <ChatMessageView
                key={chatMessage.id}
                chatId={chat?.id}
                chatMessage={chatMessage}
                isSelected={chatMessage.id === chatMessageId}
                areChatImagesVisible={areChatImagesVisible}
              />
            ))}
          <Stack ref={scrollRef} />
        </Stack>
      </MainContainer>
    </>
  );
};
