import { EarningFrame } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { sumEarningFrameAmounts } from "./sumEarningFrameAmounts";

interface Params {
  earningFrames: EarningFrame[];
}

export function mergeEarningFramesToTotal(params: Params): EarningFrame {
  const { earningFrames } = params;

  const startedAt = earningFrames[earningFrames.length - 1].startedAt;
  const endedAt = earningFrames[0].endedAt;

  return {
    id: "total",
    range: "TOTAL",
    currency: "USD",
    startedAt,
    endedAt,
    talentId: "INVALID_TALENT_ID",
    ...sumEarningFrameAmounts(earningFrames),
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  } satisfies EarningFrame;
}
