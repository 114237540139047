import { Stack, styled } from "@mui/material";
import { StaticAttachment } from "@super-real/types/types/StaticAttachment";
import { FC } from "react";
import { useStorageFile } from "../../../Common/hooks/useStorageFile";
import { LoadingView } from "../../../Common/views/LoadingView";

interface Props {
  type: StaticAttachment["type"];
  filePath: string;
}

export const FilePreview: FC<Props> = (props) => {
  const { filePath } = props;
  const { url, isLoading } = useStorageFile(filePath);

  return (
    <Stack height="100%" sx={{ aspectRatio: 1 }}>
      {isLoading && <LoadingView />}
      {filePath && <ImagePreview src={url} />}
    </Stack>
  );
};

const ImagePreview = styled("div")<{ src?: string }>(({ src }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  borderRadius: 4,
  background: src ? `url("${src}") #000` : undefined,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}));
