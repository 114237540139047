import {
  ChatMessage,
  FirebaseFilter,
  FirebaseOrderBy,
  isChatMessage,
} from "@super-real/types";
import { orderBy, query, where } from "firebase/firestore";
import { usePaginatedCollection } from "../../Common/hooks/usePaginatedCollection";
import { getCollectionGroupRef } from "../../Firebase/helpers/getCollectionGroupRef";

export function useChatMessages(
  filters: FirebaseFilter<ChatMessage>[],
  firebaseOrderBy: FirebaseOrderBy<ChatMessage> = ["createdAt", "desc"]
) {
  return usePaginatedCollection(
    query(
      getCollectionGroupRef("messages"),
      ...filters.map((filter) => where(...filter)),
      orderBy(...firebaseOrderBy)
    ),
    isChatMessage
  );
}
