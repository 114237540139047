import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import WarningIcon from "@mui/icons-material/Warning";
import { ChipOwnProps, SvgIconOwnProps, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { PayoutStatus } from "@super-real/types";

interface RecordValue {
  label: string;
  chipColor: ChipOwnProps["color"];
  iconColor: SvgIconOwnProps["color"];
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const PAYOUT_STATUS_RECORD: Record<PayoutStatus, RecordValue> = {
  PENDING: {
    label: "Pending",
    chipColor: "default",
    iconColor: "action",
    Icon: PendingIcon,
  },
  EXECUTED: {
    label: "Executed",
    chipColor: "success",
    iconColor: "success",
    Icon: CheckCircleIcon,
  },
  REJECTED: {
    label: "Rejected",
    chipColor: "error",
    iconColor: "error",
    Icon: WarningIcon,
  },
  FAILED: {
    label: "Failed",
    chipColor: "error",
    iconColor: "error",
    Icon: WarningIcon,
  },
};
