import { zodResolver } from "@hookform/resolvers/zod";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SendIcon from "@mui/icons-material/Send";
import StarIcon from "@mui/icons-material/Star";
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import { DateTime } from "luxon";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledDateTimePicker } from "../../Form/views/ControlledDateTimePicker";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { adminCreateGlobalBroadcastCallable } from "../callables/adminCreateGlobalBroadcastCallable";

const FormValues = z.object({
  text: z.string().nonempty(),
  scheduledAt: z.string().datetime(),
  hasPushNotification: z.boolean(),
  pushNotificationHeadline: z.string(),
  pushNotificationMessage: z.string(),
  isTalentBroadcast: z.boolean(),
  talentId: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

export const BroadcastCreateForm: FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState, reset, watch } =
    useForm<FormValues>({
      resolver: zodResolver(FormValues),
      defaultValues: {
        text: "",
        scheduledAt: DateTime.utc().toISO(),
        hasPushNotification: false,
        pushNotificationHeadline: "",
        pushNotificationMessage: "",
        isTalentBroadcast: false,
        talentId: "",
      },
    });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const isConfirmed = window.confirm(
          "Are you sure you want to send this message to all users?"
        );

        if (!isConfirmed) return;

        const { broadcastId } = await adminCreateGlobalBroadcastCallable({
          ...(formValues.isTalentBroadcast && {
            talentId: formValues.talentId,
          }),
          ...(formValues.hasPushNotification && {
            pushNotificationHeadline: formValues.pushNotificationHeadline,
            pushNotificationMessage: formValues.pushNotificationMessage,
          }),
          chatMessageText: formValues.text,
          scheduledAt: formValues.scheduledAt,
        });
        navigate(`/broadcasts/${broadcastId}`);
        toast.success(`Scheduled broadcast.`);
        reset();
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, reset]
  );

  const hasPushNotification = watch("hasPushNotification");
  const isTalentBroadcast = watch("isTalentBroadcast");
  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Alert severity="error">
        This message will be sent to <strong>all</strong> users
        <ul>
          <li>that have signed in</li>
          <li>
            that have not interacted with any talent within the last 10 minutes
          </li>
          <li>that have interacted with any talent in the last month</li>
        </ul>
      </Alert>
      <SAlert severity="success" icon={<ChatBubbleIcon />}>
        <ControlledTextField
          control={control}
          label="Message"
          name="text"
          multiline
          autoComplete="off"
          disabled={isLoading}
        />
      </SAlert>
      <SAlert severity="info" icon={<NotificationsActiveIcon />}>
        <Stack spacing={2}>
          <ControlledSwitch
            control={control}
            label="Send Push Notification"
            name="hasPushNotification"
            disabled={isLoading}
          />
          {hasPushNotification && (
            <>
              <ControlledTextField
                control={control}
                label="Headline"
                name="pushNotificationHeadline"
                autoComplete="off"
                disabled={isLoading}
              />
              <ControlledTextField
                control={control}
                label="Message"
                name="pushNotificationMessage"
                autoComplete="off"
                disabled={isLoading}
              />
            </>
          )}
        </Stack>
      </SAlert>
      <SAlert severity="warning" icon={<StarIcon />}>
        <Stack spacing={2}>
          <ControlledSwitch
            control={control}
            label="Specific Talent"
            name="isTalentBroadcast"
            disabled={isLoading}
          />
          {isTalentBroadcast && (
            <ControlledTextField
              control={control}
              label="Talent ID"
              name="talentId"
              autoComplete="off"
              disabled={isLoading}
            />
          )}
        </Stack>
      </SAlert>
      <Stack component={Paper} p={2} spacing={2}>
        <ControlledDateTimePicker
          control={control}
          labelForDate="Scheduled Date"
          labelForTime="Scheduled Time"
          name="scheduledAt"
        />
      </Stack>
      <Button
        size="large"
        color="success"
        type="submit"
        variant="contained"
        startIcon={
          isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <SendIcon />
          )
        }
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        {isLoading && "Sending..."}
        {!isLoading && "Send Message"}
      </Button>
    </Stack>
  );
};

const SAlert = styled(Alert)({
  ".MuiAlert-message": {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "unset",
  },
  ".MuiAlert-icon": {
    paddingTop: 14,
  },
});
