import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ONBOARDING_STEP_RECORD } from "../../Onboarding/consts/ONBOARDING_STEP_RECORD";
import { useOnboardingStep } from "../../Onboarding/hooks/useOnboardingStep";
import { OnboardingLayout } from "../../Onboarding/views/OnboardingLayout";

export const MainOnboardingRoutes: FC = () => {
  const step = useOnboardingStep((state) => state.step);

  if (!step) return null;

  return (
    <>
      <Routes>
        <Route element={<OnboardingLayout />}>
          <Route
            index
            element={
              <Navigate replace to={`/${ONBOARDING_STEP_RECORD[step].path}`} />
            }
          />
          {Object.values(ONBOARDING_STEP_RECORD).map(({ path, Page }) => (
            <Route key={path} path={`/${path}`} element={<Page />} />
          ))}
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};
