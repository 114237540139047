import { App, isApp } from "@super-real/types";
import { onSnapshot } from "firebase/firestore";
import { create } from "zustand";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";

interface State {
  app?: App;
  isInitializing: boolean;
  subscribe: (appId: string) => void;
  unsubscribe?: () => void;
}

export const useApp = create<State>((set) => ({
  isInitializing: true,
  subscribe: (appId) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef("apps", appId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const app = getDataFromDocumentSnapshot(isApp, snapshot);

        set({
          app,
          ...(app && { isInitializing: false }),
        });
      },
      () => {
        set({ app: undefined, isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ app: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
