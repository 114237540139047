import { zodResolver } from "@hookform/resolvers/zod";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, IconButton, Stack } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledSwitch } from "../../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { updateTalentCallable } from "../../../Talent/callables/updateTalentCallable";

const FormValues = z.object({
  isAutoAnswerEnabled: z.boolean(),
  chatMessageSuggestions: z.array(
    z.object({
      id: z.string(),
      text: z.string().nonempty(),
      answer: z.string(),
    })
  ),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent: Talent;
}

export const CreatorSettingsChatMessageSuggestionsForm: FC<Props> = (props) => {
  const { talent } = props;

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      // With suggestions
      ...(talent.chatMessageSuggestions?.length && {
        isAutoAnswerEnabled: !talent.chatMessageSuggestions.some(
          (s) => s.answer
        ),
        chatMessageSuggestions: talent.chatMessageSuggestions.map((s) => ({
          id: s.id,
          text: s.text,
          answer: s.answer || "",
        })),
      }),
      // Without suggestions
      ...(!talent.chatMessageSuggestions?.length && {
        isAutoAnswerEnabled: true,
        chatMessageSuggestions: [
          { id: v4(), text: "", answer: "" },
          { id: v4(), text: "", answer: "" },
        ],
      }),
    },
  });

  const chatMessageSuggestions = useFieldArray({
    control,
    name: "chatMessageSuggestions",
  });

  const isAutoAnswerEnabled = watch("isAutoAnswerEnabled");

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateTalentCallable({
          id: talent.id,
          chatMessageSuggestions: formValues.chatMessageSuggestions.map(
            (s) => ({
              id: s.id,
              text: s.text,
              ...(!formValues.isAutoAnswerEnabled &&
                s.answer && {
                  answer: s.answer,
                }),
            })
          ),
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack pb={2}>
        <ControlledSwitch
          control={control}
          label="Answered by AI"
          name="isAutoAnswerEnabled"
          disabled={isLoading}
        />
      </Stack>
      <Stack spacing={isAutoAnswerEnabled ? 2 : 6}>
        {chatMessageSuggestions.fields.map((field, index) => (
          <Stack key={field.id} spacing={2}>
            <ControlledTextField
              control={control}
              label={`Question ${index + 1}`}
              name={`chatMessageSuggestions.${index}.text`}
              autoComplete="off"
              disabled={isLoading}
              placeholder={getPlaceholder(index)}
              endAdornment={
                <IconButton
                  onClick={() => chatMessageSuggestions.remove(index)}
                  disabled={isLoading}
                  color="error"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              }
              fullWidth
            />
            {!isAutoAnswerEnabled && (
              <>
                <ControlledTextField
                  control={control}
                  label={`Static Answer ${index + 1}`}
                  name={`chatMessageSuggestions.${index}.answer`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                />
              </>
            )}
          </Stack>
        ))}
      </Stack>
      {chatMessageSuggestions.fields.length < 3 && (
        <Button
          size="large"
          startIcon={<AddCircleIcon />}
          onClick={() =>
            chatMessageSuggestions.append({
              id: v4(),
              text: "",
              answer: "",
            })
          }
          disabled={isLoading}
        >
          Add message
        </Button>
      )}
      <Stack py={2}>
        <Divider />
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};

function getPlaceholder(index: number) {
  switch (index) {
    case 0:
      return "Tell me more about yourself!";
    case 1:
      return "Send me a pic 😍";
    case 2:
      return "I had a tough day 😢 Can you cheer me up?";
    default:
      return `Suggestion ${index + 1}`;
  }
}
