import { ModelMessageRole } from "@super-real/types";

interface RecordValue {
  short: string;
}

export const MODEL_MESSAGE_ROLE_RECORD: Record<ModelMessageRole, RecordValue> =
  {
    USER: {
      short: "U",
    },
    ASSISTANT: {
      short: "A",
    },
    SYSTEM: {
      short: "S",
    },
  };
