import {
  Chip,
  ChipOwnProps,
  CircularProgress,
  Stack,
  styled,
  SvgIconTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";

interface Props {
  color?: ChipOwnProps["color"];
  label: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  isLoading?: boolean;
}

export const StatusChip: FC<Props> = (props: Props) => {
  const { color, Icon, label, isLoading } = props;

  return (
    <SChip
      size="small"
      color={color}
      label={label}
      icon={
        isLoading ? (
          <Stack pl={0.5} pr={1}>
            <CircularProgress size={12} />
          </Stack>
        ) : (
          Icon && <Icon />
        )
      }
    />
  );
};

const SChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontSize: 12,
  height: 26,
  borderRadius: 60,
  cursor: "inherit",
  "& .MuiChip-icon": {
    color: "inherit",
  },
}));
