import { Skeleton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";

interface Props {
  label: string;
  netAmount?: number;
  isLoading: boolean;
}

export const CreatorEarningTypeView: FC<Props> = (props) => {
  const { label, netAmount, isLoading } = props;

  return (
    <Stack flex={1}>
      <Typography variant="ah6" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="ah5">
        {isLoading && <Skeleton width={40} />}
        {!isLoading && toLocaleMoney({ amount: netAmount || 0 })}
      </Typography>
    </Stack>
  );
};
