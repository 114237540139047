import { EarningFrame, FirebaseFilter } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useIsLoadingNextTick } from "../../Common/hooks/useIsLoadingNextTick";
import { normalizeEarningFramesByMonth } from "../../Talent/helpers/normalizeEarningFramesByMonth";
import { useEarningFrames } from "./useEarningFrames";

export const useEarningFramesByMonth = (talentId: string) => {
  const [earningFramesByMonth, isLoadingByMonth, errorByMonth] =
    useEarningFrames(talentId, [["range", "==", "MONTH"]]);

  earningFramesByMonth.sort(
    (a, b) => b.startedAt.toMillis() - a.startedAt.toMillis()
  );

  const latestEndedAt = useMemo(() => {
    if (!earningFramesByMonth.length) return undefined;
    return DateTime.fromJSDate(earningFramesByMonth[0].endedAt.toDate());
  }, [earningFramesByMonth]);

  const [earningFramesByDay, isLoadingByDay, errorByDay] = useEarningFrames(
    isLoadingByMonth ? undefined : talentId,
    [
      ["range", "==", "DAY"],
      ...(latestEndedAt
        ? [
            [
              "startedAt",
              ">=",
              Timestamp.fromDate(latestEndedAt.toJSDate()),
            ] satisfies FirebaseFilter<EarningFrame>,
          ]
        : []),
    ]
  );

  earningFramesByDay.sort(
    (a, b) => b.startedAt.toMillis() - a.startedAt.toMillis()
  );

  const normalizedEarningFrames = useMemo(
    () =>
      normalizeEarningFramesByMonth({
        earningFramesByMonth,
        earningFramesByDay,
      }),
    [earningFramesByDay, earningFramesByMonth]
  );

  const isLoadingNextTick = useIsLoadingNextTick(!isLoadingByMonth);
  const isLoading = isLoadingByMonth || isLoadingByDay || isLoadingNextTick;
  const error = errorByMonth || errorByDay;

  return [normalizedEarningFrames, isLoading, error] as const;
};
