import { isUser } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useUser(userIdId?: string) {
  return useValidDocumentData(
    userIdId ? getDocRef("users", userIdId) : null,
    isUser
  );
}
