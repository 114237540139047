import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

const DATE_TIME_FORMAT = "dd/MM/yyyy";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  labelForDate?: ReactNode;
  labelForTime?: ReactNode;
  disabled?: boolean;
}

export function ControlledDateTimePicker<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: Props<TFieldValues, TName>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field, fieldState }) => {
        const currentDate = field.value
          ? DateTime.fromISO(field.value) ?? null
          : null;

        return (
          <>
            <DatePicker
              value={currentDate}
              format={DATE_TIME_FORMAT}
              label={props.labelForDate}
              disabled={props.disabled}
              onChange={(nextDate) => {
                if (nextDate === null || !nextDate?.isValid) {
                  if (field.value) field.onChange(null);
                  return;
                }

                field.onChange(nextDate.toUTC().toISO());
              }}
            />
            <TimePicker
              value={currentDate}
              label={props.labelForTime}
              disabled={props.disabled}
              ampm={false}
              onChange={(nextDate) => {
                if (nextDate === null || !nextDate?.isValid) {
                  if (field.value) field.onChange(null);
                  return;
                }

                field.onChange(nextDate.toUTC().toISO());
              }}
            />
            {!!fieldState.error && (
              <FormControl error>
                {fieldState.error?.message && (
                  <FormHelperText>{fieldState.error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          </>
        );
      }}
    />
  );
}
