import { FaqItem } from "@super-real/types";

export const toFaqItems = (value: string) => {
  const rows = value.split("\n");
  const items: FaqItem[] = [];

  if (rows.length === 1 && rows[0] === "") {
    throw new Error("Add at least one question and answer.");
  }

  rows.forEach((row, index) => {
    const isQuestion = index % 2 === 0;
    const type = row.substring(0, 3);
    const value = row.substring(3);

    if (isQuestion && type !== "Q: ") {
      throw new Error(`Invalid question at row ${index + 1}`);
    }

    if (!isQuestion && type !== "A: ") {
      throw new Error(`Invalid answer at row ${index + 1}`);
    }

    if (isQuestion) {
      items.push({
        question: value,
        answer: "",
      });
    } else {
      items[items.length - 1].answer = value;
    }
  });

  return items;
};
