import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { Button, Fab, Stack, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../../Common/helpers/handleError";
import { logEvent } from "../../../Common/helpers/logEvent";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { FileInput } from "../../../Common/views/FileInput";
import { useMyTalentConfigs } from "../../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { updateTalentConfigAddStaticAttachmentCallable } from "../../callables/updateTalentConfigAddStaticAttachmentCallable";
import { StaticImageAttachmentDialog } from "./StaticImageAttachmentDialog";
import { StaticImageAttachmentGallery } from "./StaticImageAttachmentGallery";

export const CreatorSettingsGalleryPage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [talent] = useMyTalents((state) => state.talents);
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);
  const [skeletonCount, setSkeletonCount] = useState(0);

  const staticImageAttachments = useMemo(() => {
    if (!talentConfig?.staticAttachments) return [];
    return talentConfig.staticAttachments.filter(
      ({ type }) => type === "IMAGE"
    );
  }, [talentConfig]);

  const [uploadFiles, isUploadingFiles] = useUploadFiles({
    ownerId: talentConfig?.id,
    ownerType: "TALENT",
    onFileUploadStarted: () => setSkeletonCount((prev) => prev + 1),
    onFileUploaded: (uploadedFile, originalFile) => {
      if (uploadedFile.type !== "IMAGE") return;

      updateTalentConfigAddStaticAttachmentCallable({
        id: talentConfig?.id,
        staticAttachment: {
          id: uploadedFile.id,
          type: uploadedFile.type,
          originalFileName: originalFile.name,
          filePath: uploadedFile.filePath,
          lockedFilePath: uploadedFile.lockedFilePath,
          dimensions: uploadedFile.dimensions,
          context: `image of ${talent?.name}`,
        },
      })
        .then(() => logEvent("uploaded_gallery_image"))
        .catch(handleError)
        .finally(() => setSkeletonCount((prev) => prev - 1));
    },
  });

  const onAttachmentSelect = useCallback(
    (id: string) => navigate(`${pathname}?attachmentId=${id}`),
    [navigate, pathname]
  );

  return (
    <Stack position="relative" pt={3} spacing={2}>
      <Stack px={2}>
        <Typography variant="ah3" color="text.secondary">
          📸 Image Gallery
        </Typography>
      </Stack>

      {!staticImageAttachments.length && !skeletonCount && (
        <Stack bgcolor="grey.100" p={2} spacing={2}>
          <Stack>
            <Typography variant="ah3">Upload Images</Typography>
            <Typography variant="body2" color="text.secondary">
              Please upload a variety of images so your AI can surface the right
              ones at the right time.
            </Typography>
          </Stack>

          <FileInput
            disabled={isUploadingFiles}
            onSelect={uploadFiles}
            accept="image/jpg,image/jpeg,image/png,image/webp"
            multiple
          >
            <Button
              variant="contained"
              size="large"
              startIcon={!isUploadingFiles && <FileUploadRoundedIcon />}
              disabled={isUploadingFiles}
              fullWidth
            >
              {isUploadingFiles && "Uploading..."}
              {!isUploadingFiles && "Upload Images"}
            </Button>
          </FileInput>
        </Stack>
      )}

      {(!!staticImageAttachments?.length || !!skeletonCount) && (
        <>
          <StaticImageAttachmentGallery
            attachments={staticImageAttachments}
            skeletonCount={skeletonCount}
            onAttachmentSelect={onAttachmentSelect}
          />

          <Stack
            position="fixed"
            alignItems="flex-end"
            width="100%"
            maxWidth={
              theme.breakpoints.values.sm -
              Number(theme.spacing(6).replace("px", ""))
            }
            bottom={0}
            p={3}
            sx={{ pointerEvents: "none" }}
          >
            <FileInput
              disabled={isUploadingFiles}
              onSelect={uploadFiles}
              accept="image/jpg,image/jpeg,image/png,image/webp"
              multiple
            >
              <Fab
                variant="extended"
                color="primary"
                disabled={isUploadingFiles}
                sx={{ pointerEvents: "auto" }}
              >
                {!isUploadingFiles && <FileUploadRoundedIcon />}
                <Typography textTransform="capitalize" fontWeight={700}>
                  {isUploadingFiles && "Uploading..."}
                  {!isUploadingFiles && "Upload"}
                </Typography>
              </Fab>
            </FileInput>
          </Stack>
        </>
      )}

      <StaticImageAttachmentDialog />
    </Stack>
  );
};
