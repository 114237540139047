import AddIcon from "@mui/icons-material/Add";
import { IconButton, ListItemButton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { MainSecondarySidebarList } from "../../Main/views/MainSecondarySidebarList";
import { MainSecondarySidebarPage } from "../../Main/views/MainSecondarySidebarPage";
import { useFaqs } from "../hooks/useFaqs";

export const FaqsPage: FC = () => {
  const [faqs, , error] = useFaqs([null]);

  return (
    <MainSecondarySidebarPage>
      <Stack px={3} pt={3} pb={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">FAQ</Typography>
          <Stack>
            <IconButton
              sx={{ mt: -1, "&.active": { color: "primary.main" } }}
              size="large"
              to="/faqs/create"
              component={NavLink}
            >
              <AddIcon color="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      {!!faqs.length && (
        <MainSecondarySidebarList>
          {faqs
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((faq) => (
              <ListItemButton
                key={faq.id}
                to={`/faqs/${faq.id}`}
                component={NavLink}
              >
                {faq.name}
              </ListItemButton>
            ))}
        </MainSecondarySidebarList>
      )}
    </MainSecondarySidebarPage>
  );
};
