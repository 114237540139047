import { create } from "zustand";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useMyTalentDrafts } from "../../Talent/hooks/useMyTalentDrafts";
import { useMyUser } from "../../User/hooks/useMyUser";
import { ONBOARDING_STEPS, OnboardingStep } from "../types/OnboardingStep";

interface State {
  isWaitlist: boolean;
  step?: OnboardingStep;
  refresh: () => void;
}

export const useOnboardingStep = create<State>((set) => ({
  isWaitlist: false,
  refresh: () => {
    const { user } = useMyUser.getState();
    const { talentDrafts, isInitializing } = useMyTalentDrafts.getState();
    const talentDraft = talentDrafts.length ? talentDrafts[0] : undefined;
    const isWaitlistAccepted =
      user?.waitlistStatus === "ACCEPTED" ||
      !!user?.managedTalentIds?.length ||
      !!useLocalStorage.getState().data.referralTalentTag;

    set({ isWaitlist: false });

    if (isInitializing) {
      return set({ step: undefined });
    }

    if (user?.managedTalentIds?.length) {
      return set({ step: undefined });
    }

    if (!isWaitlistAccepted) {
      return set({ isWaitlist: true, step: undefined });
    }

    if (!talentDraft) {
      return set({ step: "TALENT_NAME" });
    }

    if (talentDraft.profilePictureUrl === undefined) {
      return set({ step: "TALENT_PROFILE_PICTURE" });
    }

    if (!talentDraft.tag) {
      return set({ step: "TALENT_TAG" });
    }

    if (!talentDraft.gender || !talentDraft.age) {
      return set({ step: "TALENT_GENDER_AGE" });
    }

    if (!talentDraft.purpose?.length) {
      return set({ step: "TALENT_PURPOSE" });
    }

    if (!talentDraft.personalityTraits?.length) {
      return set({ step: "TALENT_PERSONALITY_TRAITS" });
    }

    if (!talentDraft.interests?.length) {
      return set({ step: "TALENT_INTERESTS" });
    }

    return set({ step: ONBOARDING_STEPS[ONBOARDING_STEPS.length - 1] });
  },
}));
