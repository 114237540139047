import { FC } from "react";
import { OnboardingStep } from "../types/OnboardingStep";
import { OnboardingTalentGenderAgePage } from "../views/OnboardingTalentGenderAgePage";
import { OnboardingTalentInitialChatMessagePage } from "../views/OnboardingTalentInitialChatMessagePage";
import { OnboardingTalentInterestsPage } from "../views/OnboardingTalentInterestsPage";
import { OnboardingTalentNamePage } from "../views/OnboardingTalentNamePage";
import { OnboardingTalentPersonalityTraitsPage } from "../views/OnboardingTalentPersonalityTraitsPage";
import { OnboardingTalentProfilePicturePage } from "../views/OnboardingTalentProfilePicturePage";
import { OnboardingTalentPurposePage } from "../views/OnboardingTalentPurposePage";
import { OnboardingTalentTagPage } from "../views/OnboardingTalentTagPage";

interface RecordValue {
  path: string;
  Page: FC;
}

export const ONBOARDING_STEP_RECORD: Record<OnboardingStep, RecordValue> = {
  TALENT_NAME: {
    path: "name",
    Page: OnboardingTalentNamePage,
  },
  TALENT_PROFILE_PICTURE: {
    path: "profile-picture",
    Page: OnboardingTalentProfilePicturePage,
  },
  TALENT_TAG: {
    path: "handle",
    Page: OnboardingTalentTagPage,
  },
  TALENT_GENDER_AGE: {
    path: "gender-age",
    Page: OnboardingTalentGenderAgePage,
  },
  TALENT_PURPOSE: {
    path: "purpose",
    Page: OnboardingTalentPurposePage,
  },
  TALENT_PERSONALITY_TRAITS: {
    path: "personality-traits",
    Page: OnboardingTalentPersonalityTraitsPage,
  },
  TALENT_INTERESTS: {
    path: "interests",
    Page: OnboardingTalentInterestsPage,
  },
  TALENT_INITIAL_CHAT_MESSAGE: {
    path: "initial-chat-message",
    Page: OnboardingTalentInitialChatMessagePage,
  },
};
