import { create } from "zustand";

interface MainInitState {
  error?: Error;
  isInitializing: boolean;
}

export const useMainInit = create<MainInitState>(() => ({
  error: undefined,
  isInitializing: true,
}));
