import { Button, Paper, Stack, Typography } from "@mui/material";
import { Talent } from "@super-real/types";
import { FC, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { createFaqCallable } from "../../Faq/callables/createFaqCallable";
import { useFaqs } from "../../Faq/hooks/useFaqs";
import { FaqForm } from "../../Faq/views/FaqForm";

interface Props {
  talent: Talent;
}

export const TalentFaqView: FC<Props> = (props) => {
  const { talent } = props;
  const [faqs, isLoading, error] = useFaqs([talent.id]);
  const [isCreatingFaq, setIsCreatingFaq] = useState(false);

  const isLoaded = !isLoading && !error;

  return (
    <Stack spacing={3}>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {isLoaded && (
        <>
          {!!faqs.length && <FaqForm faq={faqs[0]} talent={talent} />}
          {!faqs.length && (
            <Stack spacing={3} p={2} component={Paper}>
              <Typography variant="ah3">Create FAQ</Typography>
              <Button
                size="large"
                variant="contained"
                onClick={async () => {
                  try {
                    setIsCreatingFaq(true);
                    await createFaqCallable({
                      name: talent.name,
                      talentId: talent.id,
                    });
                  } catch (error) {
                    handleError(error);
                  } finally {
                    setIsCreatingFaq(false);
                  }
                }}
                sx={{ alignSelf: "flex-start" }}
                disabled={isCreatingFaq}
              >
                Create FAQ for {talent.name}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
