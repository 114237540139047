import { EarningFrame } from "@super-real/types";
import { Timestamp } from "firebase/firestore";
import { DateTime, Interval } from "luxon";
import { toTotalEarnings } from "../../Creator/helpers/toTotalEarnings";
import { normalizeEarningFramesByDay } from "./normalizeEarningFramesByDay";

interface Params {
  earningFramesByMonth: EarningFrame[];
  earningFramesByDay: EarningFrame[];
}

export function normalizeEarningFramesByMonth(params: Params): EarningFrame[] {
  const { earningFramesByMonth, earningFramesByDay } = params;
  const earliestStartedAtByDay = earliestStartedAt(earningFramesByDay);
  const earliestStartedAtByMonth = earliestStartedAt(earningFramesByMonth);

  const startOfTodaysMonth = DateTime.local().startOf("month");
  const endOfTodaysMonth = startOfTodaysMonth.endOf("month");

  const fromDate =
    earliestStartedAtByMonth?.startOf("month") ||
    earliestStartedAtByDay?.startOf("month") ||
    startOfTodaysMonth;
  const toDate = endOfTodaysMonth;

  return Interval.fromDateTimes(fromDate, toDate)
    .splitBy({ months: 1 })
    .map<EarningFrame>((interval) => {
      const monthStartedAt = interval.start || DateTime.local();
      const monthEndedAt = monthStartedAt.endOf("month");
      const monthId = monthStartedAt.toFormat("yyyy-MM");

      const earningFrameOfMonth = earningFramesByMonth.find(
        (earningFrameOfMonth) => earningFrameOfMonth.id === monthId
      );

      const earningFramesOfMonthByDay = earningFramesByDay.filter(
        (earningFrameOfDay) => earningFrameOfDay.id.startsWith(monthId)
      );

      const childEarningFramesOfMonth = normalizeEarningFramesByDay({
        earningFrames: [
          ...(earningFrameOfMonth?.childEarningFrames || []),
          ...earningFramesOfMonthByDay,
        ],
        fromDate: monthStartedAt,
        toDate: monthEndedAt,
      });

      const totalEarningsOfMonth = toTotalEarnings(childEarningFramesOfMonth);

      return {
        ...earningFrameOfMonth,
        ...totalEarningsOfMonth,
        id: monthId,
        range: "MONTH",
        currency: "USD",
        talentId: "INVALID_TALENT_ID",
        childEarningFrames: childEarningFramesOfMonth,
        startedAt: Timestamp.fromDate(monthStartedAt.toJSDate()),
        endedAt:
          earningFramesOfMonthByDay[0]?.endedAt ||
          earningFrameOfMonth?.endedAt ||
          Timestamp.fromDate(monthEndedAt.toJSDate()),
        updatedAt: Timestamp.fromDate(monthStartedAt.toJSDate()),
        createdAt: Timestamp.fromDate(monthStartedAt.toJSDate()),
      } satisfies EarningFrame;
    })
    .sort((a, b) => b.startedAt.toMillis() - a.startedAt.toMillis());
}

function earliestStartedAt(earningFrames: EarningFrame[]) {
  if (!earningFrames.length) return undefined;
  return DateTime.fromJSDate(
    earningFrames[earningFrames.length - 1].startedAt.toDate()
  );
}
