import { usePrevious } from "./usePrevious";

/**
 * Needed if we query 2 docs/collections in a row
 * but the second query depends on the first one.
 *
 * E.g.:
 * ```ts
 * const [foo, isLoadingFoo] = useFoo();
 * const [bar, isLoadingBar] = useBar(foo);
 * const isLoadingNextTick = useIsLoadingNextTick(!isLoadingFoo);
 * const isLoading = isLoadingFoo || isLoadingBar || isLoadingNextTick;
 * ```
 */
export const useIsLoadingNextTick = (isNextStepQueryable: boolean) => {
  const previousIsNextStepQueryable = usePrevious(isNextStepQueryable);

  if (isNextStepQueryable && !previousIsNextStepQueryable) {
    return true;
  }

  return false;
};
