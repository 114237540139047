import { Gender } from "@super-real/types";

interface RecordValue {
  label: string;
}

export const TALENT_GENDER_RECORD: Record<Gender, RecordValue> = {
  MALE: {
    label: "🙋‍♂️ Male",
  },
  FEMALE: {
    label: "🙋‍♀️ Female",
  },
  NON_BINARY: {
    label: "🤠 Non-binary",
  },
};
