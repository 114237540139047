import { isPayout } from "@super-real/types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function usePayout(payoutId?: string) {
  return useValidDocumentData(
    payoutId ? getDocRef("payouts", payoutId) : null,
    isPayout
  );
}
