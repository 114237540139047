import confetti from "canvas-confetti";

export const showConfetti = () => {
  void confetti({
    particleCount: 100,
    spread: 100,
    origin: { y: 0.45 },
    zIndex: 10000,
  });
};
