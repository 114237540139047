import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { ErrorView } from "../../Common/views/ErrorView";
import { useEarningFramesByMonth } from "../hooks/useEarningFramesByMonth";
import { CreatorEarningFrameAccordion } from "./CreatorEarningFrameAccordion";

interface Props {
  talentId: string;
}

export const CreatorEarningFrameMonthsView: FC<Props> = (props) => {
  const { talentId } = props;
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(0);
  const [earningFrames, isLoading, error] = useEarningFramesByMonth(talentId);
  const isLoaded = !isLoading && !error;

  return (
    <Stack spacing={3}>
      {isLoading && (
        <CreatorEarningFrameAccordion
          isExpanded={expandedIndex === 0}
          expand={() => setExpandedIndex(0)}
          close={() => setExpandedIndex(undefined)}
        />
      )}
      {error && <ErrorView error={error} />}
      {isLoaded && (
        <Stack spacing={1}>
          {earningFrames.map((earningFrame, index) => (
            <CreatorEarningFrameAccordion
              key={earningFrame.id}
              earningFrame={earningFrame}
              isExpanded={expandedIndex === index}
              expand={() => setExpandedIndex(index)}
              close={() => setExpandedIndex(undefined)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
