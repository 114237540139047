import { Skeleton, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CannyFeedback, CannyProvider } from "react-canny";
import { handleError } from "../../Common/helpers/handleError";
import { ErrorView } from "../../Common/views/ErrorView";
import { createCannyTokenCallable } from "../callables/createCannyTokenCallable";

const APP_ID = "653d7a629e144fc8bc86ac80";
const BOARD_TOKEN = "4f003c8a-8b1c-ad0d-20d1-4911b2a3905b";

export const CreatorFeedbackPage: FC = () => {
  const [cannyToken, setCannyToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    createCannyTokenCallable({})
      .then(({ cannyToken }) => setCannyToken(cannyToken))
      .catch((error) => {
        const message =
          (error as Error)?.message ||
          "An error has occured, please try again later.";
        setError(message);
        handleError(error);
      });
  }, []);

  return (
    <Stack px={2} py={3} spacing={2}>
      <Typography variant="ah3" color="text.secondary">
        🚀 Feature Requests
      </Typography>

      {error && <ErrorView error={new Error(error)} />}
      {!error && (
        <CannyProvider appId={APP_ID}>
          <Stack spacing={1}>
            {isLoading && (
              <Stack spacing={2}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton key={index} variant="rounded" height={70} />
                ))}
              </Stack>
            )}
            {cannyToken && (
              <CannyFeedback
                basePath="/feedback"
                boardToken={BOARD_TOKEN}
                ssoToken={cannyToken}
                onLoadCallback={() => setIsLoading(false)}
              />
            )}
          </Stack>
        </CannyProvider>
      )}
    </Stack>
  );
};
