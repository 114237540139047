import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useMyTalentConfigs } from "../../../Talent/hooks/useMyTalentConfigs";
import { useMyTalents } from "../../../Talent/hooks/useMyTalents";
import { CreatorSettingsProfileForm } from "./CreatorSettingsProfileForm";
import { CreatorSettingsProfilePictureInput } from "./CreatorSettingsProfilePictureInput";

export const CreatorSettingsProfilePage: FC = () => {
  const [talent] = useMyTalents((state) => state.talents);
  const [talentConfig] = useMyTalentConfigs((state) => state.talentConfigs);

  return (
    <Stack px={2} py={3} spacing={2}>
      <Typography variant="ah1" color="text.secondary">
        😎 Profile
      </Typography>
      <CreatorSettingsProfilePictureInput talent={talent} />

      {talent && talentConfig && (
        <CreatorSettingsProfileForm
          talent={talent}
          talentConfig={talentConfig}
        />
      )}
    </Stack>
  );
};
