import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { adminCreatePublicTalentCallable } from "../callables/adminCreatePublicTalentCallable";

const FormValues = z.object({
  talentId: z.string().nonempty(),
});

export type FormValues = z.infer<typeof FormValues>;

export const PublicTalentCreatePage: FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      talentId: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await adminCreatePublicTalentCallable({
          talentId: formValues.talentId,
        });
        navigate(`/public-talents/${formValues.talentId}`);
        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [navigate]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Typography variant="ah2">Create Public Talent</Typography>
      <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          label="Talent ID"
          name="talentId"
          autoComplete="off"
        />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ alignSelf: "flex-start" }}
          disabled={isLoading}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
};
