import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AppConfig,
  CURRENT_TALENT_INTERESTS,
  CurrentTalentInterest,
  ELEVEN_LABS_MODELS,
  Faq,
  GENDERS,
  Model,
  Product,
  SYSTEM_MESSAGE_PLACEHOLDERS,
  Talent,
  TALENT_INTERESTS,
  TALENT_PERSONALITY_TRAITS,
  TALENT_PURPOSES,
  TalentConfig,
} from "@super-real/types";
import { LLM_TYPES } from "@super-real/types/types/LlmType";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../../Common/helpers/toLocaleDateTimeString";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { AccordionView } from "../../../Common/views/AccordionView";
import { FileInput } from "../../../Common/views/FileInput";
import { ControlledSelect } from "../../../Form/views/ControlledSelect";
import { ControlledSwitch } from "../../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { useModelVersions } from "../../../Model/hooks/useModelVersions";
import { adminUpdateTalentCallable } from "../../callables/adminUpdateTalentCallable";
import { DEFAULT_ELEVENLABS_VOICE_CONFIG } from "../../consts/DEFAULT_ELEVENLABS_VOICE_CONFIG";
import { TALENT_INTEREST_RECORD } from "../../consts/TALENT_INTEREST_RECORD";
import { TALENT_PERSONALITY_TRAIT_RECORD } from "../../consts/TALENT_PERSONALITY_TRAIT_RECORD";
import { TALENT_PURPOSE_RECORD } from "../../consts/TALENT_PURPOSE_RECORD";
import { useTalentConfigs } from "../../hooks/useTalentConfigs";
import { TalentChip } from "../TalentChip";
import { AttachmentFormFields } from "./AttachmentFormFields";
import { FilePreview } from "./FilePreview";
import { FormValues } from "./FormValues";

interface Props {
  talent: Talent;
  talentConfig: TalentConfig;
  models: Model[];
  faqs: Faq[];
  appConfig: AppConfig;
  products: Product[];
}

export const TalentForm: FC<Props> = (props) => {
  const { talent, talentConfig, models, faqs, appConfig, products } = props;
  const [referralTalentConfigs] = useTalentConfigs([
    ["referralTalentId", "==", talent.id],
  ]);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: talent.name,
      tag: talent.tag,
      profilePictureUrl: talent.profilePictureUrl,
      gender: talent.gender,
      age: talent.age,
      voice: talentConfig.voice || {
        id: "",
        stability: DEFAULT_ELEVENLABS_VOICE_CONFIG.stability,
        similarityBoost: DEFAULT_ELEVENLABS_VOICE_CONFIG.similarityBoost,
        style: DEFAULT_ELEVENLABS_VOICE_CONFIG.style,
        model: DEFAULT_ELEVENLABS_VOICE_CONFIG.model,
        gender: talent.gender,
        name: talent.name,
      },
      creditsToSendMessage: talent.creditsToSendMessage,
      creditsToUnlockImage: talent.creditsToUnlockImage,
      creditsToUnlockAudio: talent.creditsToUnlockAudio,
      creditsToUnlockFirstImage: talent.creditsToUnlockFirstImage || 0,
      isCreditsToUnlockFirstImageEnabled:
        talent.creditsToUnlockFirstImage !== undefined,
      revenueShare: talentConfig.revenueShare,
      purpose: talentConfig.purpose || "FLIRT",
      interests: talentConfig.interests || [],
      personalityTraits: talentConfig.personalityTraits || [],
      isAdult: talent.isAdult,
      hasCustomChatPrompt: !!talentConfig.hasCustomChatPrompt,
      chatPrompt: talentConfig.chatPrompt,
      facts: talentConfig.facts,
      chatExampleCount: talentConfig.chatExampleCount,
      chatExamples: talentConfig.chatExamples,
      initialChatMessages: talent.initialChatMessages,
      staticAttachments: talentConfig.staticAttachments,
      llmType: talentConfig.llmType,
      llmTemperature: talentConfig.llmTemperature,
      llmModelId: talentConfig.llmModelId || "",
      llmFineTunedModelId: talentConfig.llmFineTunedModelId || "",
      faqId: talentConfig.faqId || "",
      subscriptionProductId: talent.subscriptionProductId || "",
      chatMessageForAuth: talent.chatMessageForAuth || "",
      chatMessageForCheckout: talent.chatMessageForCheckout || "",
      chatMessageForSubscription: talent.chatMessageForSubscription || "",
      chatMessageSuggestions:
        talent.chatMessageSuggestions?.map((s) => ({
          id: s.id,
          text: s.text,
          answer: s.answer || "",
        })) || [],
      isChatMessageSuggestionsAutoAnswerEnabled:
        !talent.chatMessageSuggestions?.some((s) => s.answer),
      referralRevenueShareForInvitees:
        talentConfig.referralRevenueShareForInvitees ||
        appConfig.referralRevenueShare,
    },
  });

  const { control, handleSubmit, formState, watch, setValue } = form;

  const llmType = watch("llmType");
  const llmModelId = watch("llmModelId");
  const hasCustomChatPrompt = watch("hasCustomChatPrompt");
  const isCreditsToUnlockFirstImageEnabled = watch(
    "isCreditsToUnlockFirstImageEnabled"
  );
  const isChatMessageSuggestionsAutoAnswerEnabled = watch(
    "isChatMessageSuggestionsAutoAnswerEnabled"
  );

  const [versions, isLoadingVersions] = useModelVersions(llmModelId);

  const chatExamples = useFieldArray({
    control,
    name: "chatExamples",
  });

  const initialChatMessages = useFieldArray({
    control,
    name: "initialChatMessages",
  });

  const chatMessageSuggestions = useFieldArray({
    control,
    name: "chatMessageSuggestions",
  });

  const staticAttachments = useFieldArray({
    control,
    name: "staticAttachments",
  });

  const [uploadFiles, isUploadingFiles] = useUploadFiles({
    ownerId: talent.id,
    ownerType: "TALENT",
    onFileUploaded: (uploadedFile, originalFile) => {
      switch (uploadedFile.type) {
        case "IMAGE":
          staticAttachments.append({
            id: uploadedFile.id,
            type: "IMAGE",
            originalFileName: originalFile.name,
            filePath: uploadedFile.filePath,
            lockedFilePath: uploadedFile.lockedFilePath,
            dimensions: uploadedFile.dimensions,
            context: "",
          });
          break;
        case "AUDIO":
          staticAttachments.append({
            id: uploadedFile.id,
            type: "AUDIO",
            originalFileName: originalFile.name,
            filePath: uploadedFile.filePath,
            context: "",
          });
          break;
      }
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await adminUpdateTalentCallable({
          talentId: talent.id,
          name: formValues.name,
          tag: formValues.tag,
          profilePictureUrl: formValues.profilePictureUrl,
          gender: formValues.gender,
          age: formValues.age,
          voice: formValues.voice,
          creditsToSendMessage: formValues.creditsToSendMessage,
          creditsToUnlockImage: formValues.creditsToUnlockImage,
          creditsToUnlockAudio: formValues.creditsToUnlockAudio,
          creditsToUnlockFirstImage:
            formValues.isCreditsToUnlockFirstImageEnabled
              ? formValues.creditsToUnlockFirstImage
              : null,
          revenueShare: formValues.revenueShare,
          isAdult: formValues.isAdult,
          hasCustomChatPrompt: formValues.hasCustomChatPrompt,
          chatPrompt: formValues.chatPrompt,
          facts: formValues.facts,
          purpose: formValues.purpose,
          interests: formValues.interests,
          personalityTraits: formValues.personalityTraits,
          chatExampleCount: formValues.chatExampleCount,
          chatExamples: formValues.chatExamples,
          initialChatMessages: formValues.initialChatMessages,
          staticAttachments: formValues.staticAttachments,
          llmType: formValues.llmType,
          llmTemperature: formValues.llmTemperature,
          llmModelId: formValues.llmModelId,
          llmFineTunedModelId: formValues.llmFineTunedModelId,
          faqId: formValues.faqId,
          subscriptionProductId: formValues.subscriptionProductId,
          chatMessageForAuth: formValues.chatMessageForAuth,
          chatMessageForCheckout: formValues.chatMessageForCheckout,
          chatMessageForSubscription: formValues.chatMessageForSubscription,
          chatMessageSuggestions: formValues.chatMessageSuggestions.map(
            (s) => ({
              id: s.id,
              text: s.text,
              ...(!formValues.isChatMessageSuggestionsAutoAnswerEnabled &&
                s.answer && {
                  answer: s.answer,
                }),
            })
          ),
          referralRevenueShareForInvitees:
            formValues.referralRevenueShareForInvitees,
        });
        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [talent.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <AccordionView title="Settings">
        <Stack spacing={2}>
          <ControlledTextField
            control={control}
            label="Name"
            name="name"
            autoComplete="off"
            disabled={isLoading}
          />
          <ControlledTextField
            control={control}
            label="Handle"
            name="tag"
            autoComplete="off"
            disabled={isLoading}
          />
          <ControlledTextField
            control={control}
            label="Profile Picture URL"
            name="profilePictureUrl"
            autoComplete="off"
            disabled={isLoading}
          />
          <Stack direction="row" spacing={2}>
            <ControlledSelect
              control={control}
              label="Gender"
              name="gender"
              items={GENDERS.map((gender) => ({
                label: gender,
                value: gender,
              }))}
              renderItem={(item) => item.value}
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              label="Age"
              name="age"
              type="number"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
          </Stack>
          <ControlledTextField
            control={control}
            label="Chat Message for Sign Up"
            name="chatMessageForAuth"
            autoComplete="off"
            disabled={isLoading}
          />
          <ControlledTextField
            control={control}
            label="Chat Message to buy Credits"
            name="chatMessageForCheckout"
            autoComplete="off"
            disabled={isLoading}
          />
          <ControlledTextField
            control={control}
            label="Chat Message to buy Subscription"
            name="chatMessageForSubscription"
            autoComplete="off"
            disabled={isLoading}
          />
        </Stack>
      </AccordionView>

      <AccordionView title="Monetisation">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ControlledTextField
              control={control}
              label="Credits to Send Message"
              name="creditsToSendMessage"
              type="number"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              label="Credits to Unlock Audio"
              name="creditsToUnlockAudio"
              type="number"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ControlledTextField
              control={control}
              label="Credits to Unlock Image"
              name="creditsToUnlockImage"
              type="number"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              label="Credits to Unlock First Image"
              name="creditsToUnlockFirstImage"
              type="number"
              autoComplete="off"
              disabled={isLoading || !isCreditsToUnlockFirstImageEnabled}
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <ControlledSwitch
                    control={control}
                    name="isCreditsToUnlockFirstImageEnabled"
                    disabled={isLoading}
                  />
                </InputAdornment>
              }
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ControlledSelect
              control={control}
              label="Subscription Product"
              name="subscriptionProductId"
              emptyLabel="None"
              items={products
                .filter((p) => p.priceType === "SUBSCRIPTION")
                .map((product) => ({
                  label: product.name,
                  value: product.id,
                }))}
              renderItem={(item) => item.label}
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              label="Revenue Share"
              name="revenueShare"
              type="number"
              inputProps={{ step: 0.1, min: 0, max: 1 }}
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
          </Stack>
        </Stack>
      </AccordionView>

      <AccordionView title="Referrals">
        <Stack direction="row" spacing={2}>
          <Stack flex={1} spacing={2}>
            <Typography variant="ah6">Inviter</Typography>
            <TextField
              label="Revenue Share"
              value={
                talentConfig.referralTalentId
                  ? talentConfig.referralRevenueShareForInviter ||
                    `${appConfig.referralRevenueShare} (default)`
                  : "No Referral"
              }
              fullWidth
              disabled
            />
            <Stack spacing={0.5} alignItems="flex-start">
              {talentConfig.referralTalentId && (
                <TalentChip talentId={talentConfig.referralTalentId} />
              )}
            </Stack>
          </Stack>
          <Stack flex={1} spacing={2}>
            <Typography variant="ah6">Invitees</Typography>
            <ControlledTextField
              control={control}
              label="Revenue Share"
              name="referralRevenueShareForInvitees"
              type="number"
              inputProps={{ step: 0.001, min: 0, max: 1 }}
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <Stack spacing={0.5} alignItems="flex-start">
              {referralTalentConfigs.map((talentConfig) => (
                <TalentChip key={talentConfig.id} talentId={talentConfig.id} />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </AccordionView>

      <AccordionView title="Voice">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ControlledTextField
              control={control}
              label="Voice ID"
              name="voice.id"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <ControlledSelect
              control={control}
              label="Model"
              name="voice.model"
              items={ELEVEN_LABS_MODELS.map((model) => ({
                label: model,
                value: model,
              }))}
              renderItem={(item) => item.value}
              disabled={isLoading}
              fullWidth
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ControlledTextField
              control={control}
              inputProps={{ step: 0.1, min: 0, max: 1 }}
              type="number"
              label="Stability"
              name="voice.stability"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              inputProps={{ step: 0.1, min: 0, max: 1 }}
              type="number"
              label="Similarity Boost"
              name="voice.similarityBoost"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              inputProps={{ step: 0.1, min: 0, max: 1 }}
              type="number"
              label="Style"
              name="voice.style"
              autoComplete="off"
              disabled={isLoading}
              fullWidth
            />
          </Stack>
        </Stack>
      </AccordionView>

      <AccordionView title="Model">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ControlledSelect
              control={control}
              label="Model"
              name="llmType"
              items={LLM_TYPES.map((model) => ({
                label: model,
                value: model,
              }))}
              renderItem={(item) => item.value}
              disabled={isLoading}
              fullWidth
            />
            <ControlledTextField
              control={control}
              label="Temperature"
              name="llmTemperature"
              autoComplete="off"
              inputProps={{ step: 0.01 }}
              type="number"
              disabled={isLoading}
              fullWidth
            />
          </Stack>
          {llmType === "fine-tuned" && (
            <Stack direction="row" spacing={2}>
              <Stack flex={1}>
                <ControlledSelect
                  control={control}
                  label="Custom Model"
                  name="llmModelId"
                  items={models
                    .filter((m) => !!m.defaultFineTunedModelId)
                    .map((model) => ({
                      label: model.name,
                      value: model.id,
                    }))}
                  renderItem={(item) => item.label}
                  onChange={() => setValue("llmFineTunedModelId", "")}
                  disabled={isLoading}
                  fullWidth
                />
              </Stack>
              <Stack flex={1}>
                {isLoadingVersions && <CircularProgress />}
                {!isLoadingVersions && !!versions.length && (
                  <ControlledSelect
                    control={control}
                    label="Version"
                    name="llmFineTunedModelId"
                    emptyLabel="Default Version"
                    items={versions
                      .filter((v) => !!v.fineTunedModelId)
                      .map((version) => ({
                        label: [
                          version.base,
                          toLocaleDateTimeString(version.createdAt),
                        ].join(" · "),
                        value: version.fineTunedModelId || "",
                      }))}
                    renderItem={(item) => item.label}
                    disabled={isLoading || isLoadingVersions || !llmModelId}
                    fullWidth
                  />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </AccordionView>

      <AccordionView title="Prompts">
        <Stack spacing={2}>
          <ControlledSwitch
            control={control}
            label="Has Custom Chat Prompt"
            name="hasCustomChatPrompt"
            disabled={isLoading}
          />
          {hasCustomChatPrompt && (
            <Stack spacing={2}>
              <ControlledTextField
                control={control}
                label="Default Chat Prompt"
                name="chatPrompt"
                autoComplete="off"
                disabled={isLoading}
                minRows={3}
                multiline
              />
              <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={1}>
                {SYSTEM_MESSAGE_PLACEHOLDERS.filter((placeholder) =>
                  placeholder.startsWith("talent")
                ).map((placeholder) => (
                  <Chip
                    key={placeholder}
                    label={`{${placeholder}}`}
                    size="small"
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {!hasCustomChatPrompt && (
            <>
              <ControlledSelect
                control={control}
                name="purpose"
                label="Purpose"
                items={TALENT_PURPOSES.map((purpose) => ({
                  label: TALENT_PURPOSE_RECORD[purpose].label,
                  value: purpose,
                }))}
                renderItem={(item) => item.label}
                disabled={isLoading}
                fullWidth
              />
              <ControlledSelect
                control={control}
                name="personalityTraits"
                label="Personality Traits"
                items={TALENT_PERSONALITY_TRAITS.map((trait) => ({
                  label: TALENT_PERSONALITY_TRAIT_RECORD[trait].label,
                  value: trait,
                }))}
                renderItem={(item) => item.label}
                disabled={isLoading}
                multiple
                fullWidth
              />
              <ControlledSelect
                control={control}
                name="interests"
                label="Interests"
                items={TALENT_INTERESTS.map((interest) => ({
                  label: `${TALENT_INTEREST_RECORD[interest].label}${
                    CURRENT_TALENT_INTERESTS.includes(
                      interest as CurrentTalentInterest
                    )
                      ? ""
                      : " (deprecated)"
                  }`,
                  value: interest,
                }))}
                renderItem={(item) => item.label}
                disabled={isLoading}
                multiple
                fullWidth
              />
            </>
          )}
        </Stack>
      </AccordionView>

      <AccordionView title="Facts">
        <Stack spacing={2}>
          <ControlledTextField
            control={control}
            label="Facts"
            name="facts"
            autoComplete="off"
            disabled={isLoading}
            minRows={3}
            maxRows={15}
            multiline
          />
        </Stack>
      </AccordionView>

      <AccordionView title="FAQ">
        <Stack spacing={2}>
          <ControlledSelect
            control={control}
            label="FAQ"
            name="faqId"
            items={faqs.map((faq) => ({
              label: faq.name,
              value: faq.id,
            }))}
            renderItem={(item) => item.label}
            emptyLabel="None"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
      </AccordionView>

      <AccordionView title="Chat Examples">
        <Stack spacing={2}>
          {llmType !== "fine-tuned" && (
            <ControlledTextField
              control={control}
              label="Amount of Chat Examples within the Chat Prompt"
              name="chatExampleCount"
              autoComplete="off"
              type="number"
              disabled={isLoading}
              fullWidth
            />
          )}
          <Stack spacing={2}>
            {chatExamples.fields.map((field, index) => (
              <Stack key={field.id} spacing={2} direction="row">
                <ControlledTextField
                  control={control}
                  label="Request"
                  name={`chatExamples.${index}.requestText`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                />
                <ControlledTextField
                  control={control}
                  label="Response"
                  name={`chatExamples.${index}.responseText`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                />
                <Stack justifyContent="center">
                  <IconButton
                    color="error"
                    onClick={() => chatExamples.remove(index)}
                    disabled={isLoading}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
            <Button
              onClick={() =>
                chatExamples.append({ requestText: "", responseText: "" })
              }
              sx={{ alignSelf: "flex-start" }}
              disabled={isLoading}
            >
              Add Chat Example
            </Button>
          </Stack>
        </Stack>
      </AccordionView>

      <AccordionView title="Welcome Messages">
        <Stack spacing={2}>
          {initialChatMessages.fields.map((field, index) => (
            <Fragment key={field.id}>
              <ControlledTextField
                control={control}
                label="Message"
                name={`initialChatMessages.${index}.text`}
                autoComplete="off"
                disabled={isLoading}
                endAdornment={
                  <IconButton
                    color="error"
                    onClick={() => initialChatMessages.remove(index)}
                    disabled={isLoading}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                }
                fullWidth
              />

              <AttachmentFormFields
                control={control}
                index={index}
                isLoading={isLoading}
                talentId={talent.id}
              />
            </Fragment>
          ))}
          <Button
            onClick={() =>
              initialChatMessages.append({
                id: v4(),
                text: "",
              })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            Add Initial Chat Message
          </Button>
        </Stack>
      </AccordionView>

      <AccordionView title="Conversation Starters">
        <Stack spacing={2}>
          <Stack pb={2}>
            <ControlledSwitch
              control={control}
              label="Answered by AI"
              name="isChatMessageSuggestionsAutoAnswerEnabled"
              disabled={isLoading}
            />
          </Stack>
          <Stack spacing={isChatMessageSuggestionsAutoAnswerEnabled ? 2 : 6}>
            {chatMessageSuggestions.fields.map((field, index) => (
              <Stack key={field.id} spacing={2}>
                <ControlledTextField
                  control={control}
                  label={`Question ${index + 1}`}
                  name={`chatMessageSuggestions.${index}.text`}
                  autoComplete="off"
                  disabled={isLoading}
                  endAdornment={
                    <IconButton
                      color="error"
                      onClick={() => chatMessageSuggestions.remove(index)}
                      disabled={isLoading}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  }
                  fullWidth
                />
                {!isChatMessageSuggestionsAutoAnswerEnabled && (
                  <>
                    <ControlledTextField
                      control={control}
                      label={`Static Answer ${index + 1}`}
                      name={`chatMessageSuggestions.${index}.answer`}
                      autoComplete="off"
                      disabled={isLoading}
                      fullWidth
                    />
                  </>
                )}
              </Stack>
            ))}
          </Stack>
          <Button
            onClick={() =>
              chatMessageSuggestions.append({ id: v4(), text: "", answer: "" })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            Add Suggestion
          </Button>
        </Stack>
      </AccordionView>

      <AccordionView title="Image Gallery">
        <Stack spacing={2}>
          {staticAttachments.fields.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={2}>
              <Stack width={56} height={56}>
                <FilePreview filePath={field.filePath} type={field.type} />
              </Stack>
              <ControlledTextField
                control={control}
                name={`staticAttachments.${index}.context`}
                label="Context"
                autoComplete="off"
                disabled={isLoading}
                fullWidth
              />
              <Stack justifyContent="center">
                <IconButton
                  color="error"
                  onClick={() => staticAttachments.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          <FileInput
            disabled={isUploadingFiles}
            onSelect={uploadFiles}
            accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3"
            multiple
          >
            <Button variant="text" disabled={isUploadingFiles}>
              {isUploadingFiles && "Uploading... "}
              {!isUploadingFiles && "Add Static Attachments"}
            </Button>
          </FileInput>
        </Stack>
      </AccordionView>

      <Stack direction="row" alignItems="self-start" mt={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
