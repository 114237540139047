import { alpha, styled } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { MAIN_PALETTE } from "../consts/MAIN_PALETTE";
import { MAIN_THEME } from "../consts/MAIN_THEME";

const PINK = "#FA1CA1";
const YELLOW = MAIN_PALETTE.secondary.main;

export const MainBackgroundGradientView: FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const draw = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      if (!context) return;

      // set canvas size
      canvas.width = window.document.body.offsetWidth;
      canvas.height = window.document.body.scrollHeight;
      context.clearRect(0, 0, canvas.width, canvas.height);

      // pink gradient
      const primaryGradient = context.createRadialGradient(
        (canvas.width + MAIN_THEME.breakpoints.values.sm) / 2, // x1
        140, // y1
        0, // r1
        (canvas.width + MAIN_THEME.breakpoints.values.sm) / 2, // x2
        140, // y2
        320 // r1
      );
      primaryGradient.addColorStop(0, alpha(PINK, 0.3));
      primaryGradient.addColorStop(1, alpha(PINK, 0));
      context.fillStyle = primaryGradient;
      context.fillRect(0, 0, canvas.width, canvas.height);

      // yellow gradient
      const secondaryGradient = context.createRadialGradient(
        (canvas.width + MAIN_THEME.breakpoints.values.sm) / 6, // x1
        300, // y1
        0, // r1
        (canvas.width + MAIN_THEME.breakpoints.values.sm) / 6, // x2
        300, // y2
        320 // r2
      );
      secondaryGradient.addColorStop(0, alpha(YELLOW, 0.2));
      secondaryGradient.addColorStop(1, alpha(YELLOW, 0));
      context.fillStyle = secondaryGradient;
      context.fillRect(0, 0, canvas.width, canvas.height);
    };

    window.addEventListener("resize", draw);
    draw();
    return () => {
      window.removeEventListener("resize", draw);
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

const Canvas = styled("canvas")(() => ({
  display: "block",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
}));
