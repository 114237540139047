import { SocialPlatform } from "@super-real/types";

interface RecordValue {
  label: string;
}

export const ONBOARDING_WAITLIST_RECORD: Record<SocialPlatform, RecordValue> = {
  INSTAGRAM: {
    label: "Instagram",
  },
  TIKTOK: {
    label: "TikTok",
  },
  X: {
    label: "𝕏",
  },
};
