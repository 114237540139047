import { PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    gradient: string;
  }
}

export const MAIN_PALETTE = {
  mode: "light",
  background: {
    default: "#FFFFFF",
    paper: "#F6F6F6",
    gradient: "linear-gradient(133deg, #FFD839 -26%, #FF81F7 99%)",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  divider: "#EEEEEE",
  text: {
    primary: "#162E52",
    secondary: "#596576",
  },
  primary: {
    main: "#5465FF",
  },
  secondary: {
    main: "#FAD81C",
  },
  success: {
    main: "#44AC5B",
    contrastText: "#FFFFFF",
  },
} satisfies PaletteOptions;
