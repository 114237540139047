import {
  endAt,
  FirestoreError,
  getDocs,
  limit,
  onSnapshot,
  Query,
  query,
  QueryDocumentSnapshot,
  queryEqual,
  QuerySnapshot,
  startAfter,
  Unsubscribe,
} from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const DEFAULT_PAGE_SIZE = 50;

interface PaginationController {
  hasMore: boolean;
  loadMore: () => void;
}

/**
 * =============|===============
 *              | Subscription 3 (n items)
 *              |===============
 * Old Messages | Subscription 2 (n items)
 *              |===============
 *              |
 * =============| Subscription 1 (n + new items)
 * New Messages |
 * =============|===============
 */
export const usePaginatedCollection = <T>(
  nextQuery: Query | null | undefined,
  isT: (obj: unknown) => obj is T,
  options?: { pageSize: number }
): [
  items: T[],
  isLoading: boolean,
  error: FirestoreError | null,
  controller: PaginationController
] => {
  const subscriptionsRef = useRef<Unsubscribe[]>([]);
  const lastDocRef = useRef<QueryDocumentSnapshot | null>(null);
  const [persistedQuery, setPersistedQuery] = useState(nextQuery);
  const [docs, setDocs] = useState<QueryDocumentSnapshot[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<FirestoreError | null>(null);

  const pageSize = options?.pageSize || DEFAULT_PAGE_SIZE;

  const unsubscribeSubscriptions = useCallback(() => {
    subscriptionsRef.current.forEach((unsubscribe) => unsubscribe());
    subscriptionsRef.current = [];
    lastDocRef.current = null;
  }, []);

  const resetState = useCallback(() => {
    setDocs([]);
    setHasMore(false);
    setIsLoading(false);
    setError(null);
  }, []);

  const onSuccess = useCallback(
    (isFirstPage = false) =>
      (snapshot: QuerySnapshot) => {
        const snapshotSize = snapshot.docs.length;
        const snapshotChanges = snapshot.docChanges();
        const snapshotChangesSize = snapshotChanges.length;

        snapshotChanges.forEach((change) => {
          switch (change.type) {
            case "added": {
              setDocs((docs) => {
                /**
                 * Documents should not be added twice
                 */
                if (docs.map((doc) => doc.id).includes(change.doc.id)) {
                  return docs;
                }

                /**
                 * In this case, new documents have been added to the collection.
                 * New documents are added to the beginning of the collection.
                 */
                if (isFirstPage && snapshotChangesSize !== snapshotSize) {
                  return [change.doc, ...docs];
                }

                /**
                 * In a regular page load, the new documents are added to the end of the collection.
                 */
                return [...docs, change.doc];
              });
              break;
            }
            case "modified": {
              setDocs((docs) => {
                const index = docs.findIndex((doc) => doc.id === change.doc.id);
                if (index === -1) return docs;
                const newDocs = [...docs];
                newDocs[index] = change.doc;
                return newDocs;
              });
              break;
            }
          }
        });

        setHasMore(snapshotSize === pageSize);
        setError(null);
        setIsLoading(false);
      },
    [pageSize]
  );

  const onError = useCallback((error: FirestoreError) => {
    setError(error);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    return () => unsubscribeSubscriptions();
  }, [unsubscribeSubscriptions]);

  useEffect(() => {
    if (persistedQuery && nextQuery && queryEqual(nextQuery, persistedQuery)) {
      return;
    }

    setPersistedQuery(nextQuery);
    unsubscribeSubscriptions();
    resetState();
  }, [nextQuery, persistedQuery, unsubscribeSubscriptions, resetState]);

  /**
   * The lastDocRef is updated whenever the docs state changes.
   * This is necessary to have an anchor point for the next page.
   */
  useEffect(() => {
    lastDocRef.current = docs[docs.length - 1] || null;
  }, [docs]);

  /**
   * The initial page subscribes to some past and all future documents.
   */
  useEffect(() => {
    if (!persistedQuery) return;

    /**
     * The first page is loaded with a "get"-query using a limit constraint.
     * This is necessary to have an anchor point for the following "snapshot"-query
     * which will be subscribed to all documents newer the anchor document.
     */
    setIsLoading(true);
    void getDocs(query(persistedQuery, limit(pageSize))).then((snapshot) => {
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      lastDocRef.current = lastDoc;

      const subscription = lastDoc
        ? onSnapshot(
            query(persistedQuery, endAt(lastDoc)),
            onSuccess(true),
            onError
          )
        : onSnapshot(persistedQuery, onSuccess(true), onError);

      subscriptionsRef.current.push(subscription);
    });
  }, [persistedQuery, pageSize, onSuccess, onError]);

  const loadMore = useCallback(() => {
    if (!persistedQuery) return;
    if (!lastDocRef.current) return;
    if (isLoading) return;

    setIsLoading(true);
    const subscription = onSnapshot(
      query(persistedQuery, startAfter(lastDocRef.current), limit(pageSize)),
      onSuccess(),
      onError
    );

    subscriptionsRef.current.push(subscription);
  }, [persistedQuery, pageSize, isLoading, onSuccess, onError]);

  const elements = useMemo(() => {
    return docs.reduce<T[]>((result, doc) => {
      const data = doc.data();
      if (isT(data)) result.push(data);
      return result;
    }, []);
  }, [docs, isT]);

  return [elements, isLoading, error, { hasMore, loadMore }];
};
