import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { Gender, GENDERS } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledChips } from "../../Form/views/ControlledChips";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { updateTalentDraftCallable } from "../../Talent/callables/updateTalentDraftCallable";
import { TALENT_GENDER_RECORD } from "../../Talent/consts/TALENT_GENDER_RECORD";
import { useMyTalentDraft } from "../../Talent/hooks/useMyTalentDraft";
import { getOnboardingStepPath } from "../helpers/getOnboardingStepPath";

const FormValues = z.object({
  genders: Gender.array().min(1).max(1),
  age: z
    .number()
    .int()
    .min(18)
    .max(1000)
    .or(z.literal(""))
    .superRefine((value, ctx) => {
      if (value !== "") return;
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: "Age is required" });
    }),
});

type FormValues = z.infer<typeof FormValues>;

const OPTIONS = GENDERS.map((gender) => ({
  label: TALENT_GENDER_RECORD[gender].label,
  value: gender,
}));

export const OnboardingTalentGenderAgePage: FC = () => {
  const navigate = useNavigate();
  const talentDraft = useMyTalentDraft();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      genders: talentDraft?.gender ? [talentDraft.gender] : [],
      age: talentDraft?.age || "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!talentDraft?.id) throw new Error("Talent ID is not defined");
        if (!formValues.age) throw new Error("Age is not defined");

        await updateTalentDraftCallable({
          id: talentDraft.id,
          gender: formValues.genders[0],
          age: formValues.age,
        });
        navigate(getOnboardingStepPath("TALENT_GENDER_AGE", "NEXT"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate, talentDraft?.id]
  );

  const isDisabled = formState.isSubmitting;

  return (
    <Stack
      mt={2}
      p={2}
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={2}>
        <Typography variant="ah5" color="text.secondary" textAlign="center">
          Almost There...
        </Typography>
        <Typography variant="ah2" color="text.primary" textAlign="center">
          A bit more about your AI Twin
        </Typography>
      </Stack>

      <Stack spacing={4}>
        <ControlledChips
          name="genders"
          control={control}
          options={OPTIONS}
          disabled={isDisabled}
          min={1}
          max={1}
          sx={{ justifyContent: "center" }}
          errorSx={{ textAlign: "center" }}
        />
        <Divider />
        <ControlledTextField
          control={control}
          name="age"
          type="number"
          label="Age in Years"
          inputLabelProps={{ shrink: true }}
          disabled={isDisabled}
        />

        <Stack spacing={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isDisabled}
            size="large"
            fullWidth
          >
            Continue
          </Button>
          <Button
            disabled={isDisabled}
            size="large"
            startIcon={<ArrowBackIcon />}
            to={getOnboardingStepPath("TALENT_GENDER_AGE", "PREVIOUS")}
            component={NavLink}
            fullWidth
          >
            Previous
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
