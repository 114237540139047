import { Grid, Skeleton, Stack } from "@mui/material";
import { StaticAttachment } from "@super-real/types/types/StaticAttachment";
import { FC } from "react";
import { StorageImage } from "../../../Common/views/StorageImage";

interface Props {
  attachments: StaticAttachment[];
  skeletonCount?: number;
  onAttachmentSelect?: (attachmentId: string) => void;
}

export const StaticImageAttachmentGallery: FC<Props> = (props) => {
  const { attachments, skeletonCount = 0, onAttachmentSelect } = props;

  return (
    <Stack>
      <Grid container spacing={0.5}>
        {attachments.map((attachment) => (
          <Grid item key={attachment.id} xs={6} sm={4}>
            <Stack position="relative">
              <StorageImage
                filePath={attachment.filePath}
                sx={{
                  aspectRatio: 1,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                onClick={() => onAttachmentSelect?.(attachment.id)}
              />
            </Stack>
          </Grid>
        ))}

        {Array.from({ length: skeletonCount }).map((_, index) => (
          <Grid item key={index} xs={6} sm={4}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ aspectRatio: 1 }}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
