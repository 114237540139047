import { FaqItem } from "@super-real/types";

export const toFaqItemsString = (items: FaqItem[]) => {
  const rows: string[] = [];

  items.forEach((item) => {
    rows.push(`Q: ${item.question}`);
    rows.push(`A: ${item.answer}`);
  });

  return rows.join("\n");
};
