import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { Payout, PAYOUT_STATUSES, PayoutStatus } from "@super-real/types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { TalentChip } from "../../Talent/views/TalentChip";
import { updatePayoutCallable } from "../callables/updatePayoutCallable";
import { PAYOUT_STATUS_RECORD } from "../consts/PAYOUT_STATUS_RECORD";
import { PAYOUT_TRANSFER_TYPE_RECORD } from "../consts/PAYOUT_TRANSFER_TYPE_RECORD";
import { PayoutStatusChip } from "./PayoutStatusChip";

export const FormValues = z.object({
  status: PayoutStatus,
  rejectionReason: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

interface Props {
  payout: Payout;
}

export const PayoutForm: FC<Props> = (props) => {
  const { payout } = props;

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      status: payout.status,
      rejectionReason: payout.rejectionReason || "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updatePayoutCallable({
          id: payout.id,
          status: formValues.status,
          rejectionReason: formValues.rejectionReason,
        });

        toast.success("Saved!");
      } catch (error) {
        handleError(error);
      }
    },
    [payout.id]
  );

  const { control, handleSubmit, formState, watch } = form;
  const status = watch("status");

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={1}>
        <PayoutStatusChip payoutStatus={payout.status} />
        <Chip label={`Amount: ${toLocaleMoney(payout.value)}`} />
        <TalentChip talentId={payout.talentId} />
      </Stack>
      <Stack component={Paper} p={2} spacing={2}>
        <Typography variant="ah3">Timeline</Typography>
        <Stack alignItems="flex-start" spacing={1}>
          <Chip
            label={`Requested: ${toLocaleDateTimeString(payout.requestedAt)}`}
          />
          {payout.executedAt && (
            <Chip
              label={`Executed: ${toLocaleDateTimeString(payout.executedAt)}`}
            />
          )}
          {payout.rejectedAt && (
            <Chip
              label={`Rejected: ${toLocaleDateTimeString(payout.rejectedAt)}`}
            />
          )}
          {payout.failedAt && (
            <Chip
              label={`Failed: ${toLocaleDateTimeString(payout.failedAt)}`}
            />
          )}
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={2}>
        <Typography variant="ah3">
          {PAYOUT_TRANSFER_TYPE_RECORD[payout.transferType].label}
        </Typography>
        <Alert severity="info">
          {payout.transferType === "BANK_TRANSFER" && (
            <>
              <Typography>
                <strong>Account Holder Name:</strong> {payout.accountHolderName}
              </Typography>
              <Typography>
                <strong>IBAN:</strong> {payout.iban}
              </Typography>
              <Typography>
                <strong>BIC:</strong> {payout.bic}
              </Typography>
            </>
          )}
          {payout.transferType === "PAYPAL" && (
            <Typography>
              <strong>Email:</strong> {payout.payPalEmail}
            </Typography>
          )}
          {!!payout.taxId && (
            <Typography>
              <strong>Tax ID:</strong> {payout.taxId}
            </Typography>
          )}
        </Alert>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Settings</Typography>
        <ControlledSelect
          control={control}
          label="Status"
          name="status"
          items={PAYOUT_STATUSES.map((status) => ({
            label: PAYOUT_STATUS_RECORD[status].label,
            value: status,
          }))}
          renderItem={(item) => item.label}
          disabled={isLoading}
          fullWidth
        />
        {(["REJECTED", "FAILED"] as PayoutStatus[]).includes(status) && (
          <ControlledTextField
            control={control}
            label="Reason"
            name="rejectionReason"
            autoComplete="off"
            disabled={isLoading}
            minRows={3}
            multiline
          />
        )}
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        Save
      </Button>
    </Stack>
  );
};
