import { Alert, Stack } from "@mui/material";
import { FC } from "react";

interface Props {
  error: Error;
}

export const ErrorView: FC<Props> = (props) => {
  const { error } = props;

  return (
    <Stack p={2}>
      <Alert severity="error">{error.message}</Alert>
    </Stack>
  );
};
