import { List, styled } from "@mui/material";

export const LinkList = styled(List)(({ theme }) => ({
  padding: 0,

  ".MuiListItemButton-root": {
    padding: theme.spacing(2),

    "&:not(:first-child)": {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  ".MuiListItemText-root": {
    margin: 0,
  },

  ".MuiListItemText-primary": {
    ...theme.typography.ah5,
    marginTop: theme.spacing(0),
  },

  ".MuiListItemIcon-root": {
    ...theme.typography.ah3,
    color: theme.palette.text.primary,
    minWidth: 46,
  },

  ".MuiSvgIcon-root": {
    ...theme.typography.ah3,
    color: theme.palette.primary.main,
  },
}));
