import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { Talent } from "@super-real/types";
import { FC, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { createChatCallable } from "../../Chat/callables/createChatCallable";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { LinkList } from "../../Common/views/LinkList";
import { createCustomTokenCallable } from "../../Firebase/callables/createCustomTokenCallable";
import { CreatorDashboardHeadlineView } from "./CreatorDashboardHeadlineView";

interface Props {
  talent: Talent;
}

export const CreatorDashboardQuickAccessView: FC<Props> = (props) => {
  const { talent } = props;
  const [isRedirecting, setIsRedirecting] = useState(false);

  const baseUrl = `${ENVIRONMENT.messengerDomain}/${talent.tag}`;

  const navigateToMessenger = useCallback(async () => {
    try {
      setIsRedirecting(true);
      const { customToken } = await createCustomTokenCallable({});
      await createChatCallable({ talentId: talent.id });
      window.location.href = `${baseUrl}/?customToken=${customToken}`;
    } catch (error) {
      handleError(error);
      setIsRedirecting(false);
    }
  }, [baseUrl, talent]);

  return (
    <>
      <CreatorDashboardHeadlineView title="✨ Quick Links" />
      <Stack>
        <LinkList>
          <ListItemButton
            onClick={navigateToMessenger}
            disabled={isRedirecting}
          >
            <ListItemIcon>💬</ListItemIcon>
            <ListItemText primary="Chat with your AI" />
            {isRedirecting ? (
              <CircularProgress size={24} />
            ) : (
              <ArrowForwardIcon />
            )}
          </ListItemButton>
          <ListItemButton to="/settings" component={NavLink}>
            <ListItemIcon>⚙️</ListItemIcon>
            <ListItemText primary="Manage your AI’s profile" />
            <ArrowForwardIcon />
          </ListItemButton>
          <ListItemButton to="/referrals" component={NavLink}>
            <ListItemIcon>🤝</ListItemIcon>
            <ListItemText primary="Refer and earn" />
            <ArrowForwardIcon />
          </ListItemButton>
          <ListItemButton to="/feedback" component={NavLink}>
            <ListItemIcon>🚀</ListItemIcon>
            <ListItemText primary="Feature Requests" />
            <ArrowForwardIcon />
          </ListItemButton>
        </LinkList>
      </Stack>
    </>
  );
};
