import { Stack, Typography } from "@mui/material";
import { FC } from "react";

export const FirebaseAuthCaptionView: FC = () => {
  return (
    <Stack direction="row" spacing={0.5}>
      <Typography variant="ah5" color="background.gradient">
        Super Real
      </Typography>
      <Typography variant="ah5" color="common.black">
        Creator Centre
      </Typography>
    </Stack>
  );
};
