import { ChipOwnProps } from "@mui/material";
import { ProductPriceType } from "@super-real/types";

interface RecordValue {
  label: string;
  chipColor: ChipOwnProps["color"];
}

export const PRODUCT_PRICE_TYPE_RECORD: Record<ProductPriceType, RecordValue> =
  {
    ONE_TIME: {
      label: "Credits",
      chipColor: "default",
    },
    SUBSCRIPTION: {
      label: "Subscription",
      chipColor: "success",
    },
    APPLE_ONE_TIME: {
      label: "IAP",
      chipColor: "info",
    },
    GOOGLE_ONE_TIME: {
      label: "IAP",
      chipColor: "warning",
    },
  };
