import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import StorageIcon from "@mui/icons-material/Storage";
import {
  Alert,
  Button,
  Chip,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { getFirestoreUrl } from "../../Firebase/helpers/getFirestoreUrl";
import { TalentChip } from "../../Talent/views/TalentChip";
import { adminDeleteBroadcastCallable } from "../callables/adminDeleteBroadcastCallable";
import { useBroadcast } from "../hooks/useBroadcast";
import { BroadcastStatusChip } from "./BroadcastStatusChip";

export const BroadcastPage: FC = () => {
  const { broadcastId = "" } = useParams<"broadcastId">();
  const navigate = useNavigate();
  const [broadcast, isLoading, error] = useBroadcast(broadcastId);
  const [isDeleting, setIsDeleting] = useState(false);
  const isLoaded = !isLoading && !error;

  const onClickDelete = useCallback(async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to delete?");
      if (!isConfirmed) return;
      setIsDeleting(true);
      await adminDeleteBroadcastCallable({ broadcastId });
      navigate("/broadcasts");
    } catch (error) {
      handleError(error);
      setIsDeleting(false);
    }
  }, [broadcastId, navigate]);

  return (
    <Stack p={3} spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="ah2">Broadcast</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          {broadcast?.status === "SCHEDULED" && (
            <Stack>
              <Button
                onClick={onClickDelete}
                color="error"
                startIcon={<DeleteIcon />}
                disabled={isDeleting}
              >
                Delete
              </Button>
            </Stack>
          )}
          <Stack>
            <Button
              component="a"
              target="_blank"
              href={getFirestoreUrl(`broadcasts/${broadcastId}`)}
              startIcon={<StorageIcon />}
            >
              Firestore
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2} flex={1}>
        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
        {isLoaded && broadcast && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <BroadcastStatusChip broadcast={broadcast} />
              {broadcast.talentId && (
                <TalentChip talentId={broadcast.talentId} />
              )}
            </Stack>
            <Alert severity="success" icon={<ChatBubbleIcon />}>
              {broadcast.message}
            </Alert>
            {(!!broadcast.pushNotificationMessage ||
              !!broadcast.pushNotificationHeadline) && (
              <Alert severity="info" icon={<NotificationsActiveIcon />}>
                <Typography fontWeight={700}>
                  {broadcast.pushNotificationHeadline}
                </Typography>
                {broadcast.pushNotificationMessage}
              </Alert>
            )}
            {!!broadcast.scheduledMessageCount && (
              <Stack spacing={0.5}>
                <Typography variant="caption" textAlign="right">
                  Sent Messages: {broadcast.sentMessageCount || 0} /{" "}
                  {broadcast.scheduledMessageCount || 0}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((broadcast.sentMessageCount || 0) * 100) /
                    broadcast.scheduledMessageCount
                  }
                />
              </Stack>
            )}
            <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={1}>
              {broadcast.status !== "DELETED" && (
                <Chip
                  size="small"
                  label={`Scheduled: ${toLocaleDateTimeString(
                    broadcast.scheduledAt
                  )}`}
                />
              )}
              {broadcast.startedPreparingAt && (
                <Chip
                  size="small"
                  color="primary"
                  label={`Started: ${toLocaleDateTimeString(
                    broadcast.startedPreparingAt
                  )}`}
                />
              )}
              {broadcast.completedAt && (
                <Chip
                  size="small"
                  color="success"
                  label={`Completed: ${toLocaleDateTimeString(
                    broadcast.completedAt
                  )}`}
                />
              )}
              {broadcast.deletedAt && (
                <Chip
                  size="small"
                  color="error"
                  label={`Deleted: ${toLocaleDateTimeString(
                    broadcast.deletedAt
                  )}`}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
