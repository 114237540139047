import { isModel } from "@super-real/types";
import { or, query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useModels(talentIds: (string | null | undefined)[]) {
  return useValidCollectionData(
    query(
      getCollectionRef("models"),
      or(...talentIds.map((talentId) => where("talentId", "==", talentId)))
    ),
    isModel
  );
}
