import { Stack } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQueryDown } from "../../Common/hooks/useMediaQueryDown";
import { MAIN_SIDEBAR_WIDTH } from "../consts/MAIN_SIDEBAR_WIDTH";
import { MainSidebarView } from "./MainSidebarView";

export const MainLayout: FC = () => {
  const isSmall = useMediaQueryDown("sm");

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <MainSidebarView />
      <Stack ml={isSmall ? 0 : `${MAIN_SIDEBAR_WIDTH}px`} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
